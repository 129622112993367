<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <privacy-parent></privacy-parent>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import PrivacyParent from '../MiskPrivacyPolicy/PrivacyParent'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex'
export default {
    name: 'TermsConditionsPage',
    components: {
        NavbarStyleTwo,
        PrivacyParent,
        Footer
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'سياسة الخصوصية | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Privacy Policy | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'تعرف على سياسة الخصوصية الخاصة بنا وكيفية جمعنا واستخدامنا وحمايتنا لبياناتك الشخصية. نحن ملتزمون بحماية خصوصيتك في جميع تعاملاتك مع شركة مسك.'
                : 'Learn about our privacy policy and how we collect, use, and protect your personal data. We are committed to safeguarding your privacy in all interactions with Misk IT Solutions.';

            const metaKeywords = isArabic
                ? 'سياسة الخصوصية, حماية البيانات, جمع البيانات, استخدام البيانات, ممارسات الخصوصية, حماية الخصوصية, مسك لحلول تكنولوجيا المعلومات'
                : 'privacy policy, data protection, data collection, data usage, privacy practices, safeguarding privacy, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/privacy-policy-image-ar.jpg'
                : 'https://example.com/privacy-policy-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/privacy-policy'
                : 'https://misk.com/privacy-policy';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>