<template>
    <div class="overview-section pt-35 pb-35">
        <div class="container">
            <div class="section-title">
                <span>{{ $t('sectionTitle.span') }}</span>
      <h2>{{ $t('sectionTitle.heading') }}</h2>
                <div class="bar"></div>
            </div>
            <section class="row align-content-center justify-content-between mt-3">
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content text-center">
                        <div class="icon">
                            <img src="../../assets/images/find-new-ideas.svg" alt="find new Ideas">
                        </div>
                        <h3>{{ $t('discussion.title') }}</h3>
                        <p>{{ $t('discussion.content') }}</p>
                        <div class="first-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/digital-analysis.svg" alt="find new Ideas">
                        </div>
                        <h3>{{ $t('planning.title') }}</h3>
                        <p>{{ $t('planning.content') }}</p>
                        <div class="second-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/market-research.svg" alt="find new Ideas">
                        </div>
                        <h3>{{ $t('uiDesign.title') }}</h3>
                        <p>{{ $t('uiDesign.content') }}</p>
                        <div class="third-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-creation.svg" alt="find new Ideas">
                        </div>
                        <h3>{{ $t('websiteProgramming.title') }}</h3>
                        <p>{{ $t('websiteProgramming.content') }}</p>
                        <div class="forth-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-launching.svg" alt="find new Ideas">
                        </div>
                        <h3>{{ $t('websiteTesting.title') }}</h3>
                        <p>{{ $t('websiteTesting.content') }}</p>
                    </div>
                </div>
                <div class="text-center mt-3 mt-md-1">
                    <router-link to="/contact" class="default-btn py-1 rounded-2 fs-5">
                        {{ $t('cta') }}
                    </router-link>
                </div>
            </section>
        </div>
        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>
            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>
            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>
            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>
            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DevelopmentProcess'
    }
</script>