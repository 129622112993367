import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      locale: 'ar', // default language
      currentLanguage: localStorage.getItem('language') || 'ar',
      counter: 0
    };
  },
  mutations: {
    increment(state) {
      state.counter++;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    setLanguage(state, language) {
      state.currentLanguage = language;
  }
  },
  actions: {
    incrementCounter(context) {
      context.commit('increment');
    },
    changeLanguage({ commit }, language) {
      commit('setLanguage', language);
    }
  },
  getters: {
    getCounter(state) {
      return state.counter;
    },
    currentLanguage: (state) => state.currentLanguage
  }
});

export default store;
