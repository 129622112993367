<template>
    <div class="company-section pt-35 pb-35">
        <div class="container ">
            <div class="row align-items-center">
                <div class="col-lg-6 mt-3">
                    <section class="company-section-content">
                        <div>
                            <span>{{ $t('our_company.title') }}</span>
                            <h2>{{ $t('our_company.subtitle') }}</h2>
                            <p>
                                {{ $t('our_company.description') }}
                            </p>
                            <p>
                                {{ $t('our_company.roles') }}
                            </p>
                            <p>
                                {{ $t('our_company.info') }}
                                <a href="https://www.misk.com.eg/wp" target="_blank">{{ $t('our_company.website')
                                    }}</a>.
                            </p>
                        </div>
                        <footer>
                            <a href="#" target="_blank" aria-label="misk its social media link"><i
                                    class="fa-brands fa-square-facebook"></i></a>
                            <a href="#" target="_blank" aria-label="misk its social media link"><i
                                    class="fa-brands fa-square-x-twitter"></i></a>
                            <a href="#" target="_blank" aria-label="misk its social media link"><i
                                    class="fa-brands fa-linkedin"></i></a>
                            <a href="#" target="_blank" aria-label="misk its social media link"><i
                                    class="fa-brands fa-square-snapchat"></i></a>
                            <a href="#" target="_blank" aria-label="misk its social media link"><i
                                    class="fa-brands fa-square-youtube"></i></a>
                        </footer>
                    </section>
                </div>
                <div class="col-lg-6 mt-5 mt-lg-3">
                    <div class="support-image text-center">
                        <img src="../../assets/images/Images/job-company.webp" alt="image" width="409" height="331">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurCompany'
}
</script>