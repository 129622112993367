<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner :Banner="localizedBanner" />
        <SectionOne :features="localizedFeatures" />
        <RelatedArticles />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Features/Banner.vue';
import SectionOne from '../Projects/Features/SectionOne.vue';
import RelatedArticles from '../Projects/Features/RelatedArticles.vue';
import Footer from '../Layout/Footer'
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex';
import { useHead } from '@vueuse/head'

export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        SectionOne,
        RelatedArticles,
        OurClients,
        Footer,
    },
    data() {
        return {
            BannerContent: {
                titleKey: 'workloadBanner.title',
                descriptionKey: 'workloadBanner.description',
                buttonLinkKey: '',
                buttonTextKey: 'workloadBanner.buttonText',
                image: require('../../assets/images/workload-1.png'),
                imageAltKey: "workloadBanner.imageAlt",
                imageWidth: 824,
                imageHeight: 562
            },
            Features: [
                { image: require('../../assets/images/workload-2.png'), titleKey: 'workload[0].title', descriptionKey: 'workload[0].description', imageWidth: 1463, imageHeight: 990, reverseOrder: true },
                { image: require('../../assets/images/workload-3.png'), titleKey: 'workload[1].title', descriptionKey: 'workload[1].description', imageWidth: 2739, imageHeight: 1896 },
                { image: require('../../assets/images/workload-4.png'), titleKey: 'workload[2].title', descriptionKey: 'workload[2].description', imageWidth: 980, imageHeight: 670, reverseOrder: true },
                { image: require('../../assets/images/workload-5.png'), titleKey: 'workload[3].title', descriptionKey: 'workload[3].description', imageWidth: 2921, imageHeight: 1993 },
            ],

        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
        localizedFeatures() {
            return this.Features.map(feature => ({
                ...feature,
                title: this.$t(feature.titleKey),
                description: this.$t(feature.descriptionKey)
            }));
        },
        localizedBanner() {
            // Ensure BannerContent is defined before accessing its properties
            return {
                title: this.$t(this.BannerContent.titleKey),
                description: this.$t(this.BannerContent.descriptionKey),
                buttonLink: this.BannerContent.buttonLinkKey, // If buttonLinkKey is static, leave it as is
                buttonText: this.$t(this.BannerContent.buttonTextKey),
                image: this.BannerContent.image,
                imageAlt: this.$t(this.BannerContent.imageAltKey),
                imageWidth: this.BannerContent.imageWidth,
                imageHeight: this.BannerContent.imageHeight
            }; // Return an empty object if BannerContent is undefined
        }
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'إدارة عبء العمل | حلول متكاملة لتحسين كفاءة الأعمال'
                : 'Workload Management | Comprehensive Solutions to Enhance Business Efficiency';

            const metaDescription = isArabic
                ? 'قم بإدارة عبء العمل بشكل فعال مع حلولنا المبتكرة. توفر شركة مسك أدوات وتقنيات لتحسين الإنتاجية وتقليل الإجهاد وتعزيز التعاون داخل فرق العمل.'
                : 'Effectively manage workload with our innovative solutions. Misk IT Solutions provides tools and techniques to boost productivity, reduce stress, and enhance team collaboration.';

            const metaKeywords = isArabic
                ? 'إدارة عبء العمل, تحسين الإنتاجية, حلول الأعمال, تنظيم العمل, تقليل الإجهاد, التعاون, إدارة الفرق, شركة مسك'
                : 'workload management, productivity enhancement, business solutions, task organization, stress reduction, team collaboration, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/workload-management-ar.jpg'
                : 'https://example.com/workload-management-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/workload-management'
                : 'https://misk.com/workload-management';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "إدارة عبء العمل" : "Workload Management"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}

</script>