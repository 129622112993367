<template>
    <div class="audience-section pt-100 pb-70">
        <div>
            <div class="container">
                <solutionTabsHeading 
                :title="$t('awsTabs.amazonWebService.title')"
                :description="$t('awsTabs.amazonWebService.description')"
                buttonLink="'/request-quote'" 
                :buttonText="$t('awsTabs.amazonWebService.buttonText')" 
                borderRadius="5px" 
                :imageSrc="require('../../assets/images/Rectangle124477.png')" 
                imageAlt="Amazon Web Service Image" 
                imageWidth="526"
                imageHeight="297" 
                />
            </div>
            <!--------Aws Features------------->
            <div class="solution-development-area ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5">
                            <div class="development-image">
                                <img src="../../assets/images/Rectangle-4.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="development-text">
                                <h3>{{ $t('cloudConsulting.title') }}</h3>
                                <div class="bar"></div>
                                <p>
                                    {{ $t('cloudConsulting.description') }}
                                </p>
                            </div>
                            <ul>
                                <li>
                                    {{ $t('cloudConsulting.services[0]') }}
                                </li>
                                <li>
                                    {{ $t('cloudConsulting.services[1]') }}
                                </li>
                                <li>
                                    {{ $t('cloudConsulting.services[2]') }}
                                </li>
                                <li>
                                    {{ $t('cloudConsulting.services[3]') }}
                                </li>
                                <li>
                                    {{ $t('cloudConsulting.services[4]') }}
                                </li>
                                <li>
                                    {{ $t('cloudConsulting.services[5]') }}
                                </li>
                                <li>
                                    {{ $t('cloudConsulting.services[6]') }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!------------------------------>
            <div class="solution-development-area solution-development-area--bg ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div>
                                <h3 class="mb-3">{{ $t('cloudMigration.title') }}</h3>
                                <p style="font-weight: 600;">
                                {{ $t('cloudMigration.question') }}
                                </p>
                            </div>
                            <div>
                                <p class="text-justify">
                                {{ $t('cloudMigration.description') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="development-video">
                               <video controls>
                                    <source src="../../assets/images/test.mp4" aria-controls="">
                               </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--------Form---------------->
            <div class="container pt-100">
                <RequestForm />
            </div>
            <!-------------------------->
        </div>
    </div>
</template>
<script>
import RequestForm from './RequestForm.vue';
import solutionTabsHeading from './solutionTabsHeading.vue'
export default {
    components: { solutionTabsHeading , RequestForm},
}
</script>
<style lang="scss" scoped>
h2 {
    color: var(--Primary, #00B79D);
}
.development-image{
    &:lang(en){
        text-align: left;
    }
    &:lang(ar){
        text-align: right;
    }
}
.development-video{
    &:lang(en){
        text-align: right;
    }
    &:lang(ar){
        text-align: left;
    }
}
@media screen and (max-width: 768px){
.development-image,.development-video{
    &:lang(en), &:lang(ar){
        text-align: center;
    }
}
}
</style>