<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <PageTitle />
        <!--<counter/>--->
        <About />
        <Vision />
        <about-value></about-value>
        <Features />
        <last-slider></last-slider>
        <our-growing></our-growing>
        <our-history></our-history>
        <ClientsOverview />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo';
import PageTitle from '../About/PageTitle';
import About from '../About/About';
import Vision from '../About/Future';
import AboutValue from '../About/AboutValue';
import Features from '../HomeOne/Features';
import LastSlider from '../About/LastSlider';
import OurGrowing from '../About/OurGrowing';
import OurHistory from '../About/OurHistory';
import Footer from '../Layout/Footer';
import ClientsOverview from '../HomeOne/ClientsOverview';
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex';
export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        PageTitle,
        About,
        Vision,
        OurGrowing,
        Footer,
        OurHistory,
        LastSlider,
        AboutValue,
        ClientsOverview,
        Features,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const author = 'Misk IT Solutions Company';

            const title =
                this.currentLanguage === 'ar'
                    ? 'من نحن | شركة مسك لحلول تكنولوجيا المعلومات'
                    : 'About Us | Misk IT Solutions Company';

            const description =
                this.currentLanguage === 'ar'
                    ? 'تعرف على شركة مسك، الشركة الرائدة في حلول تكنولوجيا المعلومات التي تقدم خدمات تقنية مبتكرة لتمكين الشركات. اكتشف رؤيتنا ورسالتنا وقيمنا التي تقودنا للنجاح.'
                    : 'Learn about Misk, a leading IT solutions company offering innovative technology services to empower businesses. Discover our vision, mission, and values that drive success.';

            const keywords =
                this.currentLanguage === 'ar'
                    ? 'شركة مسك, حلول تكنولوجيا المعلومات, خدمات تقنية, حلول مبتكرة, تكنولوجيا الأعمال, شركة تقنية, تطوير برمجيات, التحول الرقمي, حلول السحابة'
                    : 'Misk IT Solutions, IT services, technology solutions, innovative IT, business technology, IT company, software development, digital transformation, cloud solutions';

            useHead({
                title: title,
                meta: [
                    { name: 'description', content: description },
                    { name: 'keywords', content: keywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'author', content: author },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: title },
                    { property: 'og:description', content: description },
                    {
                        property: 'og:image', content: this.currentLanguage === 'ar'
                            ? 'https://example.com/about-image-ar.jpg'
                            : 'https://example.com/about-image-en.jpg'
                    },
                    {
                        property: 'og:url', content: this.currentLanguage === 'ar'
                            ? 'https://misk.com/ar/about'
                            : 'https://misk.com/about'
                    },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: title },
                    { name: 'twitter:description', content: description },
                    {
                        name: 'twitter:image', content: this.currentLanguage === 'ar'
                            ? 'https://example.com/about-image-ar.jpg'
                            : 'https://example.com/about-image-en.jpg'
                    },
                ],
            });
        },

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
};
</script>