<template>
    <section style="margin-top: 60px;" >
        <div class="container project-info">
            <div class="row">
                <!-------------------->
                <div class="col-lg-3 col-md-6 mb-3 mb-lg-0 d-flex">
                    <div class="project-info-block project-info-block--bg hover-effect flex-size">
                        <div class="icon hover-effect-1">
                            <img src="../../assets/images/DiscussionForum-1.png" alt="form icon" width="160"
                                height="160">
                        </div>
                        <div class="project-info-block--text">
                            <h4 class="hover-effect-2"> {{ $t('costs_and_tests.title') }}</h4>
                            <p class="hover-effect-2">
                                {{ $t('costs_and_tests.description') }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-------------------->
                <div class="col-md-6 mb-3 mb-lg-0">
                    <div class="project-info-block project-info-block--border hover-effect">
                        <div class="icon hover-effect-1">
                            <img src="../../assets/images/DiscussionForum.svg" alt="form icon" width="160" height="160">
                        </div>
                        <div class="project-info-block--text">
                            <h4 class="hover-effect-2"> {{ $t('dynamic_permissions.title') }}</h4>
                            <p class="hover-effect-2">
                                {{ $t('dynamic_permissions.description') }}
                            </p>
                        </div>
                    </div>
                </div>
                <!--------------->
                <div class="col-lg-3 col-md-6 mb-3 mb-lg-0">
                    <div class="project-info-block project-info-block--bg hover-effect">
                        <div class="icon hover-effect-1">
                            <img src="../../assets/images/Learning-Video.svg" alt="form icon" width="160" height="160">
                        </div>
                        <div class="project-info-block--text ">
                            <h4 class="hover-effect-2"> {{ $t('fastest_search.title') }}</h4>
                            <p class="hover-effect-2">
                                {{ $t('fastest_search.description') }}
                            </p>
                            <ul class="list-unstyled">
                                <li class="d-inline-flex align-items-center gap-1 hover-effect-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M8 13L12.228 16.382C12.4336 16.5462 12.6957 16.6228 12.9574 16.5951C13.2191 16.5674 13.4593 16.4377 13.626 16.234L22 6"
                                                stroke="#00B79D" stroke-width="3" stroke-linecap="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M10.5654 12.367L15.4654 6.37896C15.5662 6.25563 15.6138 6.09732 15.5979 5.93886C15.582 5.78039 15.5037 5.63474 15.3804 5.53396C15.2571 5.43318 15.0988 5.38551 14.9403 5.40145C14.7818 5.4174 14.6362 5.49563 14.5354 5.61896L9.64439 11.599L10.5654 12.367ZM8.03239 15.463L7.11039 14.694L6.14339 15.877C6.0781 15.957 5.98438 16.0086 5.88187 16.0211C5.77936 16.0336 5.67599 16.0059 5.59339 15.944L2.36039 13.519C2.23309 13.4235 2.07307 13.3825 1.91554 13.405C1.75801 13.4275 1.61587 13.5117 1.52039 13.639C1.42492 13.7663 1.38392 13.9263 1.40642 14.0838C1.42893 14.2413 1.51309 14.3835 1.64039 14.479L4.87339 16.904C5.20351 17.1514 5.61647 17.2618 6.02604 17.2121C6.4356 17.1623 6.81012 16.9563 7.07139 16.637L8.03239 15.463Z"
                                                fill="#00B79D" fill-opacity="0.25" />
                                        </svg>
                                    </span>
                                    <span>
                                        {{ $t('fastest_search.search_speed') }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--------------->
                <div class="col-md-6 mb-3 mb-lg-0 margin-top-custom" >
                    <div class="project-info-block project-info-block--border hover-effect">
                        <div class="icon hover-effect-1">
                            <img src="../../assets/images/Discussion-Forum2.svg" alt="form icon" width="160"
                                height="160">
                        </div>
                        <div class="project-info-block--text">
                            <h4 class="hover-effect-2"> {{ $t('advanced_filter.title') }}</h4>
                            <p class="hover-effect-2">
                                {{ $t('advanced_filter.description') }}
                            </p>
                            <ul class="list-unstyled">
                                <li class="d-inline-flex align-items-center gap-1 hover-effect-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M8 13L12.228 16.382C12.4336 16.5462 12.6957 16.6228 12.9574 16.5951C13.2191 16.5674 13.4593 16.4377 13.626 16.234L22 6"
                                                stroke="#00B79D" stroke-width="3" stroke-linecap="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M10.5654 12.367L15.4654 6.37896C15.5662 6.25563 15.6138 6.09732 15.5979 5.93886C15.582 5.78039 15.5037 5.63474 15.3804 5.53396C15.2571 5.43318 15.0988 5.38551 14.9403 5.40145C14.7818 5.4174 14.6362 5.49563 14.5354 5.61896L9.64439 11.599L10.5654 12.367ZM8.03239 15.463L7.11039 14.694L6.14339 15.877C6.0781 15.957 5.98438 16.0086 5.88187 16.0211C5.77936 16.0336 5.67599 16.0059 5.59339 15.944L2.36039 13.519C2.23309 13.4235 2.07307 13.3825 1.91554 13.405C1.75801 13.4275 1.61587 13.5117 1.52039 13.639C1.42492 13.7663 1.38392 13.9263 1.40642 14.0838C1.42893 14.2413 1.51309 14.3835 1.64039 14.479L4.87339 16.904C5.20351 17.1514 5.61647 17.2618 6.02604 17.2121C6.4356 17.1623 6.81012 16.9563 7.07139 16.637L8.03239 15.463Z"
                                                fill="#00B79D" fill-opacity="0.25" />
                                        </svg>
                                    </span>
                                    <span>
                                        {{ $t('advanced_filter.refine_filter') }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!--------------->
                <div class="col-lg-6 mb-3 mb-lg-0 margin-top-custom" >
                    <div class="project-info-block project-info-block--none d-flex align-items-start flex-column hover-effect">
                        <div class="project-info-block--text">
                            <h4 class="hover-effect-2"> {{ $t('industry_interface.title') }}</h4>
                            <p class="hover-effect-2">
                                {{ $t('industry_interface.description') }}
                            </p>
                            <ul class="list-unstyled mt-2">
                                <li class="d-inline-flex align-items-center gap-2 hover-effect-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M8 13L12.228 16.382C12.4336 16.5462 12.6957 16.6228 12.9574 16.5951C13.2191 16.5674 13.4593 16.4377 13.626 16.234L22 6"
                                                stroke="#00B79D" stroke-width="3" stroke-linecap="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M10.5654 12.367L15.4654 6.37896C15.5662 6.25563 15.6138 6.09732 15.5979 5.93886C15.582 5.78039 15.5037 5.63474 15.3804 5.53396C15.2571 5.43318 15.0988 5.38551 14.9403 5.40145C14.7818 5.4174 14.6362 5.49563 14.5354 5.61896L9.64439 11.599L10.5654 12.367ZM8.03239 15.463L7.11039 14.694L6.14339 15.877C6.0781 15.957 5.98438 16.0086 5.88187 16.0211C5.77936 16.0336 5.67599 16.0059 5.59339 15.944L2.36039 13.519C2.23309 13.4235 2.07307 13.3825 1.91554 13.405C1.75801 13.4275 1.61587 13.5117 1.52039 13.639C1.42492 13.7663 1.38392 13.9263 1.40642 14.0838C1.42893 14.2413 1.51309 14.3835 1.64039 14.479L4.87339 16.904C5.20351 17.1514 5.61647 17.2618 6.02604 17.2121C6.4356 17.1623 6.81012 16.9563 7.07139 16.637L8.03239 15.463Z"
                                                fill="#00B79D" fill-opacity="0.25" />
                                        </svg>
                                    </span>
                                    <span>
                                        {{ $t('industry_interface.templates') }}
                                    </span>
                                </li>
                                <li class="d-inline-flex align-items-center gap-2 hover-effect-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M8 13L12.228 16.382C12.4336 16.5462 12.6957 16.6228 12.9574 16.5951C13.2191 16.5674 13.4593 16.4377 13.626 16.234L22 6"
                                                stroke="#00B79D" stroke-width="3" stroke-linecap="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M10.5654 12.367L15.4654 6.37896C15.5662 6.25563 15.6138 6.09732 15.5979 5.93886C15.582 5.78039 15.5037 5.63474 15.3804 5.53396C15.2571 5.43318 15.0988 5.38551 14.9403 5.40145C14.7818 5.4174 14.6362 5.49563 14.5354 5.61896L9.64439 11.599L10.5654 12.367ZM8.03239 15.463L7.11039 14.694L6.14339 15.877C6.0781 15.957 5.98438 16.0086 5.88187 16.0211C5.77936 16.0336 5.67599 16.0059 5.59339 15.944L2.36039 13.519C2.23309 13.4235 2.07307 13.3825 1.91554 13.405C1.75801 13.4275 1.61587 13.5117 1.52039 13.639C1.42492 13.7663 1.38392 13.9263 1.40642 14.0838C1.42893 14.2413 1.51309 14.3835 1.64039 14.479L4.87339 16.904C5.20351 17.1514 5.61647 17.2618 6.02604 17.2121C6.4356 17.1623 6.81012 16.9563 7.07139 16.637L8.03239 15.463Z"
                                                fill="#00B79D" fill-opacity="0.25" />
                                        </svg>
                                    </span>
                                    <span>
                                        {{ $t('industry_interface.templates1') }}
                                    </span>
                                </li>
                                <li class="d-inline-flex align-items-center gap-2 hover-effect-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M8 13L12.228 16.382C12.4336 16.5462 12.6957 16.6228 12.9574 16.5951C13.2191 16.5674 13.4593 16.4377 13.626 16.234L22 6"
                                                stroke="#00B79D" stroke-width="3" stroke-linecap="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M10.5654 12.367L15.4654 6.37896C15.5662 6.25563 15.6138 6.09732 15.5979 5.93886C15.582 5.78039 15.5037 5.63474 15.3804 5.53396C15.2571 5.43318 15.0988 5.38551 14.9403 5.40145C14.7818 5.4174 14.6362 5.49563 14.5354 5.61896L9.64439 11.599L10.5654 12.367ZM8.03239 15.463L7.11039 14.694L6.14339 15.877C6.0781 15.957 5.98438 16.0086 5.88187 16.0211C5.77936 16.0336 5.67599 16.0059 5.59339 15.944L2.36039 13.519C2.23309 13.4235 2.07307 13.3825 1.91554 13.405C1.75801 13.4275 1.61587 13.5117 1.52039 13.639C1.42492 13.7663 1.38392 13.9263 1.40642 14.0838C1.42893 14.2413 1.51309 14.3835 1.64039 14.479L4.87339 16.904C5.20351 17.1514 5.61647 17.2618 6.02604 17.2121C6.4356 17.1623 6.81012 16.9563 7.07139 16.637L8.03239 15.463Z"
                                                fill="#00B79D" fill-opacity="0.25" />
                                        </svg>
                                    </span>
                                    <span> 
                                        {{ $t('industry_interface.templates2') }}
                                    </span>
                                </li>
                            </ul>
                            <button class="hover-effect-2">
                                {{ $t('industry_interface.button') }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--------------->
            </div>
        </div>

        <!----<div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>-->
    </section>
</template>
<style lang="scss" scoped>
.project-info {margin-bottom: 40px;}
.margin-top-custom{margin-top: 40px;}
.project-info-block {
    border-radius: 20px;
    padding: 65px 30px 42px 20px;
    h4,
    p {
        font-weight: 700;
        line-height: 160%;
    }
    h4 {
        color: #101566;
        font-size: 20px;
        margin-top: 32px;
    }
    p {
        color: #627A95;
        font-size: 14px;
        margin-top: 8px;
    }
}
ul li {
    color: #3F4E65;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    width: 100%;
    margin-bottom: 14px;
}
.project-info .project-info-block--bg {
    background: #ECFAFF;
    flex: 1;
}
.project-info-block--border {
    border: 1px solid #D4DBE3;
    background: #FFF;
    margin-left: 20px;
    margin-right: 20px;
}
.project-info-block--none {
    background: #FCFFFF;
    padding: 10px 70px 35px;
    button {
        margin-top: 37px;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 45px;
        text-transform: capitalize;
        border-radius: 20px;
        background: #00B79D;
        padding: 7px 40px;
        border: 1px solid #00B79D;
        transition: 0.2s;
        &:hover {
            background: transparent;
            color: #00B79D;
        }
    }
}
/*********************/
@media screen and (max-width:992px){
    .project-info-block--border {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    .margin-top-custom{margin-top: 0;}
    .col-md-6{display: flex;}
}
@media screen and (max-width:1200px){
    .project-info-block--none button{
        padding: 7px 15px;
        font-size: 14px;
    }
}
</style>