<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner :Banner="localizedBanner" />
        <SectionOne :features="localizedFeatures" />
        <RelatedArticles />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Features/Banner.vue';
import SectionOne from '../Projects/Features/SectionOne.vue';
import RelatedArticles from '../Projects/Features/RelatedArticles.vue';
import Footer from '../Layout/Footer'
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex';
import { useHead } from '@vueuse/head'

export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        SectionOne,
        RelatedArticles,
        OurClients,
        Footer,
    },
    data() {
        return {
            BannerContent: {
                titleKey: 'mainBanner.title',
                descriptionKey: 'mainBanner.description',
                buttonLinkKey: '',
                buttonTextKey: 'mainBanner.buttonText',
                image: require('../../assets/images/kaban-features-1.jpg'),
                imageAltKey: "mainBanner.imageAlt",
                imageWidth: 682,
                imageHeight: 458
            },
            Features: [
                { image: require('../../assets/images/features-2.png'), titleKey: 'kanbanfeatures[0].title', descriptionKey: 'kanbanfeatures[0].description', imageWidth: 646, imageHeight: 437, reverseOrder: true },
                { image: require('../../assets/images/features-3.png'), titleKey: 'kanbanfeatures[1].title', descriptionKey: 'kanbanfeatures[1].description', imageWidth: 685, imageHeight: 468 },
                { image: require('../../assets/images/features-4.jpg'), titleKey: 'kanbanfeatures[2].title', descriptionKey: 'kanbanfeatures[2].description', imageWidth: 646, imageHeight: 437, reverseOrder: true }
            ],

        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
        localizedFeatures() {
            return this.Features.map(feature => ({
                ...feature,
                title: this.$t(feature.titleKey),
                description: this.$t(feature.descriptionKey)
            }));
        },
        localizedBanner() {
            // Ensure BannerContent is defined before accessing its properties
            return {
                title: this.$t(this.BannerContent.titleKey),
                description: this.$t(this.BannerContent.descriptionKey),
                buttonLink: this.BannerContent.buttonLinkKey, // If buttonLinkKey is static, leave it as is
                buttonText: this.$t(this.BannerContent.buttonTextKey),
                image: this.BannerContent.image,
                imageAlt: this.$t(this.BannerContent.imageAltKey),
                imageWidth: this.BannerContent.imageWidth,
                imageHeight: this.BannerContent.imageHeight
            }; // Return an empty object if BannerContent is undefined
        }
    },

    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'ميزات لوحة كانبان لإدارة المشاريع | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Kanban Features for Project Management | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف ميزات لوحة كانبان لإدارة المشاريع التي توفر لك رؤية شاملة لمهامك وتساعدك على تنظيم سير العمل وتحسين الإنتاجية.'
                : 'Explore the Kanban features for project management that give you a comprehensive view of your tasks, helping you streamline workflows and improve productivity.';

            const metaKeywords = isArabic
                ? 'كانبان, إدارة المشاريع, تحسين الإنتاجية, سير العمل, حلول تكنولوجيا المعلومات, أدوات كانبان'
                : 'Kanban, project management, productivity improvement, workflow, IT solutions, Kanban tools';

            const metaImage = isArabic
                ? 'https://example.com/kanban-image-ar.jpg'
                : 'https://example.com/kanban-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/kanban-features'
                : 'https://misk.com/kanban-features';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}

</script>