<template>
    <div>
        <Navbar />
        <Banner />
        <Dashboard />
        <ImageGrid />
        <AllTools />
        <CourseData />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Elearning/Banner.vue';
import Dashboard from '../Projects/Elearning/Dashboard.vue';
import ImageGrid from '../Projects/Elearning/ImageGrid.vue';
import AllTools from '../Projects/Elearning/AllTools.vue';
import CourseData from '../Projects/Elearning/CourseData.vue';
import OurClients from '../HomeOne/OurClients'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex';
export default {
    name: 'ProductsPage',
    components: {
        Navbar,
        Banner,
        Dashboard,
        ImageGrid,
        AllTools,
        CourseData,
        OurClients,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'مشاريع التعليم الإلكتروني المبتكرة | حلول التعليم عبر الإنترنت | شركة مسك'
                : 'Innovative E-Learning Projects | Online Education Solutions | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'نقدم حلول التعليم الإلكتروني المتكاملة التي تساعد المؤسسات التعليمية والشركات في تقديم تجارب تعليمية مبتكرة عبر الإنترنت. من المنصات التعليمية إلى الدورات التدريبية عبر الإنترنت، نحن هنا لتحويل التعليم.'
                : 'We provide comprehensive e-learning solutions that help educational institutions and businesses deliver innovative online learning experiences. From learning platforms to online courses, we’re here to transform education.';

            const metaKeywords = isArabic
                ? 'التعليم الإلكتروني, حلول التعليم عبر الإنترنت, منصات التعلم, الدورات التدريبية عبر الإنترنت, تقنيات التعليم, شركة مسك'
                : 'e-learning, online education solutions, learning platforms, online courses, educational technology, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/e-learning-projects-image-ar.jpg'
                : 'https://example.com/e-learning-projects-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/e-learning-projects'
                : 'https://misk.com/e-learning-projects';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "مشاريع التعليم الإلكتروني" : "E-Learning Projects"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>