<template>
    <!-- Popup Modal -->
    <div v-if="isSuccessPopupVisible" class="popup-overlay1">
        <div class="popup-content pt-0">
            <div class="d-flex my-2 pt-4 justify-content-end">
            <span @click="closeSuccessPopup">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 6L18 18" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </span>
            </div>
            <div class="success-animation">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <p>Thank You!</p>
            <p> Your message has been sent. </p>
            <button @click="closeSuccessPopup">close</button>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        isSuccessPopupVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        isForm: {
            type: Boolean,
            required: false,
            default: false
        },
        isComment: {
            type: Boolean,
            required: false,
            default: false
        },
        isPost:{
            type : Boolean,
            required:false,
            default : false
        }
    },
    data() {
        return {
        }
    },
    methods: {
        closeSuccessPopup() {
            this.$emit('close-popup');
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    &:lang(en) {font-family: "Poppins", sans-serif;}
    &:lang(ar) {font-family: "Tajawal", sans-serif;}
}
/*****pop up styling**************/
.popup-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.popup-content {
    background-color: #fff;
    padding: 60px 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 50%;
}

button {
    margin-top: 15px;
    padding: 8px 25px;
    background-color: #00B79D;
    color: white;
    border: none;
    border-radius: 4px;
}
span:hover,button{cursor: pointer;}
p:nth-of-type(1){
    color:  #303030;
    font-size: 24px;
    font-weight: 700;
}
p:nth-of-type(2){
    color:  #8898AA;
    font-size: 22px;
    font-weight: 400;
}
button:hover {
    background-color: #00B79D;
}

.success-animation {
    margin: 30px auto;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #00B79D;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #00B79D;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 3;
    stroke-miterlimit: 10;
    stroke: #00B79D;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #00B79D;
    }
}
@media screen and (max-width: 600px) {
    .popup-content {
     width: 80%; 
    }
}
@media screen and (max-width: 768px) {
    .popup-content {
        padding-top: 0;
        padding-bottom: 20px;
    }
}
</style>