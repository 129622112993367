<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <CaseOverview/>
    <AppFeature/>
    <challenge />
    <what-we-do></what-we-do>
    <ProjectProcess/>
    <key-technology></key-technology>
    <Project />
    <RelatedProjects />
    <Footer />
  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import Challenge from "../Single Case Study/Challenge";
/*import Overview from "../Single Case Study/Overview";*/
import WhatWeDo from "../Single Case Study/WhatWeDo";
import KeyTechnology from "../Single Case Study/KeyTechnology";
import Project from "../Single Case Study/Project";
import RelatedProjects from "../Single Case Study/RelatedProjects";
import Footer from "../Layout/Footer";
import AppFeature from "../Single Case Study/AppFeature";
import ProjectProcess from '../Single Case Study/ProjectProcess';
import CaseOverview from '../Single Case Study/CaseOverview'
import { useHead } from "@vueuse/head";
export default {
  name: "SingleCaseStudy",
  components: {
    NavbarStyleTwo,
    Challenge,
    /*Overview,*/
    "what-we-do": WhatWeDo,
    "key-technology": KeyTechnology,
    RelatedProjects,
    Project,
    Footer,
    ProjectProcess,
    CaseOverview,
    AppFeature
},
  setup(){
      useHead({
          title: 'Misk - Single Case Study',
          meta :[
              {
                  name: 'description',
                  content: 'About page '
              }
          ]
      })
  }
};
</script>
