<template>
     <div class="container pt-70 pb-100 product-header">
        <header class="text-center row">
                <h1 class="mt-3"> 
                    Get your exceptional E-Learning<br> system with Misk! 
                </h1>
                <p>
                    We provide innovative solutions tailored to meet the different needs of<br> educational institutions worldwide. 
                </p>
                <div class="btn-container">
                    <router-link to="/contact">Get your system Now</router-link>
                </div>
        </header>
    </div>
</template>