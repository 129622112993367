<template>
    <div class="services-faq-section pt-35 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title section-align my-2 my-lg-0  pe-lg-5">
                        <h2>
                            {{ $t('Seofaq.H2') }}
                        </h2>
                        <p></p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp"  alt="faq image" width="610" height="343">
                    </div>
                </section>
                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('Seofaq.Qestion1Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('Seofaq.Question1P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('Seofaq.Qestion2Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('Seofaq.Question2span') }}
                                        <ul>
                                            <li>
                                                {{ $t('Seofaq.Question2item1') }}
                                            </li>
                                            <li>
                                                {{ $t('Seofaq.Question2item2') }}
                                            </li>
                                            <li>
                                                {{ $t('Seofaq.Question2item3') }}
                                            </li>
                                        </ul>
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('Seofaq.Qestion4Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('Seofaq.Question4P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('Seofaq.Qestion5Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('Seofaq.Question5P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('Seofaq.Qestion6Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('Seofaq.Question6P') }}
                                    </p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";
export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>