<template>
    <div>
        <Navbar />
        <page-title></page-title>
        <SubscribeForm />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import SubscribeForm from '../Subscribe/SubscribeForm'
import Footer from '../Layout/Footer'
import PageTitle from '../Subscribe/PageTitle'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex'
export default {
    name: 'Subscribe',
    components: {
        Navbar,
        SubscribeForm,
        PageTitle,
        Footer
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },

    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'اشترك في نشرتنا الإخبارية | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Subscribe to Our Newsletter | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اشترك في نشرتنا الإخبارية للحصول على آخر التحديثات والعروض الحصرية من شركة مسك لحلول تكنولوجيا المعلومات. احصل على معلومات حول أحدث التقنيات، والحلول المبتكرة، وأحدث الأخبار.'
                : 'Subscribe to our newsletter to receive the latest updates and exclusive offers from Misk IT Solutions. Stay informed about the latest technologies, innovative solutions, and industry news.';

            const metaKeywords = isArabic
                ? 'اشترك في النشرة الإخبارية, أخبار تقنية, عروض حصرية, حلول تكنولوجيا المعلومات, مسك لحلول تكنولوجيا المعلومات, آخر التحديثات, الحلول المبتكرة'
                : 'subscribe to newsletter, tech news, exclusive offers, IT solutions, Misk IT Solutions, latest updates, innovative solutions';

            const metaImage = isArabic
                ? 'https://example.com/subscribe-ar.jpg'
                : 'https://example.com/subscribe-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/subscribe'
                : 'https://misk.com/subscribe';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        },
        mounted() {
            this.updateHeadMeta(); // Set metadata when component mounts
        },
        watch: {
            currentLanguage: {
                handler() {
                    this.updateHeadMeta(); // Update metadata when language changes
                },
                immediate: true,
            },
        },

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>