<template>
    <div>
        <div class="container pt-35 pb-35">
            <div class="text-center services-btn">
                <router-link to="/services" class="read-btn"> {{ $t('HomeContent.services.button') }}
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M10.125 13.0687C10.0125 12.9562 9.95625 12.8219 9.95625 12.6656C9.95625 12.5094 10.0063 12.3812 10.1063 12.2812L12.8625 9.525H3.5625C3.4 9.525 3.26563 9.47187 3.15938 9.36562C3.05313 9.25937 3 9.125 3 8.9625C3 8.8 3.05313 8.66562 3.15938 8.55938C3.26563 8.45312 3.4 8.4 3.5625 8.4H12.8625L10.0875 5.625C9.975 5.5125 9.92188 5.38125 9.92813 5.23125C9.93438 5.08125 9.99375 4.95 10.1063 4.8375C10.2188 4.7375 10.3531 4.6875 10.5094 4.6875C10.6656 4.6875 10.7938 4.7375 10.8938 4.8375L14.625 8.56875C14.6875 8.63125 14.7313 8.69375 14.7563 8.75625C14.7812 8.81875 14.7938 8.8875 14.7938 8.9625C14.7938 9.0375 14.7812 9.10625 14.7563 9.16875C14.7313 9.23125 14.6875 9.29375 14.625 9.35625L10.9125 13.0687C10.8 13.1812 10.6688 13.2375 10.5188 13.2375C10.3688 13.2375 10.2375 13.1812 10.125 13.0687Z" fill="#00B79D"/>
                        </svg>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name:'ServicesBtn'
}
</script>
