<template>
  <div class="row align-items-center">
    <div class="col-md-6  order-2 order-md-1">
      <div class="audience-content">
        <h2 class="special-color">{{ title }}</h2>
        <div class="bar"></div>
        <p class="text-black mb-1 fw-bold" style="font-weight: 500; color: #303030;" v-if="SubHeading">
          {{ $t('acronisBackup.description1') }}
        </p>
        <p class="pt-1 mt-1"> {{ description }}</p>
        <div :style="{ borderRadius: borderRadius }" v-if="showbutton" >
          <a :href="buttonLink" class="d-inline-flex justify-content-center align-items-center rounded-0 px-3 audience-btn">
            {{ buttonText }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6 mt-5 order-1 order-md-2 mt-lg-1">
      <div class="audience-image">
        <img :src="imageSrc" :alt="imageAlt" :width="imageWidth" :height="imageHeight">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    showbutton: {
      type: Boolean,
      default: true
    },
    buttonLink: {
      type: String,
      default: '#'
    },
    specialColor: {
      type: Boolean,
      default: true
    },
    SubHeading :{
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Request Quote'
    },
    borderRadius: {
      type: String,
      default: '5px'
    },
    imageSrc: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String,
      default: 'image'
    },
    imageWidth: {
      type: [String, Number],
      default: 424
    },
    imageHeight: {
      type: [String, Number],
      default: 297
    }
  }
};
</script>
<style lang="scss" scoped>
/* Add any necessary styles for the component */
.audience-image {
  &:lang(en) {
    text-align: right;
  }
  &:lang(ar) {
    text-align: left;
  }
}
.audience-content p {
  color: #303030!important;
  font-size: 20px;
}
.special-color {
  color: var(--Primary, #00B79D)
}
@media screen and (max-width: 768px) {
  .audience-image {
    &:lang(en) {
      text-align: center;
    }
    &:lang(ar) {
      text-align: center;
    }
  }
}
</style>