<template>
  <section class="container-fluid mx-0 pt-100">
    <div class="grid-layout mb-2">
      <div class="img-border">
        <img src="../../../assets/images/TopBar(2).png" alt="bar image" width="554" height="32">
        <img src="../../../assets/images/grid-1.png" class="img-fluid" alt="gride 1 image " height="1024" width="1440"  v-if="$i18n.locale === 'en'" 
          style="width: 1070px; height: 93%;">
        <img src="../../../assets/images/grid-1-ar.png" class="img-fluid" alt="gride 2 image" height="1024" width="1440"  v-if="$i18n.locale === 'ar'" 
          style="width: 1070px; height: 93%;">
      </div>
      <div class="grid-content">
        <div>
          <span class="text-uppercase hover-effect-2 d-inline-block">{{ $t('learningGrid.courses_label') }}</span>
          <h2 class="text-capitalize hover-effect-2 mt-4">
            {{ $t('learningGrid.heading') }}
          </h2>
        </div>
        <p class="fw-normal hover-effect-2">
          {{ $t('learningGrid.description') }}
        </p>
        <div class="banner-btn">
          <a href="#" class="default-btn text-capitalize hover-effect-2"> {{ $t('learningGrid.button_text') }} </a>
        </div>
      </div>
    </div>
    <div class="bottom-img-grid">
      <div>
        <img src="../../../assets/images/grid-2.png" alt="grid 3 image" width="544" height="213"  v-if="$i18n.locale === 'en'">
        <img src="../../../assets/images/grid-6-ar.png" alt="grid 3 image" width="544" height="213"  v-if="$i18n.locale === 'ar'">
      </div>
      <div>
        <img src="../../../assets/images/grid-3.png" alt="grid 4 image" height="213" width="211" v-if="$i18n.locale === 'en'">
        <img src="../../../assets/images/grid-3.png" alt="grid 4 image" height="213" width="211" v-if="$i18n.locale === 'ar'">
      </div>
      <div>
        <img src="../../../assets/images/grid-4.png" alt="grid 5 image" height="213" width="472" v-if="$i18n.locale === 'en'">
        <img src="../../../assets/images/grid-3-ar.png" alt="grid 5 image" height="213" width="472" v-if="$i18n.locale === 'ar'">
      </div>
      <div>
        <img src="../../../assets/images/grid-5.png" alt="grid 6 image" height="213" width="444" v-if="$i18n.locale === 'en'">
        <img src="../../../assets/images/grid-2-ar.png" alt="grid 3 image" height="213" width="444" v-if="$i18n.locale === 'ar'">
      </div>
    </div>
  </section>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
* {
  &:lang(en) {
    font-family: "Poppins", sans-serif;
  }
  &:lang(ar) {
    font-family: "Tajawal", sans-serif;
  }
}
.grid-content {
  gap: 32px;
  padding: 80px;
  background: #0E4E66;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 32px auto 32px auto;
  grid-template-rows: auto auto auto;
  span {
    color: #00B79D;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 3px;
  }
  h2,
  p {
    color: #fff;
  }
  h2 {
    font-size: 56px;
    font-weight: 800;
    line-height: 110%;
  }
  p {
    font-size: 18px;
    line-height: 160%;
  }
  .default-btn {
    border-radius: 50px;
    -webkit-box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  }
}
.img-border {
  border: 5px solid #D9F9F4;
}
.bottom-img img {
  width: 100%;
  height: 213px;
}
.grid-layout {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 16px auto;
  grid-template-columns: 50% auto;
  gap: 16px;
}
.bottom-img-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3fr 10px 1fr 10px 2fr 10px 1fr;
  grid-template-columns: 3fr 1fr 2fr 1fr;
  gap: 10px;
  img {
    width: 100%;
    height: 213px;
  }
}
.img-border img {
  display: block;
  max-width: 100%;
  height: auto;
}
.banner-btn {
  margin-top: 16px;
}
/**************************/
@media screen and (min-width:992.9px) and (max-width:1200px) {
  .grid-layout {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto 16px auto;
    grid-template-rows: auto auto;
    gap: 16px;
  }
  .grid-layout>*:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .grid-layout>*:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .bottom-img-grid img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width:992px) {
  .grid-content {
    padding: 60px 40px;
    h2 {
      font-size: 35px;
    }
  }
  .grid-layout {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto 16px auto;
    grid-template-rows: auto auto;
    gap: 16px;
  }
  .grid-layout>*:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .grid-layout>*:nth-child(2) {
    grid-row: 3;
    grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  .bottom-img-grid {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .bottom-img-grid>*:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .bottom-img-grid>*:nth-child(2) {
    grid-row: 1;
    grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .bottom-img-grid>*:nth-child(3) {
    grid-row: 2;
    grid-column: 1;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  .bottom-img-grid>*:nth-child(4) {
    grid-row: 2;
    grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
}
</style>