<template>
  <div>
    <div class="container pt-35 pb-70 clients">
      <div class="section-title">
        <h2>{{ $t("HomeContent.clients.h2") }}</h2>
        <p>
          {{ $t("HomeContent.clients.p") }}
        </p>
        <div class="bar"></div>
      </div>
    </div>
    <div class="container-fluid our-clients">
      <Carousel
        v-bind="settings"
        :breakpoints="breakpoints"
        :autoplay="2000"
        :wrap-around="true"
        :dir="direction"
      >
        <Slide v-for="slide in slides" :key="slide">
          <div class="carousel__item">
            <img
              :src="slide.imgUrl"
              :alt="slide.altText"
              :width="slide.width"
              :height="slide.height"
            />
          </div>
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "OurClients",
  components: {
    Pagination,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      direction: localStorage.getItem("direction") || "rtl",
      slides: [
        {
          imgUrl: require("../../assets/images/Images/elite-minds.webp"),
          altText: "elite minds",
          width: 134,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/taam.webp"),
          altText: "taam",
          width: 140,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/pm-tricks.webp"),
          altText: "pm tricks",
          width: 250,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/mohandsi.webp"),
          altText: "mohandsi logo",
          width: 150,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/ideal-way.webp"),
          altText: "ideal way logo",
          width: 134,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/pm-tricks.webp"),
          altText: "pm tricks",
          width: 250,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/academy.webp"),
          altText: "academy",
          width: 100,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/BUILDERS-CN.webp"),
          altText: "BUILDERS CN logo",
          width: 230,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/mucaz-Logo.webp"),
          altText: "mucaz-Logo",
          width: 134,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/pm-tricks.webp"),
          altText: "pm tricks",
          width: 250,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/edara.webp"),
          altText: "edara",
          width: 134,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/handasa-tricks.webp"),
          altText: "handasa tricks logo",
          width: 134,
          height: 118,
        },

        {
          imgUrl: require("../../assets/images/Images/mcp-academy.webp"),
          altText: "mcp academy logo",
          width: 180,
          height: 118,
        },
        {
          imgUrl: require("../../assets/images/Images/st-tricks.webp"),
          altText: "st tricks logo",
          width: 134,
          height: 118,
        },

        {
          imgUrl: require("../../assets/images/Images/king-saud.webp"),
          altText: "king saud logo",
          width: 200,
          height: 118,
        },

        /*{
                    imgUrl: require('../../assets/images/kaspersky.png'),
                    altText: 'team image',
                    width: 134,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/logo-login-lsw.png'),
                    altText: 'team image',
                    width: 134,
                    height: 118,
                },
                /*{
                    imgUrl: require('../../assets/images/EnomLogo.jpg'),
                    altText: 'team image',
                    width: 134,
                    height: 118,
                }*/
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        900: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "center",
        },
      } /*********/,
    };
  },
  mounted() {
    window.addEventListener("direction-localstorage-changed", (event) => {
      this.direction = event.detail.storage;
    });
  },
});
</script>

<style scoped>
.carousel__viewport {
  perspective: 2000px;
}
.carousel__slide {
  position: relative;
}
.carousel__pagination {
  margin-top: 10px;
}
.client-navigation {
  position: absolute;
}
.carousel__slide {
  border: 1px solid #b3b3ac;
  border-radius: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
