<template>
    <div class="overview-section pt-70 pb-35">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('HomeContent.process.processH3') }}</h2>
                <!----<h2>Our Working  Process</h2>-->
                <p>
                    {{ $t('HomeContent.process.processP') }}
                </p>
                <div class="bar"></div>
            </div>

            <div class="row align-content-center justify-content-between mt-3">
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content text-center">
                        <div class="icon">
                            <img src="../../assets/images/find-new-ideas.svg" alt="find new Ideas">
                        </div>

                        <h3>
                            {{ $t('HomeContent.process.processStep1') }}
                        </h3>
                        <p>
                            {{ $t('HomeContent.process.processStep1-p') }}
                        </p>
                        <div class="first-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                    
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/digital-analysis.svg" alt="find new Ideas">
                        </div>

                        <h3>
                            {{ $t('HomeContent.process.processStep2') }}
                        </h3>
                        <p>
                            {{ $t('HomeContent.process.processStep2-p') }}
                        </p>
                        <div class="second-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/market-research.svg" alt="find new Ideas">
                        </div>

                        <h3>
                            {{ $t('HomeContent.process.processStep3') }}
                        </h3>
                        <p>
                            {{ $t('HomeContent.process.processStep3-p') }}
                        </p>
                        <div class="third-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-creation.svg" alt="find new Ideas">
                        </div>

                        <h3>
                            {{ $t('HomeContent.process.processStep4') }}
                        </h3>
                        <p>
                            {{ $t('HomeContent.process.processStep4-p') }}
                        </p>
                        <div class="forth-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-launching.svg" alt="find new Ideas">
                        </div>

                        <h3>
                            {{ $t('HomeContent.process.processStep5') }}
                        </h3>
                        <p>
                            {{ $t('HomeContent.process.processStep5-p') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Overview'
    }
</script>