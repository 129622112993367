<template> 
    <section class="main-banner-area-three">
        <div class="container mt-md-5 mb-md-5 ">
            <div class="main-banner-content mt-md-5">
                <h1 class="hover-effect-1">{{ $t('Projects.main_banner.heading') }}</h1>
                <p class="mx-auto hover-effect-1">
                    {{ $t('Projects.main_banner.description') }}
                </p>

                <div class="button-toggler d-flex justify-content-center align-items-center gap-4  position-relative">
                    <!----<div class="button-toggler-smart d-flex justify-content-center align-items-center gap-3">
                        <div class="button-toggler-smart--regular">
                            {{ $t('Projects.main_banner.button_toggler.regular_product') }}
                        </div>
                        <div class="button-toggler-smart--sass">
                            SASS product
                        </div>
                    </div>-->
                    <div class="button-toggler-features" @click="showFeatures">
                        {{ $t('Projects.main_banner.button_toggler.features') }}
                    </div>
                    <MenuLayout :showMenuLayout="showMenuLayout" @close-popup="closeLayout" />
                </div>
                <p class="hover-effect-1">
                    {{ $t('Projects.main_banner.advanced_system') }}
                </p>
                <div class="image-span text-center hover-effect-1">
                    <span>
                        <img src="../../assets/images/Click-up-Logo-1-1.svg" alt="logo" width="23" height="24">
                    </span>
                    <span>
                        {{ $t('Projects.main_banner.image_caption.description') }}
                    </span>
                </div>
                <div class="project-banner-btn text-center mt-2">
                    <a href="#" class="hover-effect-2 d-inline-block">{{ $t('Projects.main_banner.book_session_button')
                        }} </a>
                </div>
            </div>

            <section class="banner-images d-flex justify-content-center align-items-center">
                <div>
                    <img src="../../assets/images/banner-screen-2.png" alt="dashboard screenshot" width="405"
                        height="267">
                </div>
                <div>
                    <img src="../../assets/images/banner-screen-1.png" alt="dashboard screenshot" width="496"
                        height="385">
                </div>
                <div>
                    <img src="../../assets/images/banner-screen-3.png" alt="dashboard screenshot" width="487"
                        height="358">
                </div>
            </section>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </section>
</template>

<script>
import MenuLayout from './MenuLayout.vue';

export default {
    name: 'Heading',
    components: {
        MenuLayout
    },
    data() {
        return {
            showMenuLayout: false
        }
    },
    methods: {
        showFeatures() {
            this.showMenuLayout = !this.showMenuLayout
        },
        closeLayout() {
            this.showMenuLayout = false
        }
    }
}
</script>
<style lang="scss" scoped>
* {
    &:lang(en) {
        font-family: "Poppins", sans-serif;
    }

    &:lang(ar) {
        font-family: "Tajawal", sans-serif;
    }
}

.main-banner-area-three {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-image: url('../../assets/images/projectbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    h1 {
        background: linear-gradient(270deg, #0E4E66 13.92%, #00B79D 105.3%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 60px;
        font-weight: 700;
        text-transform: capitalize;
    }

    P {
        font-size: 25px;
    }

    p,
    .image-span span:nth-of-type(2) {
        text-align: center;
        font-weight: 400;
        line-height: 45px;
        text-transform: capitalize;
    }

    p:nth-of-type(1) {
        color: #000;
        margin-top: 35px;
        margin-bottom: 32px;
        width: 80%;
    }

    p:nth-of-type(2) {
        color: #37474F;
        margin-top: 50px;
    }

    .image-span {
        span:nth-of-type(2) {
            color: #37474F;
            font-size: 18px;
        }
    }
}

.project-banner-btn {
    a {
        color: #00B79D;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-decoration-line: underline;
        text-transform: capitalize;
    }
}

.button-toggler {

    .button-toggler-features,
    .button-toggler-smart {
        border-radius: 20px;
        background: linear-gradient(90deg, #00B79D 0%, #0E4E66 100%);
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 45px;
        text-transform: capitalize;
        padding: 8px 18px;
    }

    .button-toggler-features {
        font-size: 25px;
        padding: 14px 34px;
        transition: 0.2s ease-in-out;
        border: 1px solid transparent;

        &:hover {
            cursor: pointer;
            background: linear-gradient(270deg, #0E4E66 13.92%, #00B79D 105.3%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border: 1px solid #0E4E66;
        }
    }

    .button-toggler-smart--regular,
    .button-toggler-smart--sass {
        text-align: center;
        font-size: 16px;
    }

    .button-toggler-smart--regular {
        color: #00B79D;
        padding: 8px 18px;
        border-radius: 20px;
        background: #D3FBF4;
    }
}

.banner-images {
    padding-top: 70px;
    position: relative;

    div:nth-of-type(2) {
        transform: translateZ(100px);

        img {
            transform: scale(1.25);
        }
    }

    div:nth-of-type(3) {
        position: sticky;
        z-index: -1;

        img {
            transform: scale(0.9);

        }
    }
}

/**********************/
@media only screen and (max-width: 767px) {
    .main-banner-area-three {
        P {
            font-size: 18px;
            line-height: 30px;
        }

        p:nth-of-type(1) {
            width: 98%;
        }
    }

    .main-banner-area-three span:nth-of-type(2),
    .project-banner-btn a {
        font-size: 16px;
    }

    .button-toggler {
        .button-toggler-features {
            font-size: 18px;
            padding: 8px 30px;
        }

        .button-toggler-smart--regular,
        .button-toggler-smart--sass {
            font-size: 16px;
            padding: 4px 12px;
        }

    }

    .banner-images {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
</style>