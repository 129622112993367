<template>
    <div>
        <Navbar />
        <hosting-header></hosting-header>
        <HostingFeatures />
        <Pricing />
        <HostingTransfer></HostingTransfer>
        <faq></faq>
        <hosting-contact></hosting-contact>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import HostingHeader from '../Hosting/HostingHeader'
import Pricing from '../Hosting/Pricing'
import HostingFeatures from '../Hosting/HostingFeatures'
import HostingTransfer from '../Hosting/HostingTransfer'
import HostingContact from '../Hosting/HostingContact'
import Faq from '../Hosting/HostingFaq.vue'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex';

export default {
    name: 'Hosting',
    components: {
        Navbar,
        HostingHeader,
        Pricing,
        HostingFeatures,
        HostingTransfer,
        HostingContact,
        Faq,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']), 
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'استضافة مواقع موثوقة وفعالة | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Reliable and Efficient Web Hosting Services | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف حلول استضافة المواقع المتكاملة من شركة مسك التي توفر أداءً عاليًا، أمانًا متقدمًا، ودعمًا فنيًا على مدار الساعة لموقعك.'
                : 'Explore Misk IT Solutions\' comprehensive web hosting services offering high performance, advanced security, and 24/7 support for your website.';

            const metaKeywords = isArabic
                ? 'استضافة مواقع, استضافة ويب, استضافة سحابية, استضافة موثوقة, استضافة فعالة, خدمات استضافة المواقع'
                : 'web hosting, hosting services, cloud hosting, reliable hosting, efficient hosting, website hosting solutions';

            const metaImage = isArabic
                ? 'https://example.com/hosting-image-ar.jpg'
                : 'https://example.com/hosting-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/hosting'
                : 'https://misk.com/hosting';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>