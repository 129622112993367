<template>
    <section class="main-banner-area overflow-hidden position-relative">
        <div class="home-sliders pb-35 pt-4">
            <div>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="row d-flex justify-content-xl-between align-items-center">
                                <div class="main-banner-content col-12 col-lg-6 order-2 order-lg-1">
                                    <div class="position-relative">
                                        <h1 class="pt-4 pt-lg-0 hover-effect-1">{{ Banner.title }}</h1>
                                        <span class="line">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="188" height="15"
                                                viewBox="0 0 188 15" fill="none">
                                                <path d="M187 13C111.058 -1.03221 70.3651 -2.28767 0.999991 13"
                                                    stroke="#13BCA4" stroke-width="4" />
                                            </svg>
                                        </span>
                                    </div>

                                    <div class="position-relative">
                                        <p class="hover-effect-1">{{ Banner.description }}</p>
                                    </div>

                                    <div class="banner-btn">
                                        <router-link :to="Banner.buttonLink" class="default-btn-1">
                                            {{ Banner.buttonText }}
                                        </router-link>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-6 text-center order-1 order-lg-2 align-self-end">
                                    <img :src="Banner.image" class="img-fluid top-animate" :alt="Banner.imageAlt"
                                        :width="Banner.imageWidth" :height="Banner.imageHeight" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="default-shape">
            <div class="shape-1">
                <img src="../../../assets/images/shapes/shape-4.png" alt="image">
            </div>
            <div class="shape-2 rotateme">
                <img src="../../../assets/images/shapes/shape-5.svg" alt="image">
            </div>
            <div class="shape-3">
                <img src="../../../assets/images/shapes/shape-6.svg" alt="image">
            </div>
            <div class="shape-4">
                <img src="../../../assets/images/shapes/shape-7.png" alt="image">
            </div>
            <div class="shape-5">
                <img src="../../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        Banner: {
            type: Object,
            required: true,
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    &:lang(en) {
        font-family: "Poppins", sans-serif;
    }
    &:lang(ar) {
        font-family: "Tajawal", sans-serif;
    }
  }
.main-banner-area {
    padding-top: 50px;
    margin-bottom: 80px;
}

h1,
p {
    color: var(--Black, #121212);
}

h1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0.2px;
}

P {
    font-size: 20px;
    font-weight: 400;
    line-height: 147%;
    margin-top: 32px;

    &:lang(en) {
        padding-left: 25px;

        &::before {
            left: 0;
        }
    }

    &:lang(ar) {
        padding-right: 25px;

        &::before {
            right: 0;
        }
    }

    &::before {
        content: '';
        display: block;
        width: 10px;
        height: 52px;
        top: 3px;
        position: absolute;
        background: #000;
    }
}

.banner-btn {
    margin-top: 60px;
}

.banner-btn .default-btn-1 {
    &::first-letter {
        text-transform: capitalize;
    }

    display: inline-block;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 157%;
    border-radius: 5px;
    padding: 20px 40px;
    background: var(--Primary, #00B79D);
    transition: 0.2s;

    &:hover {
        transform: translateY(5px);
    }
}

@media screen and (max-width:992px){
    .main-banner-area {
        padding-top: 40px;
        margin-bottom: 40px;
    }
}
</style>