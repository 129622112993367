<template>
    <div>
        <section class="container dev-custom">
            <header class="section-title">
                <h2 v-html="$t('webDevelopment.title')"></h2>
            </header>
            <section>
                <div class="row">
                    <div class="col-md-6 col-lg-4 mt-3 ">
                        <div class="dev-custom-content ">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/enterprise-solution.svg"
                                        alt="Enterprise Solution Icon">
                                </div>
                                <div class="col-10">
                                    <h3>{{ $t('webDevelopment.sections[0].title') }}</h3>
                                    <p>{{ $t('webDevelopment.sections[0].description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4  mt-3 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/carbon_analytics.svg" alt="analytics icon">
                                </div>
                                <div class="col-10">
                                    <h3>{{ $t('webDevelopment.sections[1].title') }}</h3>
                                    <p>{{ $t('webDevelopment.sections[1].description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4  mt-3 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/mobile-app.svg" alt="mobile-app">
                                </div>
                                <div class="col-10">
                                    <h3>{{ $t('webDevelopment.sections[2].title') }}</h3>
                                    <p>{{ $t('webDevelopment.sections[2].description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-----------Start Of Second Row------------------->
                    <div class="col-md-6 col-lg-4 mt-5 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/open-source.svg" alt="open source development icon">
                                </div>
                                <div class="col-10">
                                    <h3>{{ $t('webDevelopment.sections[3].title') }}</h3>
                                    <p>{{ $t('webDevelopment.sections[3].description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mt-5 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/web-development-svgrepo.svg"
                                        alt="web development icon">
                                </div>
                                <div class="col-10">
                                    <h3>{{ $t('webDevelopment.sections[4].title') }}</h3>
                                    <p>{{ $t('webDevelopment.sections[4].description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mt-5 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/dev-icon-1.svg" alt="development icon">
                                </div>
                                <div class="col-10">
                                    <h3>{{ $t('webDevelopment.sections[5].title') }}</h3>
                                    <p>{{ $t('webDevelopment.sections[5].description')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-------------End Of Second Row------------------->
                </div>
            </section>
        </section>
    </div>
</template>
<script>
export default {
    name: 'DevCustom'
}
</script>