<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <ui-header></ui-header>
        <seo-features></seo-features>
        <ui-overview></ui-overview>
        <design-process></design-process>
        <Digital></Digital>
        <ui-work></ui-work>
        <ui-work-with-us></ui-work-with-us>
        <Faq />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import UiHeader from '../SeoServices/UiHeader'
import UiOverview from '../SeoServices/UiOverview'
import DesignProcess from '../SeoServices/DesignProcess'
import UiWork from '../SeoServices/UiWork'
import Digital from '../SeoServices/Digital'
import SeoFeatures from '../SeoServices/SeoFeatures'
import UiWorkWithUs from '../SeoServices/UiWorkWithUs'
import Footer from '../Layout/Footer'
import Faq from '../SeoServices/Faq'
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex';
export default {
    components: {
        NavbarStyleTwo,
        UiHeader,
        UiWork,
        UiOverview,
        DesignProcess,
        UiWorkWithUs,
        SeoFeatures,
        Digital,
        Footer,
        Faq
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'خدمات تحسين محركات البحث | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'SEO Services | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف خدمات تحسين محركات البحث (SEO) التي نقدمها في شركة مسك. نحن نساعد الشركات على زيادة ظهورها على الإنترنت وجذب المزيد من العملاء من خلال استراتيجيات SEO مخصصة.'
                : 'Explore the SEO services we offer at Misk IT Solutions. We help businesses enhance their online visibility and attract more customers through tailored SEO strategies.';

            const metaKeywords = isArabic
                ? 'تحسين محركات البحث, خدمات SEO, تحسين محركات البحث للمواقع, استراتيجيات SEO, خدمات الإنترنت, تسويق الكتروني, تحسين محركات البحث المحلية, زيادة الظهور على الإنترنت'
                : 'SEO services, search engine optimization, website SEO, SEO strategies, internet marketing, local SEO, improve online visibility';

            const metaImage = isArabic
                ? 'https://example.com/seo-services-ar.jpg'
                : 'https://example.com/seo-services-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/seo-services'
                : 'https://misk.com/seo-services';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>