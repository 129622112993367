<template>
    <div>
        <div class="container">
            <Section class="digital-overview pt-35 pb-35">
            <div class="row ">
                <div class="col-lg-6 hover-effect">
                    <header class="pt-1 pt-lg-5">
                     
                        <h2 class="span-overview">
                            {{ $t('DigitalMarketing.overview.overviewH2') }}
                        </h2>
                    </header>
                    <p>
                        {{ $t('DigitalMarketing.overview.overviewP1') }}
                    </p>
                    <p>
                        {{ $t('DigitalMarketing.overview.overviewP2') }}
                    </p>
                </div>
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/Images/digital-marketing-overview.webp" alt="overview image" height="537" width="338" class="img-fluid rounded-3"/>
                </div>
            </div>
         </Section>
        </div> 
    </div>
</template>