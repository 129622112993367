<template>
  <section>
    <div class="container">
      <!--------First Section--------------->
      <SectionTwo :features="localizedFeatures1" />
      <!---------Section two--------------->
      <section class="payment">
        <div class="text-center">
          <h4 class="hover-effect-1">
            {{ $t('payment.title') }}
          </h4>
          <p class="hover-effect-1">
            {{ $t('payment.description') }}
          </p>
          <div>
            <img src="../../../assets/images/payment.png" class="hover-effect-1" alt="payment logos" width="1136" height="543">
          </div>
        </div>
      </section>
    </div>
    <!---------Section three--------------->
    <section class="communicate">
      <div class="container">
        <div class="text-center">
          <span class="hover-effect-1 d-inline-block">  {{ $t('payment.description1') }}</span>
          <h4 class="mt-2 hover-effect-1"> {{ $t('payment.title1') }}</h4>
          <p class="hover-effect-1">
            {{ $t('payment.description2') }}
          </p>
          <button class="default-btn text-capitalize">
           {{ $t('payment.button') }}
          </button>
        </div>
      </div>
    </section>
    <div class="container">
      <!---------Section four--------------->
      <SectionTwo :features="localizedFeatures2" />
    </div>
  </section>
</template>
<script>
import SectionTwo from './SectionTwo.vue';
export default {
  components: { SectionTwo },
  data() {
    return {
      Features1: [
        { image: require('../../../assets/images/course-1.png'), image1: require('../../../assets/images/course-1.png'), titleKey: 'courses.title1', descriptionKey: 'courses.description', reverseOrder: true },
        { image: require('../../../assets/images/course-2.png'), image1: require('../../../assets/images/course-2-ar.png'), titleKey: 'courses.title2', descriptionKey: 'courses.description' },
        { image: require('../../../assets/images/course-3.png'),image1: require('../../../assets/images/course-3.png'), titleKey: 'courses.title3', descriptionKey: 'courses.description', reverseOrder: true },
        { image: require('../../../assets/images/course-4.png'), image1: require('../../../assets/images/course-4.png'),titleKey: 'courses.title4', descriptionKey: 'courses.description' },
        { image: require('../../../assets/images/course-5.png'), image1: require('../../../assets/images/course-5-ar.png'),titleKey: 'courses.title5', descriptionKey: 'courses.description', reverseOrder: true },
        { image: require('../../../assets/images/course-6.png'), image1: require('../../../assets/images/course-6-ar.png'),titleKey: 'courses.title6', descriptionKey: 'courses.description' },
        { image: require('../../../assets/images/course-7.png'), image1: require('../../../assets/images/course-7-ar.png'),  titleKey: 'courses.title7', descriptionKey: 'courses.description', reverseOrder: true },
        { image: require('../../../assets/images/course-8.png'),image1: require('../../../assets/images/course-8-ar.png'), titleKey: 'courses.title8', descriptionKey: 'courses.description' },
        { image: require('../../../assets/images/course-9.svg'),image1: require('../../../assets/images/course-9.svg'), titleKey: 'courses.title9', descriptionKey: 'courses.description', reverseOrder: true },
      ],
      Features2: [
        { image: require('../../../assets/images/course-10.png'),image1: require('../../../assets/images/course-10.png'), titleKey: 'courses.title10', descriptionKey: 'courses.description', reverseOrder: true },
        { image: require('../../../assets/images/course-11.png'), image1: require('../../../assets/images/course-11.png'),titleKey: 'courses.title11', descriptionKey: 'courses.description' },
        { image: require('../../../assets/images/course-12.png'),image1: require('../../../assets/images/course-12-ar.png'), titleKey: 'courses.title12', descriptionKey: 'courses.description', reverseOrder: true },
        { image: require('../../../assets/images/course-13.png'),image1: require('../../../assets/images/course-13.png'), titleKey: 'courses.title13', descriptionKey: 'courses.description' },
      ],
    }
  },
  computed: {
    localizedFeatures1() {
      return this.Features1.map(feature => ({
        ...feature,
        title: this.$t(feature.titleKey),
        description: this.$t(feature.descriptionKey)
      }));
    },
    localizedFeatures2() {
      return this.Features2.map(feature => ({
        ...feature,
        title: this.$t(feature.titleKey),
        description: this.$t(feature.descriptionKey)
      }));
    },
  }
}
</script>
<style lang="scss" scoped>
*{
  &:lang(en) {
        font-family: "Poppins", sans-serif;
    }
    &:lang(ar) {
        font-family: "Tajawal", sans-serif;
    }
}
.payment {
  padding: 70px 0;
  h4,
  p {
    line-height: 110%;
    text-transform: capitalize;
  }
  h4 {
    color: #0F172A;
    font-size: 56px;
    font-weight: 800;
  }
  p {
    color: #8898AA;
    font-size: 25px;
    font-weight: 500;
    margin-top: 34px;
    margin-bottom: 40px;
  }
}
.communicate {
  position: relative;
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 3;
    background-repeat: no-repeat;
  }
  &::after {
    width: 300px;
    height: 300px;
    left: 0;
    bottom: -9px;
    background-image: url('../../../assets/images/Ellipses.png');
    background-size: contain;
  }
  &::before {
    width: 500px;
    height: 375px;
    right: 0;
    background-image: url("../../../assets/images/Iphones.png");
    background-size: contain;
    bottom: 0;
  }
  span {
    color: #38EBFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  h4,
  p {
    color: #fff;
  }
  h4 {
    font-size: 45px;
    font-weight: 800;
    line-height: 110%;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
    width: 50%;
    margin: 32px auto;
  }
  button {
    border-radius: 50px;
    -webkit-box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
            box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    position: sticky;
    z-index: 5;
    &:hover{
      cursor: pointer;
      background: #fff;
      color: #2A6A84;
      -webkit-transform: translateY(5px);
          -ms-transform: translateY(5px);
              transform: translateY(5px);
    }
  }
  background: #2A6A84;
  padding: 80px;
}
/***********************************/
@media screen and (max-width:992px) {
  .payment {
    padding: 50px 0;
    h4 {
      font-size: 40px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 559.8px) {
  .communicate{
    padding: 80px 40px;
    h4{
      font-size: 30px;
      font-weight: 700;
    }
    p{
      width: 66%;
    }
  }
  .communicate::before {
    width: 241px;
    height: 180px;
    z-index: 1;
  }
  .communicate::after {
    width: 186px;
    height: 189px;
    z-index: 1;
  }
}
@media screen and (min-width: 560px) and (max-width:767.9px){
  .communicate{
    padding: 80px 60px;
    h4{
      font-size: 43px;
      font-weight: 700;
    }
  }
  .communicate::before {
    width: 319px;
    height: 238px;
    z-index: 1;
  }
}
@media screen and (min-width: 768px) and (max-width:991.8px){
  .communicate::before {
    width: 424px;
    height: 318px;
  }
}
@media screen and (min-width: 992px) and (max-width:1200px) {
  .communicate::before {
    width: 393px;
    height: 293px;
  }
}
</style>