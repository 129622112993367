<template>
    <div class="software-section ptb-100 overflow-hidden">
        <div class="container">
            <div class="row align-items-start">
                <section class="col-lg-6">
                    <div class="software-content">
                        <h2 class="mb-3">{{ $t('miskservices.header.title') }}</h2>
                        <div class="bar"></div>
                        
                        <p class="mt-5">
                            {{ $t('miskservices.description') }}
                        </p>
                    </div>
                </section>

                <section class="col-lg-5 col-lg-offset-1">
                    <div class="row">
                        <div class="col-12">
                            <div class="software-inner-content mb-30 flex-column flex-md-row gap-3 text-center text-md-start hover-effect-1">
                                <div class="icon icon-1 ">
                                   
                                </div>
                                <div>
                                    <h3>{{ $t('miskservices.sections.fast_delivery.title') }}</h3>
                                    <p>
                                        {{ $t('miskservices.sections.fast_delivery.description') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="software-inner-content mb-30 flex-column flex-md-row gap-3 text-center text-md-start second hover-effect-1">
                                <div class="icon icon-2 ">

                                </div>
                                <div>
                                    <h3>{{ $t('miskservices.sections.tailored_solutions.title') }}</h3>
                                    <p>
                                        {{ $t('miskservices.sections.tailored_solutions.description') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="software-inner-content flex-column flex-md-row gap-3 text-center text-md-start hover-effect-1">
                                <div class="icon icon-3 ">
                                </div>
                                <div>
                                    <h3>{{ $t('miskservices.sections.exceptional_support.title') }}</h3>
                                    <p>
                                        {{ $t('miskservices.sections.exceptional_support.description') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BestSoftware'
    }
</script>