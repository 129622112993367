<template>
  <div @click="openWhatsApp" class="whats-bottom-icon" :class="{ 'whats-preload': animateItem }">
    <i class="fa-brands fa-whatsapp"></i>
  </div>
</template>
<script>
export default {
  data() {
    return {
      animateItem: true,
    };
  },
  methods:{
    
    openWhatsApp() {
      const phoneNumber = '+201027027251'; 
      const message = 'Misk Technical Support'; 
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    }
    
  },
  mounted() {
    setTimeout(() => {
      this.animateItem = false;
    }, 2000);
  },
};
</script>
