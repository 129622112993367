<template>
    <div class="main-banner-area">
        <div class="home-sliders" > 
            <carousel :dir="direction"
                :autoplay="10000"
                :settings="settings"
                :wrap-around="true"
                :breakpoints="breakpoints" key="" 
            >
                <slide 
                    v-for="slide in localizedCarouselItems" 
                    :key="slide.id" 
                >
                    <div :class="slide.class">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container" >
                                    <div class="row d-flex  justify-content-xl-between align-items-center">
                                        <div class="main-banner-content  col-12 col-lg-7  order-2 order-lg-1 ">
                                            <div class="position-relative">
                                                <h1 v-html="slide.heading" class="pt-4 pt-lg-0"></h1>
                                                <span v-html="slide.svgLine" class="line"></span>
                                            </div>
                                       
                                            <div class="position-relative">
                                                <p>{{slide.description}}</p>
                                            </div>
                                        
                                            <div class="banner-btn">
                                            <router-link :to="slide.btnLink" class="default-btn">
                                                {{slide.btnText}}
                                            </router-link>
                                            </div>
                                        </div>
 
                                        <div class="banner-image col-12 col-lg-5  text-center  order-1 order-lg-2 align-self-end">
                                            <!----<img src="../../assets/images/shapes/shape-11.png" alt="image">-->

                                            <img :src="slide.image.imgUrl" class="img-fluid" :alt="slide.image.altText" 
                                            :width="slide.image.width" :height="slide.image.height">
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
                
                <template #addons>
                    <Pagination class="d-none d-lg-block" />
                </template>
                
            </carousel>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css';
import { mapGetters } from 'vuex';
export default defineComponent ({
    name: 'Banner',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    mounted(){
         
    },
    data: () => ({ 
        direction : localStorage.getItem('direction') || 'rtl',
        carouselItems: [
            {
                id: 1,
                class: 'home-item',
                heading: 'carouselItems.heading',
                description: 'carouselItems.description',
                btnLink: '/about',
                btnText: 'carouselItems.btnText',
                svgLine:`<svg xmlns="http://www.w3.org/2000/svg" width="188" height="15" viewBox="0 0 188 15" fill="none">
                            <path d="M187 13C111.058 -1.03221 70.3651 -2.28767 0.999991 13" stroke="#13BCA4" stroke-width="4"/>
                        </svg>`,
                image: { 
                    imgUrl: require('../../assets/images/Images/banner-right-image-1.webp'),
                    altText: 'team image',
                    width: 576,
                    height: 411,
                }
            },
            {
                id: 2,
                class: 'home-item',
                heading:'carouselItems.heading2',
                description: 'carouselItems.description2',
                btnLink: '/about',
                btnText: 'carouselItems.btnText',
                image: { 
                    imgUrl: require('../../assets/images/Images/image-1.webp'),
                    altText: 'team image',
                    width: 510,
                    height: 495,
                }
            },
            {
                id: 3,
                class: 'home-item',
                heading: 'carouselItems.heading3',
                description: 'carouselItems.description3',
                btnLink: '/about',
                btnText: 'carouselItems.btnText',
                image: { 
                    imgUrl: require('../../assets/images/Images/image-2.webp'),
                    altText: 'team image',
                    width: 510,
                    height: 495,
                }
            },
        ],
        settings: {
            itemsToShow: 1,
          //  snapAlign: 'left',
        },
    }),
    computed: {
        localizedCarouselItems() {
            return this.carouselItems.map(item => ({
                ...item,
                heading: this.$t(item.heading),
                description: this.$t(item.description),
                btnText : this.$t(item.btnText)
            }));
        },
        ...mapGetters(['currentLanguage']),
    },
    watch: {
        currentLanguage: {
            handler(newval) {
                if(newval === "ar"){
                    this.direction = "rtl"
                }else{
                    this.direction = "ltr"
                }
            },
            immediate: true,
        },
    },
})
</script>
<style lang="scss" scoped>
.carousel__prev,
.carousel__next {
    background: #fff;
    font-size: 38px;
}

</style>