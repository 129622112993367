<template>
    <div>
        <div class="container-fluid">
            <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="true"
                :dir="direction">
                <Slide v-for="slide in slides" :key="slide">
                    <div class="carousel__item">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-img d-flex align-items-center justify-content-start gap-2">
                                <img :src="slide.image.imgUrl" :alt="slide.image.altText" :width="slide.image.width"
                                :height="slide.image.height">
                                <h5 class="card-title">{{ slide.title }}</h5>
                                </div>
                                <p class="card-text">
                                    {{ slide.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel,  Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default defineComponent({
    components: {
        Carousel,
        Slide,
    },
    props:{
        slides:{
            type : Array,
            required : true
        }
    },
    data() {
        return {
            direction: localStorage.getItem('direction') || 'rtl',
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                900: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
            },/*********/
        }
    },
    mounted() {
        window.addEventListener('direction-localstorage-changed', (event) => {
            this.direction = event.detail.storage;
        });
    },
})
</script>
<style lang="scss" scoped>
.carousel__viewport {
    perspective: 2000px;
}
.carousel__slide {
    position: relative;
}
.carousel__pagination {margin-top: 10px;}
.client-navigation {position: absolute;}
.our-clients{
    .carousel__item {
        filter: grayscale(0) !important;
    }
}
.carousel__slide {
   margin-right: 7px;
   margin-left: 7px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.card{
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    h5{
        color:#2A6A84;
        font-size: 22px;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: 0.44px;
        &:lang(ar){
            text-align: right;
        }
        &:lang(en){
            text-align: left;
        }
    }
    p{
        color:  #6B7280;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
        margin-top: 20px;
    }
    h5,p{
        line-height: 160%; 
    }
    .card-body{
        padding: 30px 21px 40px 15px;
    }
}
</style>