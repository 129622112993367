<template>
    <div>
        <section class="container pt-35 pb-35 job-vaccancy mb-5">
            <div class="row">
                <div class="col-lg-6 mt-3 mt-lg-0">
                    <router-link to="/job-overview">
                        <section class="d-flex justify-content-center align-items-start  flex-column flex-md-row justify-content-lg-between
                    job-vaccancy-content gap-4">
                        <div class="job-icon mb-3 mb-lg-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                <path d="M13.418 9.58268H32.5846V13.416H36.418V1.93518L9.58464 1.91602V13.416H13.418V9.58268ZM29.5371 31.7969L38.3346 22.9993L29.5371 14.2018L26.8346 16.9235L32.9105 22.9993L26.8346 29.0752L29.5371 31.7969ZM19.168 29.0752L13.0921 22.9993L19.168 16.9235L16.4655 14.2018L7.66797 22.9993L16.4655 31.7969L19.168 29.0752ZM32.5846 36.416H13.418V32.5827H9.58464V44.0827H36.418V32.5827H32.5846V36.416Z" fill="white"/>
                            </svg>
                        </div>
                        <div>
                            <h4>Full Stack Developer</h4>
                            <p>
                                We are looking for Full Stack Developers to produce scalable web applications solutions.you’ll be part of a cross-functional team that’s responsible for the full software development life cycle,from conception to deployment.
                            </p>
                            <div class="d-flex justify-content-between align-items-center job-footer">
                                <div><i class="fa-regular fa-clock"></i><span class="mx-1">3 Days Left</span></div>
                                <div><i class="fa-solid fa-location-dot"></i><span class="mx-1">El Quesier, Red sea</span></div>
                            </div>
                        </div>
                    </section>
                    </router-link>
                   
                </div>
                <div class="col-lg-6  mt-3 mt-lg-0">
                    <router-link to="/job-overview">
                    <section class="d-flex justify-content-center align-items-start  flex-column flex-md-row justify-content-lg-between
                    job-vaccancy-content gap-4">
                    <div class="job-icon mb-3 mb-lg-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                <path d="M13.418 9.58268H32.5846V13.416H36.418V1.93518L9.58464 1.91602V13.416H13.418V9.58268ZM29.5371 31.7969L38.3346 22.9993L29.5371 14.2018L26.8346 16.9235L32.9105 22.9993L26.8346 29.0752L29.5371 31.7969ZM19.168 29.0752L13.0921 22.9993L19.168 16.9235L16.4655 14.2018L7.66797 22.9993L16.4655 31.7969L19.168 29.0752ZM32.5846 36.416H13.418V32.5827H9.58464V44.0827H36.418V32.5827H32.5846V36.416Z" fill="white"/>
                            </svg>
                        </div>
                        <div >
                            <h4>UX/Ui Designer</h4>
                            <p>
                                We are looking for Full Stack Developers to produce scalable web applications solutions.you’ll be part of a cross-functional team that’s responsible for the full software development life cycle,from conception to deployment.
                            </p>
                            <div class="d-flex justify-content-between align-items-center job-footer">
                                <div><i class="fa-regular fa-clock"></i><span class="mx-1"> 3 Days Left</span></div>
                                <div><i class="fa-solid fa-location-dot"></i><span class="mx-1">El Quesier, Red sea</span></div>
                            </div>
                        </div>
                    </section>
                </router-link>
                </div>
                <div class="col-lg-6  mt-3 mt-lg-4">
                    <section class="d-flex justify-content-center align-items-start  flex-column flex-md-row justify-content-lg-between
                    job-vaccancy-content gap-4">
                        <div class="job-icon mb-3 mb-lg-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                <path d="M13.418 9.58268H32.5846V13.416H36.418V1.93518L9.58464 1.91602V13.416H13.418V9.58268ZM29.5371 31.7969L38.3346 22.9993L29.5371 14.2018L26.8346 16.9235L32.9105 22.9993L26.8346 29.0752L29.5371 31.7969ZM19.168 29.0752L13.0921 22.9993L19.168 16.9235L16.4655 14.2018L7.66797 22.9993L16.4655 31.7969L19.168 29.0752ZM32.5846 36.416H13.418V32.5827H9.58464V44.0827H36.418V32.5827H32.5846V36.416Z" fill="white"/>
                            </svg>
                        </div>
                        <div>
                            <h4>Back End Developer</h4>
                            <p>
                                We are looking for Full Stack Developers to produce scalable web applications solutions.you’ll be part of a cross-functional team that’s responsible for the full software development life cycle,from conception to deployment.
                            </p>
                            <div class="d-flex justify-content-between align-items-center job-footer">
                                <div><i class="fa-regular fa-clock"></i><span class="mx-1"> 3 Days Left</span></div>
                                <div><i class="fa-solid fa-location-dot"></i><span class="mx-1">El Quesier, Red sea</span></div>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="col-lg-6  mt-3 mt-lg-4">
                    <section class="d-flex justify-content-center align-items-start  flex-column flex-md-row justify-content-lg-between
                    job-vaccancy-content gap-4">
                    <div class="job-icon mb-3 mb-lg-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                                <path d="M13.418 9.58268H32.5846V13.416H36.418V1.93518L9.58464 1.91602V13.416H13.418V9.58268ZM29.5371 31.7969L38.3346 22.9993L29.5371 14.2018L26.8346 16.9235L32.9105 22.9993L26.8346 29.0752L29.5371 31.7969ZM19.168 29.0752L13.0921 22.9993L19.168 16.9235L16.4655 14.2018L7.66797 22.9993L16.4655 31.7969L19.168 29.0752ZM32.5846 36.416H13.418V32.5827H9.58464V44.0827H36.418V32.5827H32.5846V36.416Z" fill="white"/>
                            </svg>
                        </div>
                        <div>
                            <h4>Digital Marketing Specialists</h4>
                            <p>
                                We are looking for Full Stack Developers to produce scalable web applications solutions.you’ll be part of a cross-functional team that’s responsible for the full software development life cycle,from conception to deployment.
                            </p>
                            <div class="d-flex justify-content-between align-items-center job-footer">
                                <div><i class="fa-regular fa-clock"></i><span class="mx-1"> 3 Days Left</span></div>
                                <div><i class="fa-solid fa-location-dot"></i><span class="mx-1">El Quesier, Red sea</span></div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </section>
    </div>
</template>