<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner />
        <Development />
        <new-services></new-services>
        <ServicesBtn></ServicesBtn>
        <Features />
        <Pricing />
        <Audience />
        <Design />
        <OurClients />
        <overview />
        <ClientsOverview />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../HomeOne/Banner'
/*import FunFacts from '../HomeOne/FunFacts'*/
import Features from '../HomeOne/Features'
/*import MiskNumbers from '../HomeOne/MiskNumbers'
import Services from '../HomeOne/Services'
import Achievements from '../HomeOne/Achievements'*/
import Development from '../HomeOne/Development'
import ServicesBtn from '../HomeOne/ServicesBtn';
import Pricing from '../HomeOne/Pricing'
import Audience from '../HomeOne/Audience'
import Design from '../HomeOne/Design'
import OurClients from '../HomeOne/OurClients'
import Overview from '../HomeOne/Overview'
import ClientsOverview from '../HomeOne/ClientsOverview'
import Footer from '../Layout/Footer'
import NewServices from '../HomeOne/NewServices'
import { mapGetters } from 'vuex/dist/vuex.cjs.js';
import { useHead } from '@vueuse/head';
export default {
    name: 'HomePageOne',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        ServicesBtn,
        Features,
        Audience,
        Overview,
        Development,
        Design,
        Pricing,
        OurClients,
        ClientsOverview,
        Footer,
        NewServices
        /*MiskNumbers,
        Achievements,*/
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'شركة مسك لحلول تكنولوجيا المعلومات | خدمات تقنية مبتكرة لأعمالك'
                : 'Misk IT Solutions | Innovative IT Services for Your Business';

            const metaDescription = isArabic
                ? 'تقدم شركة مسك لحلول تكنولوجيا المعلومات خدمات تقنية متطورة تشمل تطوير البرمجيات، الحوسبة السحابية، وحلول التحول الرقمي لتمكين الشركات حول العالم.'
                : 'Misk IT Solutions offers cutting-edge technology services, including software development, cloud computing, and digital transformation solutions to empower businesses worldwide.';

            const metaKeywords = isArabic
                ? 'شركة مسك, حلول تكنولوجيا المعلومات, تطوير البرمجيات, الحوسبة السحابية, التحول الرقمي, حلول تقنية مبتكرة, تقنية الأعمال'
                : 'Misk IT Solutions, IT services, software development, cloud computing, digital transformation, business IT solutions, innovative technology';

            const metaImage = isArabic
                ? '../../assets/images/home-2.png'
                : '../../assets/images/home-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar'
                : 'https://misk.com';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "../../assets/images/misk-main-logo.svg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>
