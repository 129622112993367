<template>
    <div class="main-banner-area-three pb-100 overflow-hidden">
        <div class="d-table">
            <div class="d-table-cell pt-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 img-align">
                            <div class="position-relative">
                                <!----<img src="../../../assets/images/dashboard.png" class="img-fluid" width="600"
                                    height="406" />-->
                                <img src="../../../assets/images/dashboard.png" class="img-fluid" width="600"
                                    height="406" v-if="$i18n.locale === 'en'" alt="exculsive learning  page">
                                <img src="../../../assets/images/dashboard-ar.png" class="img-fluid" width="600"
                                    v-if="$i18n.locale === 'ar'" alt="exculsive learning  page">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="main-banner-content position-relative">
                                <h2 class="text-capitalize pt-4 pt-lg-1">
                                    {{ $t('Learningdashboard.heading') }}
                                </h2>
                                <div class="abstract-banner">
                                    <img src="../../../assets/images/Icons.svg" alt="icons" width="176" height="176">
                                </div>
                                <!---------Start Of tabs------------>
                                <div class="tabs-container">
                                    <div class="tabs-header">
                                        <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = index"
                                            :class="{ active: activeTab === index }" class="tab-button">
                                            {{ tab.name }}
                                        </button>
                                    </div>
                                    <div class="tabs-content overflow-hidden">
                                        <div v-if="activeTab === 0" :class="{ 'slide-right': activeTab === 0 }">
                                            <p class="fw-normal">
                                                Egestas fringilla aliquam leo, habitasse arcu varius
                                                lorem elit. Neque pellentesque donec et tellus ac varius
                                                tortor, bibendum. Nulla felis ac turpis at amet. Purus
                                                malesuada placerat arcu at enim elit in accumsan.
                                            </p>
                                            <a href="#"
                                                class="d-inline-flex justify-content-center align-items-center gap-2">
                                                {{ $t('LearningBanner.viewDemoLink') }}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                    viewBox="0 0 24 25" fill="none">
                                                    <path
                                                        d="M15.586 11.157L11.636 7.20704C11.4538 7.01844 11.353 6.76584 11.3553 6.50364C11.3576 6.24144 11.4628 5.99063 11.6482 5.80522C11.8336 5.61981 12.0844 5.51465 12.3466 5.51237C12.6088 5.51009 12.8614 5.61088 13.05 5.79304L18.707 11.45C18.8002 11.5427 18.8741 11.6529 18.9246 11.7742C18.9751 11.8955 19.001 12.0256 19.001 12.157C19.001 12.2884 18.9751 12.4186 18.9246 12.5399C18.8741 12.6612 18.8002 12.7714 18.707 12.864L13.05 18.521C12.9578 18.6166 12.8474 18.6927 12.7254 18.7451C12.6034 18.7976 12.4722 18.8251 12.3394 18.8263C12.2066 18.8274 12.0749 18.8021 11.952 18.7519C11.8291 18.7016 11.7175 18.6273 11.6236 18.5334C11.5297 18.4395 11.4555 18.3279 11.4052 18.205C11.3549 18.0821 11.3296 17.9504 11.3307 17.8176C11.3319 17.6849 11.3595 17.5536 11.4119 17.4316C11.4643 17.3096 11.5405 17.1993 11.636 17.107L15.586 13.157H6C5.73478 13.157 5.48043 13.0517 5.29289 12.8641C5.10536 12.6766 5 12.4223 5 12.157C5 11.8918 5.10536 11.6375 5.29289 11.4499C5.48043 11.2624 5.73478 11.157 6 11.157H15.586Z"
                                                        fill="#00B79D" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div v-if="activeTab === 1" :class="{ 'slide-right': activeTab === 1 }">
                                            <p class="fw-normal">
                                                Egestas fringilla aliquam leo, habitasse arcu varius
                                                lorem elit. Neque pellentesque donec et tellus ac varius
                                                tortor, bibendum. Nulla felis ac turpis at amet. Purus
                                                malesuada placerat arcu at enim elit in accumsan.
                                            </p>
                                            <a href="#"
                                                class="d-inline-flex justify-content-center align-items-center gap-2">
                                                {{ $t('LearningBanner.viewDemoLink') }}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                    viewBox="0 0 24 25" fill="none">
                                                    <path
                                                        d="M15.586 11.157L11.636 7.20704C11.4538 7.01844 11.353 6.76584 11.3553 6.50364C11.3576 6.24144 11.4628 5.99063 11.6482 5.80522C11.8336 5.61981 12.0844 5.51465 12.3466 5.51237C12.6088 5.51009 12.8614 5.61088 13.05 5.79304L18.707 11.45C18.8002 11.5427 18.8741 11.6529 18.9246 11.7742C18.9751 11.8955 19.001 12.0256 19.001 12.157C19.001 12.2884 18.9751 12.4186 18.9246 12.5399C18.8741 12.6612 18.8002 12.7714 18.707 12.864L13.05 18.521C12.9578 18.6166 12.8474 18.6927 12.7254 18.7451C12.6034 18.7976 12.4722 18.8251 12.3394 18.8263C12.2066 18.8274 12.0749 18.8021 11.952 18.7519C11.8291 18.7016 11.7175 18.6273 11.6236 18.5334C11.5297 18.4395 11.4555 18.3279 11.4052 18.205C11.3549 18.0821 11.3296 17.9504 11.3307 17.8176C11.3319 17.6849 11.3595 17.5536 11.4119 17.4316C11.4643 17.3096 11.5405 17.1993 11.636 17.107L15.586 13.157H6C5.73478 13.157 5.48043 13.0517 5.29289 12.8641C5.10536 12.6766 5 12.4223 5 12.157C5 11.8918 5.10536 11.6375 5.29289 11.4499C5.48043 11.2624 5.73478 11.157 6 11.157H15.586Z"
                                                        fill="#00B79D" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div v-if="activeTab === 2" :class="{ 'slide-right': activeTab === 2 }">
                                            <p class="fw-normal">
                                                Egestas fringilla aliquam leo, habitasse arcu varius
                                                lorem elit. Neque pellentesque donec et tellus ac varius
                                                tortor, bibendum. Nulla felis ac turpis at amet. Purus
                                                malesuada placerat arcu at enim elit in accumsan.
                                            </p>
                                            <a href="#"
                                                class="d-inline-flex justify-content-center align-items-center gap-2">
                                                {{ $t('LearningBanner.viewDemoLink') }}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                    viewBox="0 0 24 25" fill="none">
                                                    <path
                                                        d="M15.586 11.157L11.636 7.20704C11.4538 7.01844 11.353 6.76584 11.3553 6.50364C11.3576 6.24144 11.4628 5.99063 11.6482 5.80522C11.8336 5.61981 12.0844 5.51465 12.3466 5.51237C12.6088 5.51009 12.8614 5.61088 13.05 5.79304L18.707 11.45C18.8002 11.5427 18.8741 11.6529 18.9246 11.7742C18.9751 11.8955 19.001 12.0256 19.001 12.157C19.001 12.2884 18.9751 12.4186 18.9246 12.5399C18.8741 12.6612 18.8002 12.7714 18.707 12.864L13.05 18.521C12.9578 18.6166 12.8474 18.6927 12.7254 18.7451C12.6034 18.7976 12.4722 18.8251 12.3394 18.8263C12.2066 18.8274 12.0749 18.8021 11.952 18.7519C11.8291 18.7016 11.7175 18.6273 11.6236 18.5334C11.5297 18.4395 11.4555 18.3279 11.4052 18.205C11.3549 18.0821 11.3296 17.9504 11.3307 17.8176C11.3319 17.6849 11.3595 17.5536 11.4119 17.4316C11.4643 17.3096 11.5405 17.1993 11.636 17.107L15.586 13.157H6C5.73478 13.157 5.48043 13.0517 5.29289 12.8641C5.10536 12.6766 5 12.4223 5 12.157C5 11.8918 5.10536 11.6375 5.29289 11.4499C5.48043 11.2624 5.73478 11.157 6 11.157H15.586Z"
                                                        fill="#00B79D" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!---------------------->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----<div class="default-shape">
            <div class="shape-1">
                <img src="../../../assets/images/shapes/shape-4.png" alt="image">
            </div>
            <div class="shape-2 rotateme">
                <img src="../../../assets/images/shapes/shape-5.svg" alt="image">
            </div>
            <div class="shape-3">
                <img src="../../../assets/images/shapes/shape-6.svg" alt="image">
            </div>
            <div class="shape-4">
                <img src="../../../assets/images/shapes/shape-7.png" alt="image">
            </div>
            <div class="shape-5">
                <img src="../../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>-->
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeTab: 0,
        };
    },
    computed: {
        tabs() {
            return [
                { name: this.$t("Learningdashboard.admin") },
                { name: this.$t("Learningdashboard.teacher") },
                { name: this.$t("Learningdashboard.student") }
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
* {
    &:lang(en) {
        font-family: "Poppins", sans-serif;
    }
    &:lang(ar) {
        font-family: "Tajawal", sans-serif;
    }
}
.tabs-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #cbd5e1;
    overflow: hidden;
}
.tab-button {
    cursor: pointer;
    border: 1px solid #cbd5e1;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 48px;
    padding: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    color: #0f172a;
    font-size: 16px;
    font-weight: 500;
    line-height: 110%;
}
.tab-button.active {
    background: #f1f5f9;
}
.tabs-content {
    padding: 24px 0;
}
.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
svg {
    &:lang(ar) {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}
@keyframes slide-right {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}
/*******main banner*****************/
.abstract-banner {
    position: absolute;
    top: 20%;
    z-index: -2;
    &:lang(en) {
        right: -60px;
    }
    &:lang(ar) {
        left: -60px;
    }
}
.main-banner-content {
    &:lang(en) {
        padding-left: 20px;
    }
    &:lang(ar) {
        padding-right: 20px;
    }
    h2 {
        color: #0f172a;
        font-size: 56px;
        font-weight: 800;
        line-height: 110%;
        margin-bottom: 24px;
    }
    p {
        color: #475569;
        font-size: 18px;
        line-height: 160%;
    }
    a {
        color: #00b79d;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
}
.default-btn {
    padding: 12px 20px;
}
/*************************/
@media screen and (max-width: 992px) {
    .main-banner-content {
        h2 {
            font-size: 35px;
        }
        &:lang(en) {
            padding-left: 10px;
        }
        &:lang(ar) {
            padding-right: 10px;
        }
    }
    .img-align {
        text-align: center;
    }
    .main-banner-area-three {
        padding-top: 40px;
    }
}
@media screen and (min-width:992px) and (max-width: 1200px) {
    .main-banner-content {
        h2 {
            font-size: 48px;
        }
    }
}
</style>
