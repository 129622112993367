<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner :Banner="localizedBanner" />
        <SectionOne :features="localizedFeatures" />
        <RelatedArticles />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Features/Banner.vue';
import SectionOne from '../Projects/Features/SectionOne.vue';
import RelatedArticles from '../Projects/Features/RelatedArticles.vue';
import Footer from '../Layout/Footer'
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex';
import { useHead } from '@vueuse/head'

export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        SectionOne,
        RelatedArticles,
        OurClients,
        Footer,
    },
    data() {
        return {
            BannerContent: {
                titleKey: 'toDoBanner.title',
                descriptionKey: 'toDoBanner.description',
                buttonLinkKey: '',
                buttonTextKey: 'toDoBanner.buttonText',
                image: require('../../assets/images/todo-list-1.png'),
                imageAltKey: "toDoBanner.imageAlt",
                imageWidth: 694,
                imageHeight: 477
            },
            Features: [
                { image: require('../../assets/images/todo-list-2.png'), titleKey: 'toDoList[0].title', descriptionKey: 'toDoList[0].description', imageWidth: 656, imageHeight: 442, reverseOrder: true },
                { image: require('../../assets/images/todo-list-3.png'), titleKey: 'toDoList[1].title', descriptionKey: 'toDoList[1].description', imageWidth: 647, imageHeight: 435 },
                { image: require('../../assets/images/todo-list-4.png'), titleKey: 'toDoList[2].title', descriptionKey: 'toDoList[2].description', imageWidth: 632, imageHeight: 470, reverseOrder: true }
            ],

        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
        localizedFeatures() {
            return this.Features.map(feature => ({
                ...feature,
                title: this.$t(feature.titleKey),
                description: this.$t(feature.descriptionKey)
            }));
        },
        localizedBanner() {
            // Ensure BannerContent is defined before accessing its properties
            return {
                title: this.$t(this.BannerContent.titleKey),
                description: this.$t(this.BannerContent.descriptionKey),
                buttonLink: this.BannerContent.buttonLinkKey, // If buttonLinkKey is static, leave it as is
                buttonText: this.$t(this.BannerContent.buttonTextKey),
                image: this.BannerContent.image,
                imageAlt: this.$t(this.BannerContent.imageAltKey),
                imageWidth: this.BannerContent.imageWidth,
                imageHeight: this.BannerContent.imageHeight
            }; // Return an empty object if BannerContent is undefined
        }
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'إدارة قائمة المهام | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'To-Do List Management | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'قم بتنظيم يومك وزيادة إنتاجيتك مع أدوات إدارة قوائم المهام من شركة مسك. صمم قوائمك بسهولة، وحدد أولوياتك، وتابع تقدمك لتحقيق أهدافك اليومية.'
                : 'Organize your day and boost productivity with Misk IT Solutions’ to-do list management tools. Create tasks, set priorities, and track progress to accomplish your daily goals efficiently.';

            const metaKeywords = isArabic
                ? 'قائمة المهام, إدارة الوقت, تنظيم المهام, إنتاجية, أدوات تقنية, تتبع المهام, التخطيط اليومي'
                : 'to-do list, task management, time management, productivity, tech tools, task tracking, daily planning';

            const metaImage = isArabic
                ? 'https://example.com/todo-list-ar.jpg'
                : 'https://example.com/todo-list-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/todo-list'
                : 'https://misk.com/todo-list';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "قائمة المهام" : "To-Do List"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}

</script>