import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import  store  from './Store';
import {createHead} from '@vueuse/head'

import EN from './Locale/en.json'
import AR from './Locale/ar.json'

import { createI18n } from 'vue-i18n'

// Get stored language and direction, or fall back to defaults
const storedLanguage = localStorage.getItem('language') || 'ar';
const storedDirection = localStorage.getItem('direction') || 'rtl';

const initialLocale = storedLanguage || 'en';
const i18n = createI18n({
    locale: initialLocale,
    globalInjection: true,
    messages: {
        en: EN,
        ar: AR,
    }
});
// Set document direction based on the stored direction
document.body.dir = storedDirection;

// Save the default language and direction if they are not in localStorage
if (!localStorage.getItem('language')) {
  localStorage.setItem('language', 'ar');
}
if (!localStorage.getItem('direction')) {
  localStorage.setItem('direction', 'rtl');
}

const head = createHead()

import "./assets/custom.scss";

createApp(App).use(i18n).use(head).use(store).use(router).mount("#app");