<template>
  <section class=" py-35">
    <div class="solutions-tabs mx-auto">
      <div class="container">
        <div class="tab-buttons mx-auto d-flex justify-content-evenly align-items-center gap-lg-5">
          <div v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }"
            @click="currentTab = index" class="d-flex flex-column justify-content-center align-items-center gap-2">
            <div v-html="tab.svgLink">
            </div>
            <p>
              {{ $t(tab.name) }}
            </p>
          </div>
        </div>
      </div>
      <transition name="swap-up">
        <div v-if="tabs[currentTab]" class="tab-content" :key="currentTab">
          <component :is="tabs[currentTab].component"></component>
        </div>
      </transition>
    </div>
  </section>
</template>
<script>
import CyberTab from './CyberTab.vue';
import AwsTab from './AwsTab.vue';
import BackupTab from './BackupTab.vue';
import EmailTab from './EmailTab.vue';
export default {
  data() {
    return {
      currentTab: 0,
      tabs: [
        {
          name: 'tabName1.tab1',
          svgLink: `
                  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 40 40" fill="none">
                    <g clip-path="url(#clip0_497_58933)">
                      <path d="M37.0507 7.80268C36.8996 7.63015 36.7145 7.49066 36.5071 7.39294C36.2996 7.29522 36.0742 7.24136 35.845 7.23475C32.2838 7.14164 27.7776 3.33371 24.7983 1.8394C22.9579 0.919229 21.7429 0.312504 20.8305 0.152677C20.6452 0.125681 20.457 0.126204 20.2719 0.154229C19.3595 0.314056 18.1445 0.92078 16.3057 1.84095C13.3264 3.33371 8.82015 7.14164 5.25894 7.23475C5.02955 7.24171 4.80403 7.2957 4.59637 7.3934C4.3887 7.49109 4.20331 7.6304 4.0517 7.80268C3.73743 8.15823 3.57529 8.62293 3.60015 9.09682C4.36515 24.6497 9.94359 34.2751 19.7319 39.8691C19.9864 40.0134 20.2688 40.0878 20.5496 40.0878C20.8305 40.0878 21.1129 40.0134 21.3689 39.8691C31.1572 34.2751 36.7341 24.6497 37.5007 9.09682C37.527 8.62301 37.3652 8.15795 37.0507 7.80268ZM29.2672 13.9196L21.0074 26.1084C20.711 26.546 20.2455 26.8361 19.7846 26.8361C19.3222 26.8361 18.8086 26.5832 18.4843 26.2589L12.6622 20.4353C12.4721 20.2445 12.3654 19.9861 12.3654 19.7168C12.3654 19.4475 12.4721 19.1892 12.6622 18.9984L14.1007 17.5568C14.2919 17.3675 14.55 17.2613 14.8191 17.2613C15.0882 17.2613 15.3464 17.3675 15.5376 17.5568L19.3238 21.343L25.9015 11.6339C26.0537 11.4117 26.2876 11.2588 26.5522 11.2085C26.8168 11.1582 27.0905 11.2146 27.3136 11.3654L28.9972 12.5075C29.2196 12.6595 29.3729 12.8933 29.4235 13.1578C29.4741 13.4224 29.4179 13.6963 29.2672 13.9196Z" fill="#2A6A84"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_497_58933">
                        <rect width="40.3448" height="40.3448" fill="white" transform="translate(0.378906)"/>
                      </clipPath>
                    </defs>
                  </svg>`,
          component: 'CyberTab'
        },
        {
          name: 'tabName1.tab2',
          svgLink: `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                    <path d="M10.2933 31.0346C7.93982 31.0346 5.92931 30.22 4.26172 28.5907C2.5931 26.9613 1.75879 24.97 1.75879 22.6165C1.75879 20.5993 2.36655 18.8019 3.58206 17.2243C4.79758 15.6467 6.3881 14.6381 8.35362 14.1984C9.00017 11.8191 10.2933 9.89238 12.2329 8.41824C14.1726 6.9441 16.3709 6.20703 18.8278 6.20703C21.8536 6.20703 24.4202 7.26065 26.5274 9.36789C28.6357 11.4762 29.6898 14.0432 29.6898 17.0691C31.4743 17.276 32.9552 18.0451 34.1324 19.3765C35.3086 20.7089 35.8967 22.2674 35.8967 24.0519C35.8967 25.9915 35.2181 27.6405 33.8609 28.9988C32.5026 30.356 30.8536 31.0346 28.914 31.0346H10.2933Z" fill="#2A6A84"/>
                  </svg>`,
          component: 'AwsTab'
        },
        {
          name: 'tabName1.tab3',
          svgLink: `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                    <path d="M10.2933 31.0346C7.93982 31.0346 5.92931 30.22 4.26172 28.5907C2.5931 26.9613 1.75879 24.97 1.75879 22.6165C1.75879 20.5993 2.36655 18.8019 3.58206 17.2243C4.79758 15.6467 6.3881 14.6381 8.35362 14.1984C9.00017 11.8191 10.2933 9.89238 12.2329 8.41824C14.1726 6.9441 16.3709 6.20703 18.8278 6.20703C21.8536 6.20703 24.4202 7.26065 26.5274 9.36789C28.6357 11.4762 29.6898 14.0432 29.6898 17.0691C31.4743 17.276 32.9552 18.0451 34.1324 19.3765C35.3086 20.7089 35.8967 22.2674 35.8967 24.0519C35.8967 25.9915 35.2181 27.6405 33.8609 28.9988C32.5026 30.356 30.8536 31.0346 28.914 31.0346H10.2933Z" fill="#2A6A84"/>
                  </svg>`,
          component: 'BackupTab'
        },
        {
          name: 'tabName1.tab4',
          svgLink: `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 37 37" fill="none">
                    <path d="M6.16634 30.8346C5.31842 30.8346 4.59281 30.533 3.98951 29.9297C3.38517 29.3253 3.08301 28.5992 3.08301 27.7513V9.2513C3.08301 8.40339 3.38517 7.67777 3.98951 7.07447C4.59281 6.47014 5.31842 6.16797 6.16634 6.16797H30.833C31.6809 6.16797 32.4071 6.47014 33.0114 7.07447C33.6147 7.67777 33.9163 8.40339 33.9163 9.2513V27.7513C33.9163 28.5992 33.6147 29.3253 33.0114 29.9297C32.4071 30.533 31.6809 30.8346 30.833 30.8346H6.16634ZM18.4997 20.043L30.833 12.3346V9.2513L18.4997 16.9596L6.16634 9.2513V12.3346L18.4997 20.043Z" fill="#2A6A84"/>
                  </svg>`,
          component: 'EmailTab'
        }
      ]
    };
  },
  components: {
    CyberTab,
    EmailTab,
    AwsTab,
    BackupTab
  }
};
</script>
<style lang="scss" scoped>
/* Transition styles */
.swap-up-enter-active,
.swap-up-leave-active {
  transition: all 0.5s ease-in-out;
}

.swap-up-enter {
  opacity: 0;
  transform: translateY(30px);
}

.swap-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>