<template>
    <div>
        <div class="container">
            <Section class="digital-overview pt-35 pb-35 ">
            <div class="row ">
                <div class="col-lg-6 hover-effect order-2 order-lg-1 mt-3 mt-lg-0">
                    <header class="pt-2">
                        <h2>{{ $t('DigitalMarketing.track.trackH2') }}</h2>
                    </header>
                    <p>
                        {{ $t('DigitalMarketing.track.trackP1') }}
                    </p>
                    <p>
                        {{ $t('DigitalMarketing.track.trackP2') }} 
                    </p>
                </div>
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 d-flex justify-content-center align-items-center order-1 order-lg-2">
                    <img src="../../assets/images/Images/SEO-Optimization-Image.webp" alt="overview image" height="527" width="285" class="img-fluid rounded-3"/>
                </div>
            </div>
         </Section>
        </div> 
    </div>
</template>