<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner :Banner="localizedBanner" />
        <SectionOne :features="localizedFeatures" />
        <RelatedArticles />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Features/Banner.vue';
import SectionOne from '../Projects/Features/SectionOne.vue';
import RelatedArticles from '../Projects/Features/RelatedArticles.vue';
import Footer from '../Layout/Footer'
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex';
import { useHead } from '@vueuse/head'

export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        SectionOne,
        RelatedArticles,
        OurClients,
        Footer,
    },
    data() {
        return {
            BannerContent: {
                titleKey: 'trackingBanner.title',
                descriptionKey: 'trackingBanner.description',
                buttonLinkKey: '',
                buttonTextKey: 'trackingBanner.buttonText',
                image: require('../../assets/images/timing-1.png'),
                imageAltKey: "trackingBanner.imageAlt",
                imageWidth: 728,
                imageHeight: 513
            },
            timeTracking: [
                { image: require('../../assets/images/timing-2.png'), titleKey: 'timeTracking[0].title', descriptionKey: 'timeTracking[0].description', imageWidth: 313, imageHeight: 482, reverseOrder: true },
                { image: require('../../assets/images/timing-3.png'), titleKey: 'timeTracking[1].title', descriptionKey: 'timeTracking[1].description', imageWidth: 662, imageHeight: 457 },
                { image: require('../../assets/images/timing-4.png'), titleKey: 'timeTracking[2].title', descriptionKey: 'timeTracking[2].description', imageWidth: 632, imageHeight: 451, reverseOrder: true }
            ],

        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
        localizedFeatures() {
            return this.timeTracking.map(feature => ({
                ...feature,
                title: this.$t(feature.titleKey),
                description: this.$t(feature.descriptionKey)
            }));
        },
        localizedBanner() {
            // Ensure BannerContent is defined before accessing its properties
            return {
                title: this.$t(this.BannerContent.titleKey),
                description: this.$t(this.BannerContent.descriptionKey),
                buttonLink: this.BannerContent.buttonLinkKey, // If buttonLinkKey is static, leave it as is
                buttonText: this.$t(this.BannerContent.buttonTextKey),
                image: this.BannerContent.image,
                imageAlt: this.$t(this.BannerContent.imageAltKey),
                imageWidth: this.BannerContent.imageWidth,
                imageHeight: this.BannerContent.imageHeight
            }; // Return an empty object if BannerContent is undefined
        }
    },

    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'إدارة وتتبع الوقت | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Time Tracking Solutions | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف حلول تتبع الوقت من شركة مسك لمراقبة إنتاجية الفريق وتحسين العمليات اليومية. أدوات مبتكرة لتتبع الساعات، وإدارة المشاريع، وتحليل الأداء بكفاءة.'
                : 'Discover Misk IT Solutions’ time tracking tools to monitor team productivity and streamline daily operations. Innovative solutions for tracking hours, managing projects, and analyzing performance efficiently.';

            const metaKeywords = isArabic
                ? 'تتبع الوقت, إدارة الوقت, أدوات الإنتاجية, تحسين العمليات, تحليل الأداء, إدارة المشاريع, حلول تقنية'
                : 'time tracking, time management, productivity tools, process optimization, performance analysis, project management, IT solutions';

            const metaImage = isArabic
                ? 'https://example.com/time-tracking-ar.jpg'
                : 'https://example.com/time-tracking-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/time-tracking'
                : 'https://misk.com/time-tracking';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "تتبع الوقت" : "Time Tracking"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}

</script>