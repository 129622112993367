<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <servers-header></servers-header>
        <servers-difference></servers-difference>
        <server-pricing></server-pricing>
        <Specification />
        <server-features></server-features>
        <private-servers-features></private-servers-features>
        <Footer />
    </div>
</template>
<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import ServersHeader from '../PrivateServers/ServersHeader'
import ServersDifference from '../PrivateServers/ServersDifference'
import ServerPricing from '../PrivateServers/ServerPricing'
import Specification from '../PrivateServers/Specification'
import ServerFeatures from '../PrivateServers/ServerFeatures'
import Footer from '../Layout/Footer'
import PrivateServersFeatures from '../PrivateServers/PrivateServersFeatures'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex'
export default {
    name: 'PrivateServers',
    components: {
        NavbarStyleTwo,
        ServersHeader,
        ServersDifference,
        ServerPricing,
        Specification,
        ServerFeatures,
        PrivateServersFeatures,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';
            const metaTitle = isArabic
                ? 'الخوادم الخاصة | خدمات الخوادم الخاصة من مسك لحلول تكنولوجيا المعلومات'
                : 'Private Servers | Misk IT Solutions Private Server Services';
            const metaDescription = isArabic
                ? 'اكتشف حلول الخوادم الخاصة التي توفرها شركة مسك لتلبية احتياجات عملك. نحن نقدم خوادم خاصة آمنة وقوية مع إمكانية التخصيص التام والأداء العالي.'
                : 'Discover the private server solutions offered by Misk IT Solutions, tailored to meet your business needs. We provide secure, powerful private servers with full customization and high performance.';
            const metaKeywords = isArabic
                ? 'خوادم خاصة, خوادم مخصصة, حلول الخوادم الخاصة, استضافة الخوادم, خوادم آمنة, مسك لحلول تكنولوجيا المعلومات'
                : 'private servers, dedicated servers, private server solutions, server hosting, secure servers, Misk IT Solutions';
            const metaImage = isArabic
                ? 'https://example.com/private-servers-image-ar.jpg'
                : 'https://example.com/private-servers-image-en.jpg';
            const metaUrl = isArabic
                ? 'https://misk.com/ar/private-servers'
                : 'https://misk.com/private-servers';
            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },
                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },
                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>