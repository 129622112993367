<template>
    <div>
        <Navbar />
        <vps-header></vps-header>
        <vps-difference></vps-difference>
        <vps-pricing></vps-pricing>
        <vps-features></vps-features>
        <Footer />
    </div>
</template>
<script>
import Navbar from '../Layout/NavbarStyleTwo'
import VpsHeader from '../Vps Servers/VpsHeader'
import VpsDifference from '../Vps Servers/VpsDifference'
import VpsFeatures from '../Vps Servers/VpsFeatures'
import VpsPricing from '../Vps Servers/VpsPricing'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex';
export default {
    name: 'SignUpPage',
    components: {
        Navbar,
        VpsHeader,
        VpsDifference,
        VpsFeatures,
        VpsPricing,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';
            const metaTitle = isArabic
                ? 'استضافة الخوادم الافتراضية (VPS) | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Virtual Private Server (VPS) Hosting | Misk IT Solutions';
            const metaDescription = isArabic
                ? 'استمتع بأداء عالٍ وموثوقية مع خدمات استضافة الخوادم الافتراضية من شركة مسك. حلول مرنة وآمنة تناسب احتياجات عملك وتعزز وجودك الرقمي.'
                : 'Experience high performance and reliability with Misk IT Solutions’ VPS hosting services. Flexible, secure solutions designed to meet your business needs and enhance your digital presence.';
            const metaKeywords = isArabic
                ? 'استضافة الخوادم الافتراضية, VPS, حلول الاستضافة, خوادم مخصصة, الأداء العالي, الأمان, خوادم سريعة, شركة مسك'
                : 'VPS hosting, virtual private server, hosting solutions, dedicated servers, high performance, secure servers, fast hosting, Misk IT Solutions';
            const metaImage = isArabic
                ? 'https://example.com/vps-hosting-ar.jpg'
                : 'https://example.com/vps-hosting-en.jpg';
            const metaUrl = isArabic
                ? 'https://misk.com/ar/vps-hosting'
                : 'https://misk.com/vps-hosting';
            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },
                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },
                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "استضافة الخوادم الافتراضية" : "VPS Hosting"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>