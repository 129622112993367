<template>
    <div class="services-faq-section pt-35 pb-70 mt-5">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title my-2 my-lg-0  pe-lg-5">
                        <h2>{{ $t('brandIdentity.faq.faqH2') }}</h2>
                        <p>
                            {{ $t('brandIdentity.faq.faqP') }}
                        </p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp"  alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('brandIdentity.faq.faqQestion1Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('brandIdentity.faq.faqQuestion1P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('brandIdentity.faq.faqQestion2Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('brandIdentity.faq.faqQuestion2P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('brandIdentity.faq.faqQestion3Heading') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('brandIdentity.faq.faqQuestion3P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('brandIdentity.faq.faqQestion4Heading') }}

                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        {{ $t('brandIdentity.faq.faqQuestion4P') }}
                                    </p>
                                </template>
                            </accordion-item>
                            <!----<accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Venenatis duis proin ultricies consectetur?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </template>
                            </accordion-item>-->
                        </accordion>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>
<style lang="scss" scoped>
    .services-section-title{
        &:lang(en){text-align: left;}
        &:lang(ar){text-align: right;}
    }

    @media screen and (max-width:992px) {
        .services-section-title{
        &:lang(en),&:lang(ar){text-align: center;}
        }
    }
</style>