<template>
    <section class="feedback ">
        <div class="container feedback-container mt-5">
            <div class="row">
                <div class="col-lg-8 order-2 order-lg-1">
                    <h4 class="hover-effect-2">{{ $t('feedback_section.title') }}</h4>
                    <p class="hover-effect-2">
                        {{ $t('feedback_section.description') }}
                    </p>
                    <ul>
                        <li class="d-inline-flex align-items-center gap-2 hover-effect-2">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M8 13L12.228 16.382C12.4336 16.5462 12.6957 16.6228 12.9574 16.5951C13.2191 16.5674 13.4593 16.4377 13.626 16.234L22 6"
                                        stroke="#00B79D" stroke-width="3" stroke-linecap="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.5654 12.367L15.4654 6.37896C15.5662 6.25563 15.6138 6.09732 15.5979 5.93886C15.582 5.78039 15.5037 5.63474 15.3804 5.53396C15.2571 5.43318 15.0988 5.38551 14.9403 5.40145C14.7818 5.4174 14.6362 5.49563 14.5354 5.61896L9.64439 11.599L10.5654 12.367ZM8.03239 15.463L7.11039 14.694L6.14339 15.877C6.0781 15.957 5.98438 16.0086 5.88187 16.0211C5.77936 16.0336 5.67599 16.0059 5.59339 15.944L2.36039 13.519C2.23309 13.4235 2.07307 13.3825 1.91554 13.405C1.75801 13.4275 1.61587 13.5117 1.52039 13.639C1.42492 13.7663 1.38392 13.9263 1.40642 14.0838C1.42893 14.2413 1.51309 14.3835 1.64039 14.479L4.87339 16.904C5.20351 17.1514 5.61647 17.2618 6.02604 17.2121C6.4356 17.1623 6.81012 16.9563 7.07139 16.637L8.03239 15.463Z"
                                        fill="#00B79D" fill-opacity="0.25" />
                                </svg>
                            </span>
                            <span>
                                {{ $t('feedback_section.list_item.text') }}
                            </span>
                        </li>
                    </ul>
                    <button class="book-btn hover-effect-2">
                        {{ $t('feedback_section.button.book_session') }}
                    </button>
                </div>
                <!---------->
                <div class="col-lg-4 order-1 order-lg-2">
                    <div class="img-block">
                        <img src="../../assets/images/user-feedback.svg" alt="user feedback" width="334.497" class="top-animate"
                            height="306">
                    </div>
                </div>
                <!------------>
            </div>
        </div>
        <div class="container" style="margin-bottom: 70px;">
            <!---------New Row---------------------->
            <div class="row">
                <div class="col-lg-8 col-md-8 mb-4 mb-md-1 create-project">
                    <h4 class="organize hover-effect-2">
                        {{ $t('feedback_section.create_project_title') }}
                    </h4>
                </div>
                <div class="col-lg-4 col-md-4 demo-btn-container">
                    <button class="demo-btn hover-effect-2">
                        {{ $t('feedback_section.button.try_demo') }}
                    </button>
                </div>
            </div>
            <!------------------------------->
            <div class="row">
                <div class="pt-4">
                    <img src="../../assets/images/Frame.png" width="1363" height="804" alt="screenshot" >
                </div>
            </div>
            <!----------------------->
        </div>
    </section>
</template>
<style lang="scss" scoped>
.feedback-container {
    padding: 60px 50px;
    border-radius: 25px;
    background: #ECFAFF;
    margin-bottom: 70px;
    h4,
    p {
        color: #263238;
        line-height: 160%;
    }
    h4 {
        font-size: 30px;
        font-weight: 700;
    }
    p {
        font-size: 25px;
        font-weight: 400;
        margin-top: 23px;
    }
}
.img-block {
    text-align: center;
}
button {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 45px;
    text-transform: capitalize;
    border-radius: 20px;
    transition: 0.2s;
}
h4.organize {
    font-size: 35px;
}
.book-btn {
    margin-top: 37px;
    padding: 7px 40px;
    background: #0E4E66;
    border: 1px solid #0E4E66;
    &:hover {
        background: transparent;
        color: #0E4E66;
    }
}
.demo-btn-container {
    &:lang(ar) {
        text-align: left;
    }
    &:lang(en) {
        text-align: right;
    }
}
.create-project {
    &:lang(ar) {
        text-align: right;
    }
    &:lang(en) {
        text-align: left;
    }
}
.demo-btn {
    padding: 7px 40px;
    background: #00B79D;
    border: 1px solid #00B79D;
    ;
    &:hover {
        background: transparent;
        color: #00B79D;
        ;
    }
}
ul li {
    margin-top: 34px;
}
@media screen and (max-width: 768px) {
    .demo-btn-container {
        &:lang(ar),
        &:lang(en) {
            text-align: center;
        }
    }
    .create-project {
        &:lang(ar),
        &:lang(en) {
            text-align: center;
        }
    }
}
@media screen and (max-width:1200px) {
    .feedback-container h4,
    h4.organize {
        font-size: 25px;
    }
    .feedback-container p {
        font-size: 20px;
    }
    .book-btn {
        padding: 7px 25px;
    }
    .book-btn,
    .demo-btn {
        font-size: 14px;
    }
}
</style>