<!-- src/components/RelatedArticles.vue -->
<template>
    <section class="container articles mt-5">
      <header>
        <h3 class="mb-4 text-center py-4">{{ $t('articlesTitle') }}</h3>
      </header>
      <div class="row">
        <ArticleCard
          v-for="(article, index) in localizedArticles"
          :key="index"
          :title="article.title"
          :description="article.description"
          :categories="article.categories"
          :publishDate="article.publishDate"
          :link="article.link"
        />
      </div>
    </section>
  </template>
  
  <script>
  import ArticleCard from './ArticleCard.vue';
  
  export default {
    name: 'RelatedArticles',
    components: {
      ArticleCard
    },
    data() {
      return {
        articles: [
          {
            titleKey: "articles[0].title",
            descriptionKey: "articles[0].description",
            categoriesKey: ['articles[0].categories[0]', 'articles[0].categories[1]'],
            publishDateKey: 'articles[0].publishDate',
            link: '#'
          },
          {
            titleKey: "articles[1].title",
            descriptionKey: "articles[1].description",
            categoriesKey: ['articles[1].categories[1]', 'articles[1].categories[1]'],
            publishDateKey: 'articles[1].publishDate',
            link: '#'
          },
          {
            titleKey: "articles[2].title",
            descriptionKey: "articles[2].description",
            categoriesKey: ['articles[2].categories[0]', 'articles[2].categories[1]'],
            publishDateKey: 'articles[2].publishDate',
            link: '#'
          },
        ]
      }
    },
    computed:{
        localizedArticles() {
            return this.articles.map(article => ({
                ...article,
                title: this.$t(article.titleKey),
                description: this.$t(article.descriptionKey),
                categories: article.categoriesKey.map(categoryKey => this.$t(categoryKey)),
                publishDate : this.$t(article.publishDateKey)
            }));
        },
    }
  }
  </script>
  
  <style lang="scss" scoped>
     .articles{
        margin-top: 80px;
        margin-bottom: 80px;
        h3{
            color: #081F32;
            text-align: center;
            font-size: 35px;
            line-height: 55px; 
            text-transform: capitalize;
        }
    }
  /* Add any specific styles if needed */
  </style>
  