<template>
    <div>
        <Navbar />
        <cloud-header></cloud-header>
        <Pricing />
        <CloudFeatures></CloudFeatures>
        <CloudTransfer></CloudTransfer>
        <cloud-contact></cloud-contact>
        <Faq />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import CloudHeader from '../Cloud/CloudHeader'
import Pricing from '../Cloud/CloudPricing'
import CloudFeatures from '../Cloud/CloudFeatures'
import CloudTransfer from '../Cloud/CloudTransfer'
import CloudContact from '../Cloud/CloudContact'
import Faq from '../Cloud/Faq'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex'
export default {
    name: 'CloudServers',
    components: {
        Navbar,
        CloudHeader,
        Pricing,
        CloudFeatures,
        CloudTransfer,
        CloudContact,
        Faq,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'خوادم سحابية موثوقة وقابلة للتطوير | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Scalable and Reliable Cloud Servers | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف خوادم سحابية آمنة وقابلة للتطوير توفرها شركة مسك لحلول تكنولوجيا المعلومات. نقدم استضافة موثوقة وسريعة تلبي احتياجات عملك.'
                : 'Discover secure and scalable cloud servers offered by Misk IT Solutions. We provide reliable, fast hosting tailored to your business needs.';

            const metaKeywords = isArabic
                ? 'خوادم سحابية, استضافة سحابية, حلول السحابة, خوادم آمنة, خدمات استضافة, شركة مسك, حلول تكنولوجيا المعلومات'
                : 'cloud servers, cloud hosting, scalable solutions, secure servers, hosting services, Misk IT Solutions, IT solutions';

            const metaImage = isArabic
                ? 'https://example.com/cloud-servers-image-ar.jpg'
                : 'https://example.com/cloud-servers-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/cloud-servers'
                : 'https://misk.com/cloud-servers';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "خوادم سحابية" : "Cloud Servers"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>