<template>
  <section class="pt-70 pb-70 clients-overview">
    <div class="container">
      <section class="section-title">
        <span>
          {{ $t("HomeContent.overview.span") }}
        </span>
        <h2>
          {{ $t("HomeContent.overview.h2") }}
        </h2>
        <p>
          {{ $t("HomeContent.overview.p") }}
        </p>
        <div class="bar"></div>
      </section>
    </div>
    <div class="container-fluid">
      <!---Clients Slider Start-->
      <Carousel
        v-bind="settings"
        :breakpoints="breakpoints"
        class="mt-4 pt-4"
        :autoplay="2000"
        :wrap-around="true"
        :dir="direction"
      >
        <Slide v-for="slide in 9" :key="slide">
          <div class="carousel__item gap-3 client-card">
            <div class="client-image">
              <img
                src="../../assets/images/Images/slider3.webp"
                class="card-img-top img-fluid"
                alt="client"
                width=""
                height=""
              />
              <div class="card-body">
                <h3>Eman Atif</h3>
                <span>Designer</span>
              </div>
            </div>

            <div class="info">
              <div class="position-relative info-container">
                <div class="left-quote">
                  <i class="fa-solid fa-quote-left"></i>
                </div>

                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Laborum, at. Lorem ipsum dolor sit, amet consectetur
                  adipisicing elit. A, velit!
                </p>
                <div class="right-quote">
                  <i class="fa-solid fa-quote-right"></i>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Pagination />
          <navigation class="d-none d-lg-flex">
            <template #next>
              <button class="client-overview-next client-overview-navigation" aria-label="next button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                >
                  <path
                    d="M0.644505 9.00052C0.644505 9.14219 0.668116 9.27205 0.715338 9.3901C0.76256 9.50816 0.845198 9.62621 0.963254 9.74427L7.97575 16.7568C8.16464 16.9457 8.41846 17.0342 8.73721 17.0224C9.05596 17.0106 9.30978 16.9102 9.49867 16.7214C9.73478 16.4852 9.83513 16.2314 9.79971 15.9599C9.76429 15.6884 9.65214 15.4464 9.46325 15.2339L3.22992 9.00052L9.46325 2.76719C9.65214 2.5783 9.75839 2.32448 9.782 2.00573C9.80561 1.68698 9.69936 1.43316 9.46325 1.24427C9.27436 1.00816 9.02645 0.90781 8.7195 0.943228C8.41256 0.978645 8.15284 1.0908 7.94034 1.27968L0.963254 8.25677C0.845198 8.37483 0.76256 8.49288 0.715338 8.61094C0.668116 8.72899 0.644505 8.85885 0.644505 9.00052Z"
                    fill="#00B79D"
                  />
                </svg>
              </button>
            </template>
            <template #prev>
              <button class="client-overview-prev client-overview-navigation" aria-label="previuos button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                >
                  <path
                    d="M0.644505 9.00052C0.644505 9.14219 0.668116 9.27205 0.715338 9.3901C0.76256 9.50816 0.845198 9.62621 0.963254 9.74427L7.97575 16.7568C8.16464 16.9457 8.41846 17.0342 8.73721 17.0224C9.05596 17.0106 9.30978 16.9102 9.49867 16.7214C9.73478 16.4852 9.83513 16.2314 9.79971 15.9599C9.76429 15.6884 9.65214 15.4464 9.46325 15.2339L3.22992 9.00052L9.46325 2.76719C9.65214 2.5783 9.75839 2.32448 9.782 2.00573C9.80561 1.68698 9.69936 1.43316 9.46325 1.24427C9.27436 1.00816 9.02645 0.90781 8.7195 0.943228C8.41256 0.978645 8.15284 1.0908 7.94034 1.27968L0.963254 8.25677C0.845198 8.37483 0.76256 8.49288 0.715338 8.61094C0.668116 8.72899 0.644505 8.85885 0.644505 9.00052Z"
                    fill="#00B79D"
                  />
                </svg>
              </button>
            </template>
          </navigation>
        </template>
      </Carousel>
    </div>
    <!--Clients Slider End-->
    <!----ALL reviewes button-->
    <div class="text-center mt-3">
        <router-link class="audience-btn border-1 border-white" to="/testmonial">View All Reviews</router-link>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Breakpoints",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    // carousel settings
    direction: localStorage.getItem("direction") || "rtl",

    settings: {
      itemsToShow: 1,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 3.5,
      },
    },
  }),

  mounted() {
    window.addEventListener("direction-localstorage-changed", (event) => {
      this.direction = event.detail.storage;
    });
  },
});
</script>
