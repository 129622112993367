<template>
    <div>
        <Navbar />
        <Heading />
        <ReportsTabs />
        <Technologies />
        <target-audiance />
        <AIWriterTabs />
        <ProjectInfo />
        <UserFeedback />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import Footer from '../Layout/Footer'
import Heading from '../Projects/Heading.vue';
import ReportsTabs from '../Projects/ReportsTabs.vue';
import AIWriterTabs from '../Projects/AIWriterTabs.vue';
import Technologies from '../Projects/Technologies.vue';
import TargetAudiance from '../Projects/TargetAudiance.vue';
import ProjectInfo from '../Projects/ProjectInfo.vue';
import { useHead } from '@vueuse/head'
import UserFeedback from '../Projects/UserFeedback.vue';
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex'
export default {
    name: 'ProductsPage',
    components: {
        Navbar,
        Heading,
        ReportsTabs,
        AIWriterTabs,
        Technologies,
        TargetAudiance,
        ProjectInfo,
        OurClients,
        UserFeedback,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'إدارة المشاريع | حلول إدارة المشاريع من مسك لحلول تكنولوجيا المعلومات'
                : 'Project Management | Misk IT Solutions Project Management Services';

            const metaDescription = isArabic
                ? 'اكتشف حلول إدارة المشاريع الشاملة التي تقدمها شركة مسك. من التخطيط إلى التنفيذ، نحن نساعدك في إدارة مشاريعك بكفاءة لتحقيق النجاح المستدام.'
                : 'Explore the comprehensive project management solutions offered by Misk IT Solutions. From planning to execution, we help you manage your projects efficiently to achieve sustainable success.';

            const metaKeywords = isArabic
                ? 'إدارة المشاريع, حلول إدارة المشاريع, تخطيط المشاريع, تنفيذ المشاريع, نجاح المشاريع, إدارة فرق العمل, مسك لحلول تكنولوجيا المعلومات'
                : 'project management, project management solutions, project planning, project execution, project success, team management, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/project-management-image-ar.jpg'
                : 'https://example.com/project-management-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/project-management'
                : 'https://misk.com/project-management';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>