<template>
    <div class="audience-section pt-1 pb-35">
        <div class="container">
            <div class="section-title">
                <span>Last Work</span>
                <h2>Our Work</h2>
                <p>
                    We have previously worked with many companies and clients, and we are proud of their 
                    participation in achieving the goals they seek through our implementation of
                    the projects they.
                </p>
                <div class="bar"></div>
            </div>
            <!------Start Of First section-->
            <section class="row align-items-center justify-content-center justify-content-lg-start section-align mt-4 mt-lg-1">
                <div class="col-lg-6 order-2 order-lg-1">
                    <div class="audience-content">
                        <h3>Elbalaty Website </h3>
                        <span class="web">Web Design</span> 
                        <span>Web Development</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori.
                        </p>

                        <div >
                            <router-link  to="/single-case-study" class="text-capitalize audience-btn">View case study</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-4 mt-lg-0 order-1 order-lg-2">
                    <div class="audience-image">
                        <img src="../../assets/images/Images/home-Macbook.webp" alt="image" height="340" width="602">
                    </div>
                </div>
            </section>
            <!--------End of First section------------->
            <!--------Start Of Second section------->
            <section class="row align-items-center second-section justify-content-center justify-content-lg-start  section-align">
                <div class="col-lg-6 mt-3 mb-md-3 mb-lg-0 ">
                    <div class="audience-image mx-auto mt-4 m-lg-0 ">
                        <img src="../../assets/images/Images/home-mobile.webp" alt="image" height="479" width="304">
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <div class="audience-content">
                        <h3>Elbalaty Website </h3>
                        <span class="web">Web Design</span> 
                        <span>Web Development</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori.
                        </p>

                        <div >
                            <router-link  to="/single-case-study" class="text-capitalize audience-btn">View case study</router-link>
                        </div>
                    </div>
                </div>
            </section>
            <!--------End Of Second Section--------->
            <!-----Start of Third section-------------->
            <section class="row align-items-center third-section justify-content-center justify-content-lg-start section-align">
                <div class="col-lg-6 mb-1 mb-md-3 mb-lg-0 order-2 order-lg-1">
                    <div class="audience-content">
                        <h3>Elbalaty Website </h3>
                        <span class="web">Web Design</span> 
                        <span>Web Development</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori.
                        </p>

                        <div >
                            <router-link  to="/single-case-study" class="text-capitalize audience-btn">View case study</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 order-1 order-lg-2">
                    <div class="audience-image mt-4 mt-lg-0">
                        <img src="../../assets/images/Images/home-Macbook.webp" alt="image" height="340" width="602">
                    </div>
                </div>
            </section>
            <!-----End of third section---------------->
            <!-------Button--------------------->
            <ServicesBtn></ServicesBtn>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
import ServicesBtn from './ServicesBtn';
    export default {
        name: 'Audience',
        components: {
            ServicesBtn
        }
    }
</script>

<style lang="scss" scoped>
    .section-align{
        &:lang(en){text-align: left;}
        &:lang(ar){text-align: right;}
    }

    @media screen and (max-width:992px) {
        .section-align{
        &:lang(en),&:lang(ar){text-align: center;}
        }
    }
</style>