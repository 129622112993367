<template>
    <div class="choose-section  pt-35 pb-35">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('websiteImportance.sectionTitle.title') }}</h2>
                <p>{{ $t('websiteImportance.sectionTitle.description') }}</p>
                <div class="bar"></div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="choose-content d-flex">
                        <div class="icon">
                            <i class="flaticon-shared-folder"></i>
                        </div>
                        <div>
                            <h3>{{ $t('websiteImportance.features.globalAccessibility.title') }}</h3>
                            <p>{{ $t('websiteImportance.features.globalAccessibility.description') }}</p>
                        </div>
                    </div>
                    <div class="choose-content">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>{{ $t('websiteImportance.features.credibilityAndTrust.title') }}</h3>
                        <p>{{ $t('websiteImportance.features.credibilityAndTrust.description') }}</p>
                    </div>
                    <div class="choose-content">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>{{ $t('websiteImportance.features.efficientOnlineMarketing.title') }}</h3>
                        <p>{{ $t('websiteImportance.features.efficientOnlineMarketing.description') }}</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="choose-image">
                        <img src="../../assets/images/Images/choose.webp" alt="image" width="830" height="750">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Choose'
}
</script>