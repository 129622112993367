<template>
    <div>
        <header class="vps-header">
            <div class="container">
                <section class="d-flex flex-column justify-content-center align-items-center">    
                    <div class="text-center">
                        <img src="../../assets/images/rocket-img.svg" alt="rocker-img">
                    </div>
                    <h1 class="text-center">{{ $t("vpsTitle") }}</h1>
                    <p>{{ $t("vpsDescription") }}</p>
                </section>
            </div>
        </header>
    </div>
</template>
<script>
    export default{
        name: 'VpsHeader',
        computed: {
            test () {
             return this.$store.state.counter
            }
        }
    }
</script>
