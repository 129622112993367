<template>
  <div>
    <router-view />
    <Preloader v-if="isLoading" />
    <WhatsappIcon v-show="showWhatsAppIcon" />
    <GoTop />
  </div>
</template>

<script>
import Preloader from "./components/Layout/Preloader";
import GoTop from "./components/Layout/GoTop";
import WhatsappIcon from "./components/Layout/WhatsappIcon";
import { useHead } from "@vueuse/head";

export default {
  name: "App",
  components: {
    Preloader,
    WhatsappIcon,
    GoTop,
  },
  setup() {
    useHead({
      title: "Misk - It solutions and Technology<",
      meta: [
        {
          name: "description",
          content: "Misk website",
        },
      ],
      script: [
        {
          type: "application/ld+json",
          innerHTML: `
                            {
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                "url": "http://www.your-company-site.com",
                                "contactPoint": [
                                {
                                    "@type": "ContactPoint",
                                    "telephone": "+1-401-555-1212",
                                    "contactType": "customer service"
                                }
                                ]
                            }
                            `,
        },
      ],
    });
  },
  data() {
    return {
      isLoading: true,
      showWhatsAppIcon: true,
    };
  },
  watch: {
    // Watch for changes to the language stored in local storage
    "$i18n.locale": function (newLanguage) {
      // Set the lang attribute of the html tag
      document.querySelector("html").setAttribute("lang", newLanguage);
    },
  },

  methods: {
    updateHtmlLangAttribute() {
      // Get the stored language from local storage
      const storedLanguage = localStorage.getItem("language");

      // Set the lang attribute of the html tag
      document
        .querySelector("html")
        .setAttribute("lang", storedLanguage || "en");
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
    this.updateHtmlLangAttribute();
  },
};
</script>