<template>
    <div class="more-features-section  mt-lg-1">
        <div class="container">
            <section class="section-title">
                <span>{{ $t('vision.span') }}</span>
                <h2>
                    {{ $t('vision.h2') }}
                </h2>
                <div class="bar"></div>
            </section>
            <div class="row">
                <section class="col-md-6 ">
                    <div class="more-features-content hover-effect-1">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="../../assets/images/vision-1.svg" alt="our vision icon" width="46" height="46">
                            <h3>
                                {{ $t('vision.visionh3') }}
                            </h3>
                        </div>
                        <p>
                            {{ $t('vision.visionP') }}
                        </p>
                    </div>
                </section>
                <section class=" col-md-6 d-flex">
                    <div class="more-features-content hover-effect-1" >
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="../../assets/images/mission-1.svg" alt="our vision icon" width="46" height="46">
                            <h3>
                                {{ $t('vision.missionh3') }}
                            </h3>
                        </div>
                        <p>
                            {{ $t('vision.missionP') }}
                        </p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
    }
</script>