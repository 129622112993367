<template>
    <div class="services-faq-section pt-35 pb-70 mt-5">
        <div class="container">
            <div class="row align-items-start d-flex">
                <section class="col-lg-6">
                    <header class="services-section-title my-2 my-lg-0  pe-lg-5">
                        <h2>{{ $t("faqTitle") }}</h2>
                        <p>{{ $t("faqDescription") }}</p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp" alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4 d-flex flex-column justify-content-between ">
                    <section class="services-faq-accordion  mb-5">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t("faqQuestion1") }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t("faqAnswer1") }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t("faqQuestion2") }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t("faqAnswer2") }}</p>

                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t("faqQuestion3") }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t("faqAnswer3") }}</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                    <div class="mt-auto text-center">
                        <router-link to="/contact" class="default-btn py-1"> {{ $t("askMoreButton") }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'HostingFaq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>

<style lang="scss" scoped>
.services-section-title {
    &:lang(en) {
        text-align: left;
    }

    &:lang(ar) {
        text-align: right;
    }
}

@media screen and (max-width:992px) {
    .services-section-title {

        &:lang(en),
        &:lang(ar) {
            text-align: center;
        }
    }
}
</style>