<template>
    <div>
        <div class="content">
            <navbar-style-two></navbar-style-two>
            <page-title></page-title>
            <Login />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import PageTitle from '../LogIn/PageTitle'
import Login from '../LogIn/Login'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex';

export default {
    name: 'LogInPage',
    components: {
        NavbarStyleTwo,
        Login,
        PageTitle,
        Footer,
        /* CopyRight*/
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'تسجيل الدخول إلى حسابك | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Login to Your Account | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'تسجيل الدخول إلى حسابك للوصول إلى حلول تكنولوجيا المعلومات الخاصة بشركة مسك. استمتع بالخدمات المتقدمة والمخصصة التي تلبي احتياجات عملك.'
                : "Log in to your account to access Misk IT Solutions' advanced and tailored IT services that meet your business needs.";

            const metaKeywords = isArabic
                ? 'تسجيل الدخول, حساب العميل, خدمات تكنولوجيا المعلومات, حلول تكنولوجيا المعلومات, شركة مسك'
                : 'login, client account, IT services, IT solutions, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/login-image-ar.jpg'
                : 'https://example.com/login-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/login'
                : 'https://misk.com/login';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'noindex, nofollow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>