<template>
    <div class="services-faq-section pt-35 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title  my-2 my-lg-0 pe-lg-5">
                        <h2>{{ $t('mobilefaq.header.title') }}</h2>
                        <p>{{ $t('mobilefaq.header.description') }}</p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp" alt="faq image" width="610" height="343">
                    </div>
                </section>
                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('mobilefaq.questions[0].question') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t('mobilefaq.questions[0].answer') }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('mobilefaq.questions[1].question') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t('mobilefaq.questions[1].answer') }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('mobilefaq.questions[2].question') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t('mobilefaq.questions[2].answer') }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('mobilefaq.questions[3].question') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t('mobilefaq.questions[3].answer') }}</p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        {{ $t('mobilefaq.questions[4].question') }}
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{ $t('mobilefaq.questions[4].answer') }}</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";
export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>
<style lang="scss" scoped>
    .services-section-title{
        &:lang(en){text-align: left;}
        &:lang(ar){text-align: right;}
    }

    @media screen and (max-width:992px) {
        .services-section-title{
        &:lang(en),&:lang(ar){text-align: center;}
        }
    }
</style>