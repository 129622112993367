<template>
    <div>
        <Navbar />
        <set-up-page-title></set-up-page-title>
        <set-up></set-up>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import Footer from '../Layout/Footer'
import SetUpPageTitle from '../Subscribe/SetUppageTitle'
import SetUp from '../Subscribe/SetUp'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex';

export default {
    name: 'SetUpDomain',
    components: {
        Navbar,
        SetUpPageTitle,
        Footer,
        SetUp,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'إعداد النطاق | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Set Up Domain | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'تعلم كيفية إعداد نطاقك بسهولة مع شركة مسك. نقدم لك حلولًا مرنة ومبسطة لضبط النطاق وتوجيهه إلى موقعك الإلكتروني بسرعة وكفاءة.'
                : 'Learn how to set up your domain effortlessly with Misk IT Solutions. We provide flexible and streamlined solutions to configure and point your domain to your website quickly and efficiently.';

            const metaKeywords = isArabic
                ? 'إعداد النطاق, تخصيص النطاق, ضبط النطاق, إعداد DNS, استضافة النطاق, خدمات النطاق, شراء النطاقات'
                : 'domain setup, domain configuration, DNS setup, domain pointing, domain hosting, domain services, buying domains';

            const metaImage = isArabic
                ? 'https://example.com/domain-setup-ar.jpg'
                : 'https://example.com/domain-setup-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/set-up-domain'
                : 'https://misk.com/set-up-domain';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>