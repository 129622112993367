<template>
  <section class="container">
    <div v-for="(item, index) in features" :key="index" class="row">
      <div class="col-lg-5" :class="{
        'order-lg-1': item.reverseOrder,
        'order-lg-2': !item.reverseOrder,
      }">
        <img :src="item.image" class="img-fluid hover-effect-1" :alt="item.title" width="600" height="479"
          v-if="$i18n.locale === 'en'" />
        <img :src="item.image1" class="img-fluid hover-effect-1" :alt="item.title" width="600" height="479"
          v-if="$i18n.locale === 'ar'" />
      </div>
      <div class="col-lg-7 d-flex justify-content-center flex-column gap-3 order-lg-1" style="height: inherit;">
        <div class="hover-effect" :class="!item.reverseOrder ? 'row-text-1' : 'row-text'">
          <h4 class="hover-effect-2">{{ item.title }}</h4>
          <p class="hover-effect-2">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.hover-effect-1:hover{
    transform: scale(1.04);
}
.hover-effect:hover{padding: 20px 10px;}
* {
  &:lang(en) {
    font-family: "Poppins", sans-serif;
  }
  &:lang(ar) {
    font-family: "Tajawal", sans-serif;
  }
}
.row {
  margin-top: 80px;
  margin-bottom: 80px;
}
h4,
p {
  color: var(--Blue-Gray-900, #0F172A);
}
.img-container {
  &:lang(ar) {
    padding-left: 50px;
  }
  &:lang(en) {
    padding-right: 50px;
  }
}
.img-container-1 {
  &:lang(ar) {
    padding-right: 50px;
  }
  &:lang(en) {
    padding-left: 50px;
  }
}
h4 {
  font-size: 54px;
  font-weight: 800;
  line-height: 110%;
  text-transform: capitalize;
}
p {
  font-size: 20px;
  font-weight: 400;
  line-height: 180%;
}
.row-text {
  &:lang(en) {
    padding-left: 40px;
  }
  &:lang(ar) {
    padding-right: 40px;
  }
}
.row-text-1 {
  &:lang(ar) {
    padding-left: 40px;
  }
  &:lang(en) {
    padding-right: 40px;
  }
}
@media screen and (max-width:992px) {
  h4 {
    text-align: center;
    font-size: 35px;
    margin-top: 40px;
  }
  p {
    text-align: center;
    font-size: 18px;
  }
  .row-text {
    &:lang(en) {
      padding-left: 10px;
    }
    &:lang(ar) {
      padding-right: 10px;
    }
  }
  .row-text-1 {
    &:lang(ar) {
      padding-left: 10px;
    }
    &:lang(en) {
      padding-right: 10px;
    }
  }
}
@media screen and (min-width:993px) and (max-width:1260px) {
  h4 {
    font-size: 40px;
  }
}
</style>