<template>
    <div>
        <Navbar />
        <RoleHeader></RoleHeader>
        <OverviewApp />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import RoleHeader from '../RoleOverview/RoleHeader'
import OverviewApp from '../RoleOverview/OverviewApp'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from "vuex";
export default {
    name: 'RoleOverview',
    components: {
        Navbar,
        RoleHeader,
        OverviewApp,
        Footer,
    },
    data() {
        return {
            TabChanged: false,
        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'نظرة عامة عن الدور الوظيفي | فرص العمل في شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Role Overview | Career Opportunities at Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف الفرص الوظيفية في شركة مسك وحصل على نظرة عامة عن دورك المستقبلي. نحن نبحث عن محترفين موهوبين للانضمام إلى فريقنا وتحقيق النجاح في عالم تكنولوجيا المعلومات.'
                : 'Explore career opportunities at Misk IT Solutions and get an overview of your future role. We are looking for talented professionals to join our team and thrive in the IT industry.';

            const metaKeywords = isArabic
                ? 'فرص العمل, وظائف, نظرة عامة عن الدور الوظيفي, شركة مسك, حلول تكنولوجيا المعلومات, مهنة في تكنولوجيا المعلومات, وظائف في مسك'
                : 'job opportunities, roles overview, careers, Misk IT Solutions, IT careers, job descriptions, IT roles at Misk';

            const metaImage = isArabic
                ? 'https://example.com/role-overview-ar.jpg'
                : 'https://example.com/role-overview-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/role-overview'
                : 'https://misk.com/role-overview';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        localStorage.setItem('TabChanged', this.TabChanged.toString());
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>