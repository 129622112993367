<template>
    <div>
        <section class="container computer-security-section pt-35 pb-35">
            <div class="row mt-lg-5">
                <section class="hover-effect rounded-1 col-md-4 text-center mt-3 mt-lg-0">
                    <div>
                        <img src="../../assets/images/computer-security-1.svg">
                    </div>
                    <div>
                        <h3>
                            {{ $t('cyberSecurity.computerSecurity.computerH3List1') }}
                        </h3>
                        <p>
                            {{ $t('cyberSecurity.computerSecurity.computerPList1') }}
                        </p>
                    </div>
                </section>
                <section class="hover-effect rounded-1  col-md-4 text-center mt-3 mt-lg-0">
                    <div>
                        <img src="../../assets/images/computer-security-2.svg">
                    </div>
                    <div>
                        <h3>
                            {{ $t('cyberSecurity.computerSecurity.computerH3List2') }}
                        </h3>
                        <p>
                            {{ $t('cyberSecurity.computerSecurity.computerPList2') }}
                        </p>
                    </div>
                </section>
                <section class="hover-effect rounded-1  col-md-4 text-center mt-3 mt-lg-0">
                    <div>
                        <img src="../../assets/images/computer-security-3.svg">
                    </div>
                    <div>
                        <h3>
                            {{ $t('cyberSecurity.computerSecurity.computerH3List3') }}
                        </h3>
                        <p>
                            {{ $t('cyberSecurity.computerSecurity.computerPList3') }}
                        </p>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>