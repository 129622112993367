<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner :Banner="localizedBanner" />
        <SectionOne :features="localizedFeatures" />
        <RelatedArticles />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Features/Banner.vue';
import SectionOne from '../Projects/Features/SectionOne.vue';
import RelatedArticles from '../Projects/Features/RelatedArticles.vue';
import Footer from '../Layout/Footer'
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex';
import { useHead } from '@vueuse/head'

export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        SectionOne,
        RelatedArticles,
        OurClients,
        Footer,
    },
    data() {
        return {
            BannerContent: {
                titleKey: 'automationBanner.title',
                descriptionKey: 'automationBanner.description',
                buttonLinkKey: '',
                buttonTextKey: 'automationBanner.buttonText',
                image: require('../../assets/images/automation-1.png'),
                imageAltKey: "automationBanner.imageAlt",
                imageWidth: 824,
                imageHeight: 562
            },
            Features: [
                { image: require('../../assets/images/automation-2.png'), titleKey: 'automation[0].title', descriptionKey: 'automation[0].description', imageWidth: 732, imageHeight: 500, reverseOrder: true },
                { image: require('../../assets/images/automation-3.png'), titleKey: 'automation[1].title', descriptionKey: 'automation[1].description', imageWidth: 1380, imageHeight: 935 },
                { image: require('../../assets/images/automation-4.png'), titleKey: 'automation[2].title', descriptionKey: 'automation[2].description', imageWidth: 730, imageHeight: 447, reverseOrder: true },
                { image: require('../../assets/images/automation-5.png'), titleKey: 'automation[3].title', descriptionKey: 'automation[3].description', imageWidth: 1463, imageHeight: 990 },
            ],

        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
        localizedFeatures() {
            return this.Features.map(feature => ({
                ...feature,
                title: this.$t(feature.titleKey),
                description: this.$t(feature.descriptionKey)
            }));
        },
        localizedBanner() {
            // Ensure BannerContent is defined before accessing its properties
            return {
                title: this.$t(this.BannerContent.titleKey),
                description: this.$t(this.BannerContent.descriptionKey),
                buttonLink: this.BannerContent.buttonLinkKey, // If buttonLinkKey is static, leave it as is
                buttonText: this.$t(this.BannerContent.buttonTextKey),
                image: this.BannerContent.image,
                imageAlt: this.$t(this.BannerContent.imageAltKey),
                imageWidth: this.BannerContent.imageWidth,
                imageHeight: this.BannerContent.imageHeight
            }; // Return an empty object if BannerContent is undefined
        }
    },
    methods: {
        updateHeadMeta() {
    const isArabic = this.currentLanguage === 'ar';

    const metaTitle = isArabic
        ? 'حلول الأتمتة | شركة مسك لحلول تكنولوجيا المعلومات'
        : 'Automation Solutions | Misk IT Solutions';

    const metaDescription = isArabic
        ? 'قم بتحسين عمليات أعمالك مع خدمات الأتمتة المتقدمة من شركة مسك لحلول تكنولوجيا المعلومات. من أتمتة سير العمل إلى الحلول المدعومة بالذكاء الاصطناعي، نساعد الشركات على زيادة الكفاءة وتقليل التكاليف.'
        : 'Optimize your business processes with Misk IT Solutions\' advanced automation services. From workflow automation to AI-driven solutions, we help businesses enhance efficiency and reduce costs.';

    const metaKeywords = isArabic
        ? 'حلول الأتمتة, أتمتة الأعمال, أتمتة العمليات, أتمتة الذكاء الاصطناعي, شركة مسك, تحسين العمليات, روبوتات العمليات الآلية, حلول الكفاءة'
        : 'automation solutions, workflow automation, business automation, AI automation, Misk IT Solutions, process optimization, robotic process automation, efficiency solutions';

    const metaImage = isArabic
        ? 'https://example.com/automation-image-ar.jpg'
        : 'https://example.com/automation-image-en.jpg';

    const metaUrl = isArabic
        ? 'https://misk.com/ar/automation'
        : 'https://misk.com/automation';

    useHead({
        title: metaTitle,
        meta: [
            { name: 'description', content: metaDescription },
            { name: 'keywords', content: metaKeywords },
            { name: 'robots', content: 'index, follow' },
            { name: 'language', content: this.currentLanguage },

            // Open Graph
            { property: 'og:title', content: metaTitle },
            { property: 'og:description', content: metaDescription },
            { property: 'og:image', content: metaImage },
            { property: 'og:url', content: metaUrl },
            { property: 'og:type', content: 'website' },

            // Twitter Card
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: metaTitle },
            { name: 'twitter:description', content: metaDescription },
            { name: 'twitter:image', content: metaImage }
        ],
        script: [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": metaTitle,
                    "description": metaDescription,
                    "url": metaUrl,
                    "publisher": {
                        "@type": "Organization",
                        "name": "Misk IT Solutions",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://example.com/logo.jpg"
                        }
                    },
                    "inLanguage": [isArabic ? "ar" : "en"],
                    "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": metaUrl
                    },
                    "primaryImageOfPage": {
                        "@type": "ImageObject",
                        "url": metaImage
                    },
                    "breadcrumb": {
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "item": {
                                    "@id": metaUrl,
                                    "name": isArabic ? "الرئيسية" : "Home"
                                }
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "item": {
                                    "@id": metaUrl,
                                    "name": isArabic ? "حلول الأتمتة" : "Automation Solutions"
                                }
                            }
                        ]
                    }
                })
            }
        ]
    });
}


    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}

</script>