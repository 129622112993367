<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <qa-header></qa-header>
    <qa-overview></qa-overview>
    <app-services></app-services>
    <app-process></app-process>
    <qa-work-with-us></qa-work-with-us>
    <Faq />
    <Footer />

  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import QaHeader from "../AppDevelopment/QaHeader";
import QaWorkWithUs from "../AppDevelopment/QaWorkWithUs";
import QaOverview from "../AppDevelopment/QaOverview";
/*import TestingProcess from "../AppDevelopment/TestingProcess";
import TestingSteps from "../AppDevelopment/TestingSteps";*/
import AppServices from "../AppDevelopment/AppServices.vue";
import AppProcess from "../AppDevelopment/AppProcess.vue";
import Faq from '../AppDevelopment/Faq'
import Footer from "../Layout/Footer";
import { useHead } from "@vueuse/head";
import { mapGetters } from 'vuex';
export default {
  components: {
    NavbarStyleTwo,
    QaHeader,
    QaWorkWithUs,
    QaOverview,
    /*TestingProcess,
    TestingSteps,*/
    Faq,
    AppServices,
    AppProcess,
    Footer,
  },
  computed: {
    ...mapGetters(['currentLanguage']),
  },
  methods: {
    updateHeadMeta() {
      const isArabic = this.currentLanguage === 'ar';

      const metaTitle = isArabic
        ? 'تطوير التطبيقات  | شركة مسك لحلول تكنولوجيا المعلومات'
        : 'Mobile App Development Services | Misk IT Solutions';

      const metaDescription = isArabic
        ? 'نحن نقدم حلول تطوير التطبيقات المحمولة المخصصة لمساعدتك في بناء تطبيقات مبتكرة تعمل على جميع الأجهزة المحمولة. نحن نركز على تحسين تجربة المستخدم والتفاعل عبر الأنظمة الأساسية.'
        : 'We provide custom mobile app development solutions to help you build innovative apps that work across all mobile devices. We focus on enhancing user experience and engagement across platforms.';

      const metaKeywords = isArabic
        ? 'تطوير التطبيقات المحمولة, تطبيقات موبايل مخصصة, تطوير تطبيقات للأندرويد, تطوير تطبيقات للآيفون, تصميم التطبيقات, تطبيقات الموبايل, حلول موبايل'
        : 'mobile app development, custom mobile apps, android app development, iOS app development, app design, mobile apps, mobile solutions';

      const metaImage = isArabic
        ? 'https://example.com/mobile-development-image-ar.jpg'
        : 'https://example.com/mobile-development-image-en.jpg';

      const metaUrl = isArabic
        ? 'https://misk.com/ar/mobile-development'
        : 'https://misk.com/mobile-development';

      useHead({
        title: metaTitle,
        meta: [
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: metaKeywords },
          { name: 'robots', content: 'index, follow' },
          { name: 'language', content: this.currentLanguage },

          // Open Graph
          { property: 'og:title', content: metaTitle },
          { property: 'og:description', content: metaDescription },
          { property: 'og:image', content: metaImage },
          { property: 'og:url', content: metaUrl },
          { property: 'og:type', content: 'website' },

          // Twitter Card
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: metaTitle },
          { name: 'twitter:description', content: metaDescription },
          { name: 'twitter:image', content: metaImage }
        ],
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": metaTitle,
              "description": metaDescription,
              "url": metaUrl,
              "publisher": {
                "@type": "Organization",
                "name": "Misk IT Solutions",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://example.com/logo.jpg"
                }
              },
              "inLanguage": [isArabic ? "ar" : "en"],
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": metaUrl
              },
              "primaryImageOfPage": {
                "@type": "ImageObject",
                "url": metaImage
              }
            })
          }
        ]
      });
    }
  },
  mounted() {
    this.updateHeadMeta(); // Set metadata when component mounts
  },
  watch: {
    currentLanguage: {
      handler() {
        this.updateHeadMeta(); // Update metadata when language changes
      },
      immediate: true,
    },
  },
};
</script>
