<template>
    <div class="contact-box pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-box">
                        <i class="fa-solid fa-location-dot"></i>
                        <div class="content-title">
                            <h2>{{ $t('contactInfo.officeHeader') }}</h2>  
                            <p>{{ $t('contactInfo.officeAddress') }}</p>  
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-box">
                        <i class="fa-brands fa-whatsapp"></i>
                        <div class="content-title">
                            <h2>{{ $t('contactInfo.phoneHeader') }}</h2>  
                            <!----<a href="tel:123456123">+123(456)123</a>-->
                            <a href="tel:01050931981">(+20) 01050931981</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="single-contact-box">
                        <i class="flaticon-envelope"></i>
                        <div class="content-title">
                            <h2>{{ $t('contactInfo.emailHeader') }}</h2>  
                            <a href="mailto:info@meskits.com">info@meskits.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactBox'
    }
</script>