<template>
  <div>
    <div class="container">
      <Section class="ui-overview pt-35 pb-35">
        <div class="row">
          <div class="col-lg-6">
            <header>
              <h2>
                {{ $t("brandIdentity.overview.overviewH2") }}
              </h2>
            </header>
            <p>
              {{ $t("brandIdentity.overview.overviewP") }}
            </p>
            <div class="row">
          <ul class="graphic-overview">
            <li class="hover-effect-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z"
                    fill="#00B79D"
                  />
                </svg>
              </span>
              <span>
                {{ $t("brandIdentity.overview.overviewList1") }}
              </span>
            </li>
            <li class="hover-effect-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z"
                    fill="#00B79D"
                  />
                </svg>
              </span>
              <span>
                {{ $t("brandIdentity.overview.overviewList2") }}
              </span>
            </li>
            <li class="hover-effect-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z"
                    fill="#00B79D"
                  />
                </svg>
              </span>
              <span>
                {{ $t("brandIdentity.overview.overviewList3") }}
              </span>
            </li>
            <li class="hover-effect-2">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z"
                    fill="#00B79D"
                  />
                </svg>
              </span>
              <span>
                {{ $t("brandIdentity.overview.overviewList4") }}
              </span>
            </li>
          </ul>
        </div>
          </div>
          <div
            class="col-lg-6 text-center mt-3 mt-lg-0 d-flex justify-content-center align-items-center"
          >
            <img
              src="../../assets/images/Images/ui-overview.webp"
              alt="overview image"
              height="475"
              width="382"
              class="rounded-3 w-100"
            />
          </div>
        </div>
        
      </Section>
    </div>
  </div>
</template>