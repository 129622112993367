<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <JobsHeader></JobsHeader>
        <OurCompany></OurCompany>
        <HowItworks></HowItworks>
        <Vaccancies></Vaccancies>
        <Benefits></Benefits>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import JobsHeader from '../Jobs/JobsHeader'
import HowItworks from '../Jobs/HowItworks'
import OurCompany from '../Jobs/OurCompany'
import Vaccancies from '../Jobs/Vaccancies'
import Benefits from '../Jobs/Benefits'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex';

export default {
    components: {
        NavbarStyleTwo,
        JobsHeader,
        HowItworks,
        OurCompany,
        Vaccancies,
        Benefits,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'وظائف شاغرة | انضم إلى فريق شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Job Openings | Join the Misk IT Solutions Team';

            const metaDescription = isArabic
                ? 'استكشف فرص العمل المتاحة في شركة مسك لحلول تكنولوجيا المعلومات. انضم إلى فريقنا المبدع وساهم في تحقيق النجاح مع تقنيات مبتكرة وبيئة عمل ملهمة.'
                : 'Explore career opportunities at Misk IT Solutions. Join our innovative team and contribute to success with cutting-edge technology and a dynamic work environment.';

            const metaKeywords = isArabic
                ? 'وظائف تقنية, وظائف شاغرة, فرص عمل, شركة مسك, تكنولوجيا المعلومات, وظائف في البرمجيات, وظائف الأمن السيبراني, وظائف تطوير البرمجيات'
                : 'IT jobs, job openings, career opportunities, Misk IT Solutions, technology jobs, software development jobs, cybersecurity jobs, IT careers';

            const metaImage = isArabic
                ? 'https://example.com/jobs-image-ar.jpg'
                : 'https://example.com/jobs-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/jobs'
                : 'https://misk.com/jobs';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "وظائف" : "Jobs"
                                        }
                                    }
                                ]
                            },
                            "potentialAction": {
                                "@type": "SearchAction",
                                "target": `${metaUrl}?search={search_term_string}`,
                                "query-input": "required name=search_term_string"
                            }
                        })
                    }
                ]
            });
        }

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>