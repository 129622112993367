<template>
  <div class="design-section pb-100 pt-35 hosting-transfer">
    <div class="container position-relative">
      <div class="position-absolute top-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path d="M0 40H40L0 0L0 40Z" fill="#17D7B5" />
        </svg>
      </div>
      <header class="section-title">
        <h2>Transfer to <span>Misk</span> Hosting</h2>
        <p>Want fast hosting with unlimited features and affordable prices?</p>
        <div class="bar"></div>
      </header>

      <div class="row align-items-center">
        <section class="col-lg-6 design-image-container">
          <div class="design-image">
            <img
              src="../../assets/images/host-transfer.svg"
              alt="meeting image"
            />
          </div>
        </section>

        <section class="col-lg-6">
          <div class="design-content">
            <h3>Transfer your website hosting</h3>
            <!----<div class="bar"></div>-->
            <p>
              Activate the hosting package immediately upon payment. Get a free
              domain by choosing a hosting package. Install free scripts of your
              choice, dear customer.
            </p>
            <ul>
              <li class="hover-effect-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.3551 2.49219L20 5.13715L7.62759 17.5096L0 9.88213L2.64488 7.23717L7.62759 12.2197L17.3551 2.49219Z"
                      fill="#00B79D"
                    />
                  </svg>
                </span>
                <span> Free transfer of all your site files</span>
              </li>

              <li class="hover-effect-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.3551 2.49219L20 5.13715L7.62759 17.5096L0 9.88213L2.64488 7.23717L7.62759 12.2197L17.3551 2.49219Z"
                      fill="#00B79D"
                    />
                  </svg>
                </span>
                <span>Free database transfer</span>
              </li>

              <li class="hover-effect-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.3551 2.49219L20 5.13715L7.62759 17.5096L0 9.88213L2.64488 7.23717L7.62759 12.2197L17.3551 2.49219Z"
                      fill="#00B79D"
                    />
                  </svg>
                </span>
                <span>Free transfer of all emails</span>
              </li>

              <li class="hover-effect-2">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.3551 2.49219L20 5.13715L7.62759 17.5096L0 9.88213L2.64488 7.23717L7.62759 12.2197L17.3551 2.49219Z"
                      fill="#00B79D"
                    />
                  </svg>
                </span>
                <span>Technical support starts from the move</span>
              </li>
            </ul>

            <div class="d-flex justify-content-center">
              <button class="hosting-btn">
                <router-link to="/subscribe" class="text-capitalize">{{
                  $t("subscribeNow")
                }}</router-link>
              </button>
            </div>
          </div>
        </section>
      </div>
      <div class="position-absolute right-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path d="M0 40H40L0 0L0 40Z" fill="#17D7B5" />
        </svg>
      </div>
      <div class="position-absolute left-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path d="M0 40H40L0 0L0 40Z" fill="#17D7B5" />
        </svg>
      </div>
    </div>

    <div class="default-shape">
      <div class="shape-1">
        <img src="../../assets/images/shapes/shape-4.png" alt="image" />
      </div>

      <div class="shape-2 rotateme">
        <img src="../../assets/images/shapes/shape-5.svg" alt="image" />
      </div>

      <div class="shape-3">
        <img src="../../assets/images/shapes/shape-6.svg" alt="image" />
      </div>

      <div class="shape-4">
        <img src="../../assets/images/shapes/shape-7.png" alt="image" />
      </div>

      <div class="shape-5">
        <img src="../../assets/images/shapes/shape-8.png" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CloudTransfer",
};
</script>
<style lang="scss" scoped>
.design-image-container {
  &:lang(en) {
    text-align: left;
  }
  &:lang(ar) {
    text-align: right;
  }
}

@media screen and (max-width: 992px) {
  .design-image-container {
    &:lang(en),
    &:lang(ar) {
      text-align: center;
    }
  }
}
</style>
