<template>
    <div class="main-banner-area-three all-tools pb-100 overflow-hidden position-relative">
        <div class="d-table">
            <div class="d-table-cell pt-100">
                <div class="container">
                    <div class="row align-items-center ">
                        <div class="col-lg-6">
                            <div class="main-banner-content">
                                <h1 class="text-capitalize"> {{ $t('LearningBanner.heading') }} </h1>
                                <p>
                                    {{ $t('LearningBanner.paragraph') }}
                                </p>
                                <div
                                    class="banner-btn d-flex gap-3 align-items-center justify-content-center justify-content-lg-start">
                                    <a href="#" class="default-btn rounded-2 py-1">
                                        {{ $t('LearningBanner.getStartedButton') }}
                                    </a>
                                    <a href="#" class="text-decoration-underline text-capitalize">
                                        {{ $t('LearningBanner.viewDemoLink') }}
                                    </a>
                                </div>
                                <div class="plateforms d-flex flex-wrap gap-3 mt-3 py-4">
                                    <img src="../../../assets/images/magento-logo.svg" alt="magento logo" width="150"
                                        height="46.88">
                                    <img src="../../../assets/images/pay-logo.svg" alt="pay logo" width="77.21"
                                        height="42.05">
                                    <img src="../../../assets/images/Odoo.svg" alt="Odoo logo" width="122.7"
                                        height="42.05">
                                    <img src="../../../assets/images/googlepay.svg" alt="googlepay logo" width="116.5"
                                        height="47.56">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="position-relative">
                                <div class="abstract-banner">
                                    <img src="../../../assets/images/Icons.svg" alt="icons" width="176" height="176">
                                </div>
                                <div class="video-container">
                                    <div>
                                        <img src="../../../assets/images/TopBar(2).png" class="img-fluid" alt="bar image" width="554" height="32">
                                        <video style="width:100%" controls>
                                            <source src="../../../assets/images/e-learnig.mp4">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="default-shape">
            <div class="shape-1">
                <img src="../../../assets/images/shapes/shape-4.png" alt="image">
            </div>
            <div class="shape-2 rotateme">
                <img src="../../../assets/images/shapes/shape-5.svg" alt="image">
            </div>
            <div class="shape-3">
                <img src="../../../assets/images/shapes/shape-6.svg" alt="image">
            </div>
            <div class="shape-4">
                <img src="../../../assets/images/shapes/shape-7.png" alt="image">
            </div>
            <div class="shape-5">
                <img src="../../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Banner'
}
</script>
<style lang="scss" scoped>
*{
    &:lang(en) {
        font-family: "Poppins", sans-serif;
    }
    &:lang(ar) {
        font-family: "Tajawal", sans-serif;
    }
}
.main-banner-area-three.all-tools { border-bottom: 1px solid #E2E8F0;}
.video-container {
    div {
        border: 1px solid #CBD5E1;
        border-radius: 8px;
    }
    &:lang(en) {
        padding-left: 80px;
    }
    &:lang(ar) {
        padding-right: 80px;
    }
}
p{
    margin-top: 20px;
    &:lang(ar){font-weight: 500;}
    &:lang(en){ font-weight: 400;}
}
video {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: -5px;
}
.abstract-banner {
    position: absolute;
    top: 65%;
    z-index: -1;
    &:lang(en){
        right: -71px;
    }
    &:lang(ar){
        left:-71px;
    }
}
@media screen and (min-width:767px) and (max-width: 992px) {
    .main-banner-area-three {
        padding-top: 40px;
    }
}
@media screen and (max-width:992px) {
    .main-banner-area-three.all-tools .main-banner-content h1 {
        font-size: 40px;
    }
    .plateforms {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}
@media screen and (max-width: 1300px) {  
    .main-banner-area-three {
        padding-top: 40px;
    }
    .pt-100{
        padding-top: 50px;
    }
    .video-container {
        &:lang(ar){
            padding-right: 10px;
        }   
        &:lang(en){
            padding-left: 10px;
        } 
    }
    .main-banner-area-three.all-tools .main-banner-content h1 {
        font-size: 52px;
    }
    .plateforms {
        img{
            width: 100px;
        }
    }
}
/*
@media screen and (max-width: 1200px) {
    .main-banner-area-three {
        height: 477px;
    }
  }*/
</style>