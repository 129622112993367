<template>
    <div class="position-relative bg-applied">
        <header class="hosting-main-banner">
            <div class="container">
                <div class="row d-flex  align-items-center hosting-row section-align">
                    <div class="col-lg-6">
                        <div class="main-banner-content">
                            <h1 class="section-align">
                                {{ $t("webHosting") }}
                            </h1>
                            <p>
                                {{ $t("hostingDescription") }}
                            </p>
                            <!----<div class="row">
                                <div class="col-3 col-xl-2 text-center domains">
                                    <span>
                                        .com
                                    </span>
                                    <div>
                                        $4.99/year
                                    </div>
                                </div>
                                <div class="col-3 col-xl-2 text-center domains">
                                    <span>
                                        .net
                                    </span>
                                    <div>
                                        $4.99/year
                                    </div>
                                </div>
                                <div class="col-3 col-xl-2 text-center domains">
                                    <span>
                                        .co
                                    </span>
                                    <div>
                                        $4.99/year
                                    </div>
                                </div>
                                <div class="col-3 col-xl-2 text-center domains">
                                    <span>
                                        .info
                                    </span>
                                    <div>
                                        $4.99/year
                                    </div>
                                </div>
                                <div class="col-3 col-xl-2 text-center domains">
                                    <span>
                                        .biz
                                    </span>
                                    <div>
                                        $4.99/year
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="col-lg-6 pr-0 align-self-baseline align-self-md-center">
                        <div class="banner-image text-center">
                            <img src="../../assets/images/Images/banner-right-image-1.webp" alt="image" width="576"
                                height="411">
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section>
            <div class="hosting-inner-area">
                <div class="subscribe-container ">
                    <div class="hosting-inner-text">
                        <h2>{{ $t("findDomainTitle") }}</h2>
                        <p>
                            {{ $t("findDomainDescription") }}
                        </p>
                        <form class="newsletter-form d-none d-lg-block" data-toggle="validator">
                            <input type="email" class="input-newsletter" :placeholder="$t('searchPlaceholder')"
                                name="EMAIL" required>

                            <span class="svg-lg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.371 4.888 14.113C3.62933 12.8543 3 11.3167 3 9.5C3 7.68333 3.62933 6.14567 4.888 4.887C6.146 3.629 7.68333 3 9.5 3C11.3167 3 12.8543 3.629 14.113 4.887C15.371 6.14567 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5627 11.8127 14 10.75 14 9.5C14 8.25 13.5627 7.18733 12.688 6.312C11.8127 5.43733 10.75 5 9.5 5C8.25 5 7.18733 5.43733 6.312 6.312C5.43733 7.18733 5 8.25 5 9.5C5 10.75 5.43733 11.8127 6.312 12.688C7.18733 13.5627 8.25 14 9.5 14Z"
                                        fill="#808080" />
                                </svg>
                            </span>
                            <div class="btn-position d-flex align-items-center gap-2">
                                <button class="domain">.Com <i class="fa-solid fa-chevron-down"></i></button>
                                <button type="submit" class="search">{{ $t("searchButtonText") }}</button>
                            </div>
                        </form>
                        <form class="newsletter-form newsletter-form-sm d-block d-lg-none" data-toggle="validator">
                            <input type="email" class="input-newsletter news" :placeholder="$t('searchPlaceholder')"
                                name="EMAIL" required>
                            <span class="svg-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.371 4.888 14.113C3.62933 12.8543 3 11.3167 3 9.5C3 7.68333 3.62933 6.14567 4.888 4.887C6.146 3.629 7.68333 3 9.5 3C11.3167 3 12.8543 3.629 14.113 4.887C15.371 6.14567 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5627 11.8127 14 10.75 14 9.5C14 8.25 13.5627 7.18733 12.688 6.312C11.8127 5.43733 10.75 5 9.5 5C8.25 5 7.18733 5.43733 6.312 6.312C5.43733 7.18733 5 8.25 5 9.5C5 10.75 5.43733 11.8127 6.312 12.688C7.18733 13.5627 8.25 14 9.5 14Z"
                                        fill="#808080" />
                                </svg>
                            </span>
                            <div class="mt-2 gap-2">
                                <button class="domain-sm">{{ $t("domainButtonText") }} <i
                                        class="fa-solid fa-chevron-down"></i></button>
                                <button type="submit" class="search-sm">{{ $t("searchButtonText") }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'HostingHeader'
}
</script>