<template>
    <header class="dev-services-heading services-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content">
                        <h1>{{ $t('mobileAppDesign.title') }}</h1>
                        <p>{{ $t('mobileAppDesign.description') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
    export default{
        name:'QaHeader'
    }
</script>
