<template>
    <div class="private-servers-heading pt-70 pb-35">
        <div class="container">
            <header>
                <div class="row">
                    <div class="col-lg-6 mb-3 mb-lg-0 mt-lg-5">
                        <div class="pt-lg-4 section-align">
                            <h1 v-html="$t('misk_servers.heading')"></h1>
                            <p>
                                {{ $t('misk_servers.description_1') }}
                            </p>
                            <p v-html="$t('misk_servers.description_2')"></p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <Carousel :autoplay="2000" :wrap-around="true" :dir="direction">
                            <Slide v-for="slide of slides" :key="slide.id" class="mb-3">
                                <div class="carousel__item">
                                    <img :src="slide.imgUrl" :height="slide.height" :width="slide.width"
                                        :alt="slide.altText">
                                </div>
                            </Slide>
                            <template #addons>
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default defineComponent({
    name: 'ServersHeader',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data() {
        return {
            direction: localStorage.getItem('direction') || 'rtl',
            slides: [
                {
                    id: 1,
                    imgUrl: require('../../assets/images/servers-ill.svg'),
                    altText: 'team image',
                    width: 576,
                    height: 411,
                },
                {
                    id: 2,
                    imgUrl: require('../../assets/images/Images/image-1.webp'),
                    altText: 'team image',
                    width: 510,
                    height: 495,
                },
                {
                    id: 3,
                    imgUrl: require('../../assets/images/Images/image-2.webp'),
                    altText: 'team image',
                    width: 510,
                    height: 495,
                }
            ]
        }
    },
    mounted() {
        window.addEventListener('direction-localstorage-changed', (event) => {
            this.direction = event.detail.storage;
        });
    },
})
</script>