<template>
    <section class="vps-difference ptb-100">
        <div class="container position-relative servers-difference">
            <div class="top-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                    <path d="M72 72L6.29444e-06 72L72 0L72 72Z" fill="#17D7B5" />
                </svg>
            </div>
            <h2 class="text-transform-none">{{ $t('private_servers.heading') }}</h2>
            <p class="top-info">
                {{ $t('private_servers.description') }}
            </p>
            <div class="bottom-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                    <path d="M72 72L6.29444e-06 72L72 0L72 72Z" fill="#17D7B5" />
                </svg>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'serverssDifference'
}
</script>