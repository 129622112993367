<template>
    <div>
        <NavbarStyleTwo />
        <testmonial-header></testmonial-header>
        <PaginationTest />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import PaginationTest from '../Testmonial/PaginationTest'
import TestmonialHeader from '../Testmonial/TestmonialHeader'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex';
export default {
    name: 'TeamPage',
    components: {
        NavbarStyleTwo,
        PaginationTest,
        TestmonialHeader,
        Footer,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'آراء العملاء | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Customer Testimonials | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اقرأ ما يقوله عملاؤنا عن تجاربهم مع شركة مسك لحلول تكنولوجيا المعلومات. اكتشف كيف ساعدناهم في تحقيق أهدافهم باستخدام حلول تقنية مبتكرة وموثوقة.'
                : 'Read what our clients have to say about their experiences with Misk IT Solutions. Discover how we’ve helped them achieve their goals through innovative and reliable IT solutions.';

            const metaKeywords = isArabic
                ? 'آراء العملاء, تجارب العملاء, مراجعات العملاء, حلول تكنولوجيا المعلومات, مسك لحلول تكنولوجيا المعلومات, رضا العملاء, شهادات العملاء'
                : 'customer testimonials, client reviews, customer feedback, IT solutions, Misk IT Solutions, customer satisfaction, client success stories';

            const metaImage = isArabic
                ? 'https://example.com/testimonials-ar.jpg'
                : 'https://example.com/testimonials-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/testimonials'
                : 'https://misk.com/testimonials';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "review": [
                                {
                                    "@type": "Review",
                                    "author": {
                                        "@type": "Person",
                                        "name": "John Doe"
                                    },
                                    "reviewBody": "Misk IT Solutions provided us with outstanding service and innovative solutions that truly transformed our business operations.",
                                    "reviewRating": {
                                        "@type": "Rating",
                                        "ratingValue": "5",
                                        "bestRating": "5"
                                    }
                                },
                                {
                                    "@type": "Review",
                                    "author": {
                                        "@type": "Person",
                                        "name": "Ahmed Al-Rashid"
                                    },
                                    "reviewBody": "خدمات رائعة من شركة مسك لحلول تكنولوجيا المعلومات. ساعدونا على تحقيق أهدافنا بسرعة وكفاءة.",
                                    "reviewRating": {
                                        "@type": "Rating",
                                        "ratingValue": "5",
                                        "bestRating": "5"
                                    }
                                }
                            ]
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>