<template>
    <div>
        <Navbar />
        <PageTitle />
        <ContactBox />
        <Contact />
        <TestBootstrap></TestBootstrap>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import PageTitle from '../Contact/PageTitle'
import ContactBox from '../Contact/ContactBox'
import Contact from '../Contact/Contact'
import Footer from '../Layout/Footer'
import TestBootstrap from '../Contact/TestBootstrap'
import { mapGetters } from 'vuex/dist/vuex.cjs.js';
import { useHead } from '@vueuse/head'
export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageTitle,
        ContactBox,
        Contact,
        Footer,
        TestBootstrap

    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'تواصل معنا | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Contact Us | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اتصل بشركة مسك لحلول تكنولوجيا المعلومات لمعرفة المزيد عن خدماتنا. احصل على الدعم الفني، استفسر عن الحلول التقنية، أو قم بترتيب اجتماع مع فريقنا.'
                : 'Get in touch with Misk IT Solutions to learn more about our services. Reach out for technical support, inquire about IT solutions, or schedule a meeting with our team.';

            const metaKeywords = isArabic
                ? 'اتصل بنا, شركة مسك, حلول تقنية, استفسارات, دعم فني, خدمات تكنولوجيا المعلومات, التواصل مع شركة مسك'
                : 'contact us, Misk IT Solutions, IT solutions, technical support, inquiries, IT services, reach out';

            const metaImage = isArabic
                ? 'https://example.com/contact-image-ar.jpg'
                : 'https://example.com/contact-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/contact-us'
                : 'https://misk.com/contact-us';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "ContactPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+1234567890",
                                "contactType": "customer service",
                                "areaServed": isArabic ? "العالم العربي" : "Global",
                                "availableLanguage": [isArabic ? "ar" : "en"]
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "تواصل معنا" : "Contact Us"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>