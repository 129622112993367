<template>
    <section>
        <div class="container">
            <div class="row technologies">
                <div class="col-lg-6 demo-btn-container">
                    <div>
                        <img src="../../assets/images/Macbook2.svg" width="646" height="512" alt="screenshot">
                    </div>
                </div>
                <div class="col-lg-6  mb-4 mb-md-1 ">
                    <h4 class="organize hover-effect-2">
                        {{ $t('Projects.techTitle') }}
                    </h4>
                    <p class="hover-effect-2">
                        {{ $t('Projects.techDescription') }}
                    </p>
                    <div class="d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap flex-sm-nowrap gap-3">
                        <div class="technology-logo hover-effect">
                            <div class="technology-logo--img" style="width: 74px;" >
                                <img src="../../assets/images/linux-preview.png" class="img-fluid" width="433" height="577" alt="linux logo">
                            </div>
                            <span class="text-center">Linux</span>
                        </div>
                        <div class="technology-logo hover-effect">
                            <div class="technology-logo--img">
                                <img src="../../assets/images/apple-2-preview.png" class="img-fluid" width="577" height="433" alt="linux logo">
                            </div>
                            <span class="text-center">Mac</span>
                        </div>
                        <div class="technology-logo hover-effect"> 
                            <div class="technology-logo--img">
                                <img src="../../assets/images/Windows_logo.png" style="width: 74px;" class="img-fluid"  width="768" height="768" alt="linux logo">
                            </div>
                            <span class="text-center">Windows</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="scss" scoped>
.technologies {
    margin-top: 70px;
    margin-bottom: 70px;
    h4,
    p {
        line-height: 45px;
        text-transform: capitalize;
        color: #000;
    }
    h4 {
        font-size: 22px;
        font-weight: 700;
        line-height: 45px;
        margin-top: 28px;
    }
    p {
        margin-top: 25px;
        margin-bottom: 25px;
        font-size: 18px;
        font-weight: 400;
    }
}
.technology-logo {
    width: 195px;
    height: 197px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--Neutral-Shark-200, #D4DBE3);
    background: #FFF;
    .technology-logo--img{
        width: 109px;
        overflow: hidden;
        object-fit: contain;
        text-align: center;
    }
    span{
        color: #000;
        font-size: 18px;
        font-weight: 400;
        line-height: 45px; /* 250% */
        text-transform: capitalize;
    }
}
@media screen and (min-width:992px) and (max-width: 1200px) {
    .technology-logo {
        width: 140px;
        height: 147px;
    }
}
@media screen and (max-width: 768px) {
    .technology-logo {
        width: 140px;
        height: 147px;
        img{
            width: 60px;
            height: auto;
        }
    }
}
@media screen and (min-width: 1200.99px)  and (max-width: 1400px) {
    .technology-logo {
        width: 155px;
        height: 157px;
    }
}
</style>