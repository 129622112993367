<template>
  <div
    class="pricing-section pb-35 pt-70 hosting-plan-section private-server-pricing"
  >
    <div class="container vps-pricing">
      <div class="row">
        <section class="col-lg-3 col-md-6 mt-3">
          <div class="single-pricing">
            <div class="pricing-header mb-3">
              <h3 class="mt-3">SERVER-D1</h3>
              <span>1840$<span class="year">/ Year </span></span>
            </div>

            <ul class="pricing-list px-2 px-lg-3">
              <li class="d-flex justify-content-between">
                <span> Random Ram </span>
                <span> 2048mb </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Hard Disk </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Traffic </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Calls Number /moment </span>
                <span> 500 </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Mails Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Databases Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssl certificate </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssh Access </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Technical Support/24 </span>
                <span class="free"> Free </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
            </ul>

            <div class="price-btn">
              <router-link to="/subscribe" class="default-btn">
                {{ $t("subscribeNow") }}
              </router-link>
            </div>
          </div>
        </section>
        <section class="col-lg-3 col-md-6 mt-3">
          <div class="single-pricing">
            <div class="pricing-header mb-3">
              <h3 class="mt-3">SERVER-D1</h3>
              <span>1840$<span class="year">/ Year </span></span>
            </div>

            <ul class="pricing-list px-2 px-lg-3">
              <li class="d-flex justify-content-between">
                <span> Random Ram </span>
                <span> 2048mb </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Hard Disk </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Traffic </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Calls Number /moment </span>
                <span> 500 </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Mails Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Databases Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssl certificate </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssh Access </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Technical Support/24 </span>
                <span class="free"> Free </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
            </ul>

            <div class="price-btn">
              <router-link to="/subscribe" class="default-btn">
                {{ $t("subscribeNow") }}
              </router-link>
            </div>
          </div>
        </section>
        <section class="col-lg-3 col-md-6 mt-3">
          <div class="single-pricing">
            <div class="pricing-header mb-3">
              <h3 class="mt-3">SERVER-D1</h3>
              <span>1840$<span class="year">/ Year </span></span>
            </div>

            <ul class="pricing-list px-2 px-lg-3">
              <li class="d-flex justify-content-between">
                <span> Random Ram </span>
                <span> 2048mb </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Hard Disk </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Traffic </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Calls Number /moment </span>
                <span> 500 </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Mails Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Databases Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssl certificate </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssh Access </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Technical Support/24 </span>
                <span class="free"> Free </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
            </ul>

            <div class="price-btn">
              <router-link to="/subscribe" class="default-btn">
                {{ $t("subscribeNow") }}
              </router-link>
            </div>
          </div>
        </section>
        <section class="col-lg-3 col-md-6 mt-3">
          <div class="single-pricing">
            <div class="pricing-header mb-3">
              <h3 class="mt-3">SERVER-D1</h3>
              <span>1840$<span class="year">/ Year </span></span>
            </div>

            <ul class="pricing-list px-2 px-lg-3">
              <li class="d-flex justify-content-between">
                <span> Random Ram </span>
                <span> 2048mb </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Hard Disk </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Traffic </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Calls Number /moment </span>
                <span> 500 </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Mails Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Databases Number </span>
                <span> unlimited </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssl certificate </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Ssh Access </span>
                <span> Available </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Technical Support/24 </span>
                <span class="free"> Free </span>
              </li>
              <li class="d-flex justify-content-between">
                <span> Processor </span>
                <span> 5000 MHZ </span>
              </li>
            </ul>

            <div class="price-btn">
              <router-link to="/subscribe" class="default-btn">
                {{ $t("subscribeNow") }}
              </router-link>
            </div>
          </div>
        </section>

        <!--End Of Pricing -->
      </div>
    </div>

    <div class="default-shape">
      <div class="shape-1">
        <img src="../../assets/images/shapes/shape-4.png" alt="image" />
      </div>

      <div class="shape-2 rotateme">
        <img src="../../assets/images/shapes/shape-5.svg" alt="image" />
      </div>

      <div class="shape-3">
        <img src="../../assets/images/shapes/shape-6.svg" alt="image" />
      </div>

      <div class="shape-4">
        <img src="../../assets/images/shapes/shape-7.png" alt="image" />
      </div>

      <div class="shape-5">
        <img src="../../assets/images/shapes/shape-8.png" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServerPricing",
};
</script>
