<template>
    <div class="error-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="error-content">
                        <img src="../../assets/images/404.png" alt="error">
                        <h3>{{ $t('page_not_found.heading') }}</h3>
                        <p>
                            {{ $t('page_not_found.description') }}
                        </p>
                        <router-link to="/" class="default-btn">
                            {{ $t('page_not_found.button') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Error'
}
</script>