<template>
    <div>
        <Navbar />
        <services-header></services-header>
        <best-software></best-software>
        <!----<Services></Services>-->
        <new-services></new-services>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/NavbarStyleTwo'
import ServicesHeader from '../MiskServices/ServicesHeader'
import BestSoftware from '../MiskServices/BestSoftware'
/*import Services from '../HomeOne/Services'*/
import NewServices from '../HomeOne/NewServices'
/*import Faq from '../MiskServices/Faq'*/
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex';

export default {
    components: {
        Navbar,
        BestSoftware,
        /*  Services,*/
        /* Faq,*/
        Footer,
        ServicesHeader,
        NewServices,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'خدمات تقنية متكاملة | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Comprehensive IT Services | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف مجموعة واسعة من الخدمات التقنية التي تقدمها شركة مسك، بما في ذلك تطوير البرمجيات، الحلول السحابية، الأمن السيبراني، وتصميم المواقع لتعزيز نجاح عملك.'
                : 'Discover a wide range of IT services offered by Misk, including software development, cloud solutions, cybersecurity, and web design to boost your business success.';

            const metaKeywords = isArabic
                ? 'خدمات تقنية, تطوير البرمجيات, حلول السحابة, الأمن السيبراني, تصميم المواقع, حلول الأعمال, شركة مسك, خدمات تكنولوجيا المعلومات'
                : 'IT services, software development, cloud solutions, cybersecurity, web design, business solutions, Misk IT Solutions, technology services';

            const metaImage = isArabic
                ? 'https://example.com/services-image-ar.jpg'
                : 'https://example.com/services-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/services'
                : 'https://misk.com/services';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "خدماتنا" : "Services"
                                        }
                                    }
                                ]
                            },
                            "service": {
                                "@type": "Service",
                                "serviceType": isArabic ? "تطوير البرمجيات" : "Software Development",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Misk IT Solutions"
                                }
                            }
                        })
                    }
                ]
            });
        }

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>