<template>
    <header class="dev-services-heading services-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-xl-9 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content ps-lg-5 ps-xl-0">
                        <h1>{{ $t('brandIdentity.header.headerH1') }} </h1>
                        <p>
                            {{ $t('brandIdentity.header.headerP') }}
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    </header>
 
</template>
<script>
    export default{
        name:'UiHeader'
    }
</script>