<template>
    <div>
        <section class="container job-resume pt-35 pb-35">
            <section class="section-title">
  <h3>{{ $t('how_it_works.section_title') }}</h3>
  <p>{{ $t('how_it_works.subtitle') }}</p>
</section>
            <div class="row mt-lg-5">
                <section class="col-md-4 text-center mt-3 mt-lg-0">
                    <div >
                        <div>
                            <img src="../../assets/images/Images/resume.webp" height="113" width="105" alt="image">
                        </div>
                        <div>
                            <h4>{{ $t('how_it_works.features[0].title') }}</h4>
                            <p>{{ $t('how_it_works.features[0].description') }}</p>
                        </div>
                    </div>
                </section>
                <section class="col-md-4 text-center mt-3 mt-lg-0">
                    <div >
                        <div>
                            <img src="../../assets/images/Images/resume-1.webp" height="113" width="105" alt="image">
                        </div>
                        <div>
                            <h4>{{ $t('how_it_works.features[1].title') }}</h4>
                            <p>{{ $t('how_it_works.features[1].description') }}</p>
                        </div>
                    </div>
                    
                </section>
                <section class="col-md-4 text-center mt-3 mt-lg-0">
                    <div >
                    <div>
                        <img src="../../assets/images/Images/resume-2.webp" height="113" width="106" alt="image">
                    </div>
                    <div>
                        <h4>{{ $t('how_it_works.features[2].title') }}</h4>
                        <p>{{ $t('how_it_works.features[2].description') }}</p>
                    </div>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>