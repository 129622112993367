<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <TabsComponent />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Footer from '../Layout/Footer'
import TabsComponent from '../Solutions/TabsComponent.vue';
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex/dist/vuex.cjs.js';
export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Footer,
        TabsComponent
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'حلول تقنية لجميع احتياجات الأعمال | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'IT Solutions for Every Business Need | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'اكتشف مجموعة شاملة من الخدمات التي تقدمها شركة مسك لتلبية احتياجات عملك. من تطوير البرمجيات إلى الحلول السحابية والأمن السيبراني، نقدم تقنيات مبتكرة لتعزيز نجاحك.'
                : 'Explore Misk IT Solutions\' comprehensive range of services designed to meet your business needs. From software development to cloud solutions and cybersecurity, we provide innovative technologies to drive your success.';

            const metaKeywords = isArabic
                ? 'حلول تقنية, حلول تكنولوجيا المعلومات, تطوير البرمجيات, حلول السحابة, الأمن السيبراني, التحول الرقمي, خدمات تقنية, حلول الأعمال, شركة مسك'
                : 'IT solutions, business technology, software development, cloud solutions, cybersecurity, digital transformation, IT services, enterprise solutions, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/solutions-image-ar.jpg'
                : 'https://example.com/solutions-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/solutions'
                : 'https://misk.com/solutions';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "حلول تقنية" : "Solutions"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }


    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>