<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <Banner :Banner="localizedBanner" />
        <SectionOne :features="localizedFeatures" />
        <RelatedArticles />
        <div style="margin-bottom: 70px;">
            <OurClients />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../Projects/Features/Banner.vue';
import SectionOne from '../Projects/Features/SectionOne.vue';
import RelatedArticles from '../Projects/Features/RelatedArticles.vue';
import Footer from '../Layout/Footer'
import OurClients from '../HomeOne/OurClients'
import { mapGetters } from 'vuex';
import { useHead } from '@vueuse/head'

export default {
    name: 'AboutPage',
    components: {
        'navbar-style-two': NavbarStyleTwo,
        Banner,
        SectionOne,
        RelatedArticles,
        OurClients,
        Footer,
    },
    data() {
        return {
            BannerContent: {
                titleKey: 'sprintBanner.title',
                descriptionKey: 'sprintBanner.description',
                buttonLinkKey: '',
                buttonTextKey: 'sprintBanner.buttonText',
                image: require('../../assets/images/todo-list-1.png'),
                imageAltKey: "sprintBanner.imageAlt",
                imageWidth: 694,
                imageHeight: 477
            },
            Features: [
                { image: require('../../assets/images/todo-list-2.png'), titleKey: 'sprint[0].title', descriptionKey: 'sprint[0].description', imageWidth: 656, imageHeight: 442, reverseOrder: true },
                { image: require('../../assets/images/todo-list-3.png'), titleKey: 'sprint[1].title', descriptionKey: 'sprint[1].description', imageWidth: 647, imageHeight: 435 },
            ],

        }
    },
    computed: {
        ...mapGetters(['currentLanguage']),
        localizedFeatures() {
            return this.Features.map(feature => ({
                ...feature,
                title: this.$t(feature.titleKey),
                description: this.$t(feature.descriptionKey)
            }));
        },
        localizedBanner() {
            // Ensure BannerContent is defined before accessing its properties
            return {
                title: this.$t(this.BannerContent.titleKey),
                description: this.$t(this.BannerContent.descriptionKey),
                buttonLink: this.BannerContent.buttonLinkKey, // If buttonLinkKey is static, leave it as is
                buttonText: this.$t(this.BannerContent.buttonTextKey),
                image: this.BannerContent.image,
                imageAlt: this.$t(this.BannerContent.imageAltKey),
                imageWidth: this.BannerContent.imageWidth,
                imageHeight: this.BannerContent.imageHeight
            }; // Return an empty object if BannerContent is undefined
        },
        ...mapGetters(['currentLanguage']),
    },
  
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'إدارة السبرينت | شركة مسك لحلول تكنولوجيا المعلومات'
                : 'Sprint Management | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'إدارة السبرينت هي عملية هامة لضمان نجاح المشاريع التقنية. نحن نقدم حلول مرنة لإدارة السبرينتات بكفاءة لضمان تسليم المشاريع في الوقت المحدد وبأعلى جودة.'
                : 'Sprint management is a crucial process to ensure the success of tech projects. We provide flexible solutions to manage sprints efficiently, ensuring project delivery on time and with the highest quality.';

            const metaKeywords = isArabic
                ? 'إدارة السبرينت, تخطيط السبرينت, تطوير البرمجيات, إدارة المشاريع, منهجية أجايل, تخطيط المشروع, فريق السبرينت'
                : 'sprint management, sprint planning, software development, project management, Agile methodology, project planning, sprint team';

            const metaImage = isArabic
                ? 'https://example.com/sprint-management-ar.jpg'
                : 'https://example.com/sprint-management-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/sprint-management'
                : 'https://misk.com/sprint-management';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            }
                        })
                    }
                ]
            });
        }

    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}

</script>