<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <cyber-header></cyber-header>
        <computer-security></computer-security>
        <OperationCenter></OperationCenter>
        <new-cyber-services></new-cyber-services>
        <cyber-security-features></cyber-security-features>
        <CyberWorkWithUs></CyberWorkWithUs>
        <Faq></Faq>
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import CyberHeader from '../CyberSecurity/CyberHeader'
import ComputerSecurity from '../CyberSecurity/ComputerSecurity'
import OperationCenter from '../CyberSecurity/OperationCenter'
import NewCyberServices from '../CyberSecurity/NewCyberServices'
import CyberWorkWithUs from '../CyberSecurity/CyberWorkWithUs'
import Faq from '../CyberSecurity/Faq'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex'
import CyberSecurityFeatures from '../CyberSecurity/CyberSecurityFeatures.vue'
export default {
    components: {
        NavbarStyleTwo,
        CyberHeader,
        ComputerSecurity,
        CyberWorkWithUs,
        Footer,
        OperationCenter,
        NewCyberServices,
        Faq,
        CyberSecurityFeatures
        /* SecurityServices*/
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';

            const metaTitle = isArabic
                ? 'حماية رقمية متكاملة للأعمال | حلول الأمن السيبراني | شركة مسك'
                : 'Comprehensive Digital Protection for Businesses | Cybersecurity Solutions | Misk IT Solutions';

            const metaDescription = isArabic
                ? 'احمِ بيانات شركتك من التهديدات الرقمية باستخدام حلول الأمن السيبراني المتقدمة من شركة مسك. اكتشف خدمات الحماية المتقدمة التي تتضمن إدارة التهديدات، الاستجابة للحوادث، وأمن الشبكات.'
                : 'Protect your company\'s data from digital threats with advanced cybersecurity solutions by Misk IT Solutions. Explore services like threat management, incident response, and network security.';

            const metaKeywords = isArabic
                ? 'الأمن السيبراني, حماية البيانات, أمن الشبكات, الحماية الرقمية, الاستجابة للحوادث, إدارة التهديدات, شركة مسك'
                : 'cybersecurity, data protection, network security, digital protection, incident response, threat management, Misk IT Solutions';

            const metaImage = isArabic
                ? 'https://example.com/cybersecurity-image-ar.jpg'
                : 'https://example.com/cybersecurity-image-en.jpg';

            const metaUrl = isArabic
                ? 'https://misk.com/ar/cybersecurity'
                : 'https://misk.com/cybersecurity';

            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },

                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },

                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "الأمن السيبراني" : "Cybersecurity"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>