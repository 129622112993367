<template>
  <div class="slider clients-section pb-35 pt-70">
    <div class="container">
        <header class="section-title">
          <h2>{{ $t('AboutValue.aboutHeading') }}</h2>
          <div class="bar"></div>
        </header>
      
        <div class="clients-slider position-relative">
          <transition name="fade" mode="out-in">
            <div :key="currentIndex" class="clients-item row">
              <div class="col-lg-5 col-12 ">
                <img
                  :src="currentItem.imgData.imgUrl"
                  :alt="currentItem.imgData.alt"
                  :width="currentItem.imgData.width"
                  :height="currentItem.imgData.height"
                />
              </div>
              <div class="clients-content col-lg-7 ">
                <h3>{{ currentItem.title }}</h3>
                <p class="description ">{{ currentItem.description }}</p>
                <ul class="list-unstayled">
                  <li class="d-flex justify-content-start align-items-baseline "
                    v-for="(item, index) in currentItem.listItems" 
                    :key="index"
                  >
                    <span class="d-none d-md-inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="14"
                        viewBox="0 0 22 14"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.500612 7.00095C0.500612 6.80204 0.57963 6.61127 0.720282 6.47062C0.860935 6.32997 1.0517 6.25095 1.25061 6.25095H18.9401L14.2196 1.53195C14.1499 1.46222 14.0946 1.37943 14.0568 1.28832C14.0191 1.19721 13.9997 1.09956 13.9997 1.00095C13.9997 0.902332 14.0191 0.804681 14.0568 0.713572C14.0946 0.622463 14.1499 0.539679 14.2196 0.469947C14.2893 0.400215 14.3721 0.344901 14.4632 0.307163C14.5543 0.269424 14.652 0.25 14.7506 0.25C14.8492 0.25 14.9469 0.269424 15.038 0.307163C15.1291 0.344901 15.2119 0.400215 15.2816 0.469947L21.2816 6.46995C21.3515 6.53962 21.4069 6.62238 21.4447 6.7135C21.4825 6.80462 21.502 6.9023 21.502 7.00095C21.502 7.0996 21.4825 7.19728 21.4447 7.2884C21.4069 7.37952 21.3515 7.46228 21.2816 7.53195L15.2816 13.5319C15.2119 13.6017 15.1291 13.657 15.038 13.6947C14.9469 13.7325 14.8492 13.7519 14.7506 13.7519C14.652 13.7519 14.5543 13.7325 14.4632 13.6947C14.3721 13.657 14.2893 13.6017 14.2196 13.5319C14.1499 13.4622 14.0946 13.3794 14.0568 13.2883C14.0191 13.1972 13.9997 13.0996 13.9997 13.0009C13.9997 12.9023 14.0191 12.8047 14.0568 12.7136C14.0946 12.6225 14.1499 12.5397 14.2196 12.4699L18.9401 7.75095H1.25061C1.0517 7.75095 0.860935 7.67193 0.720282 7.53128C0.57963 7.39063 0.500612 7.19986 0.500612 7.00095Z"
                          fill="#00B79D"
                        />
                      </svg>
                    </span>
                    <p class="d-inline-block slider-paragraph">{{ item }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
       
      
            <section class="pagination">
                <span
                v-for="(item, index) in carouselItems"
                :key="index"
                class="dot"
                :class="{ active: currentIndex === index }"
                @click="goToSlide(index)"
                ></span>
            </section>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
      currentIndex: 0,
      intervalId: null,
      carouselItems: [
      {
          id: 1,
          imgData: {
            imgUrl: require("../../assets/images/support.svg"),
            alt:"24/7 Support",
            width: 406,
            height: 406,
          },
          title: "support.title",
          description:"support.description",
          listItems: [
            "support.listItem1",
            "support.listItem2",
            "support.listItem3",
          ],
        },
       
        {
          id: 2,
          imgData: {
            imgUrl: require("../../assets/images/friendly-communication.svg"),
            alt: "Friendly Communication",
            width: 406,
            height: 406,
          },
          title: "communication.title",
          description:"communication.description",
          listItems: [
            "communication.listItem1",
            "communication.listItem2",
            "communication.listItem3",
          ],
        },
        {
          id: 3,
          imgData: {
            imgUrl: require("../../assets/images/elegant-work.svg"),
            alt: "Elegant Work",
            width: 406,
            height: 406,
          },
          title: "elegantWork.title",
          description:"elegantWork.description",
          listItems: [
            "elegantWork.listItem1",
            "elegantWork.listItem2",
            "elegantWork.listItem3",
          ],
        },
        {
          id: 4,
          imgData: {
            imgUrl: require("../../assets/images/fast-delivery.svg"),
            alt: "Expert Guidance Image",
            width: 406,
            height: 406,
          },
          title: "expertGuidance.title",
          description:"expertGuidance.description",

          listItems: [
            "expertGuidance.listItem1",
            "expertGuidance.listItem2",
            "expertGuidance.listItem3",
          ],
        },
        {
          id: 5,
          imgData: {
            imgUrl: require("../../assets/images/Images/smart-solution.webp"),
            alt: "Scalable Solutions",
            width: 406,
            height: 406,
          },
          title: "scalableSolutions.title",
          description:"scalableSolutions.description",
          listItems: [
            "scalableSolutions.listItem1",
            "scalableSolutions.listItem2",
            "scalableSolutions.listItem3",
          ],
        },
        {
          id: 6,
          imgData: {
            imgUrl: require("../../assets/images/new-technologies.svg"),
            alt: "Continuous Innovation",
            width: 406,
            height: 406,
          },
          title: "continuousInnovation.title",
          description:"continuousInnovation.description",

          listItems: [
            "continuousInnovation.listItem1",
            "continuousInnovation.listItem2",
            "continuousInnovation.listItem3",
          ],
        },
        
        
        
      ],
    };
  },
  computed: {
    localizedCarouselItems() {
      return this.carouselItems.map(item => ({
        ...item,
        title: this.$t(item.title),
        description: this.$t(item.description),
        listItems: item.listItems.map(listItem => this.$t(listItem))
      }));
    },
    currentItem() {//this.carouselItems[this.currentIndex];
      return this.localizedCarouselItems[this.currentIndex];
    },
  },
  mounted() {
    this.startAutoplay();
  },
  methods: {
    goToSlide(index) {
      this.currentIndex = index;
      this.restartAutoplay();
    },
    startAutoplay() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.carouselItems.length;
      }, 5000); // Change 5000 to the desired interval in milliseconds (e.g., 3000 for 3 seconds)
    },
    restartAutoplay() {
      clearInterval(this.intervalId);
      this.startAutoplay();
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style>


</style>
