<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <PageTitle />
    <portofolio-tabs></portofolio-tabs>
    <Footer />
  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import PageTitle from "../Portofolio/PageTitle.vue";
import PortofolioTabs from "../Portofolio/ProtofolioTabs.vue";
import Footer from "../Layout/Footer";
import { useHead } from "@vueuse/head";
import { mapGetters } from "vuex";

export default {
  name: "PortofolioPage",
  components: {
    "navbar-style-two": NavbarStyleTwo,
    PageTitle,
    "portofolio-tabs": PortofolioTabs,
    Footer,
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
  },
  methods: {
    updateHeadMeta() {
      const isArabic = this.currentLanguage === "ar";

      const metaTitle = isArabic
        ? "مشاريعنا السابقة | شركة مسك لحلول تكنولوجيا المعلومات"
        : "Our Portfolio | Misk IT Solutions";

      const metaDescription = isArabic
        ? "اكتشف مشاريعنا السابقة التي توضح قدرتنا على تقديم حلول تقنية مبتكرة. شركة مسك متخصصة في تطوير البرمجيات، تصميم التطبيقات، التحول الرقمي، وأكثر من ذلك."
        : "Explore our portfolio showcasing innovative IT solutions delivered by Misk. From software development to app design and digital transformation, see how we drive success.";

      const metaKeywords = isArabic
        ? "مشاريع تقنية, حلول تكنولوجيا المعلومات, تطوير البرمجيات, تصميم التطبيقات, التحول الرقمي, الأمن السيبراني, شركة مسك, عملاء مسك"
        : "IT projects, IT solutions, software development, app design, digital transformation, cybersecurity, Misk IT Solutions, Misk clients";

      const metaImage = isArabic
        ? "https://example.com/portfolio-image-ar.jpg"
        : "https://example.com/portfolio-image-en.jpg";

      const metaUrl = isArabic
        ? "https://misk.com/ar/portfolio"
        : "https://misk.com/portfolio";

      useHead({
        title: metaTitle,
        meta: [
          { name: "description", content: metaDescription },
          { name: "keywords", content: metaKeywords },
          { name: "robots", content: "index, follow" },
          { name: "language", content: this.currentLanguage },

          // Open Graph
          { property: "og:title", content: metaTitle },
          { property: "og:description", content: metaDescription },
          { property: "og:image", content: metaImage },
          { property: "og:url", content: metaUrl },
          { property: "og:type", content: "website" },

          // Twitter Card
          { name: "twitter:card", content: "summary_large_image" },
          { name: "twitter:title", content: metaTitle },
          { name: "twitter:description", content: metaDescription },
          { name: "twitter:image", content: metaImage },
        ],
        script: [
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              name: metaTitle,
              description: metaDescription,
              url: metaUrl,
              publisher: {
                "@type": "Organization",
                name: "Misk IT Solutions",
                logo: {
                  "@type": "ImageObject",
                  url: "https://example.com/logo.jpg",
                },
              },
              inLanguage: [isArabic ? "ar" : "en"],
              mainEntityOfPage: {
                "@type": "WebPage",
                "@id": metaUrl,
              },
              primaryImageOfPage: {
                "@type": "ImageObject",
                url: metaImage,
              },
              breadcrumb: {
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    item: {
                      "@id": "https://misk.com",
                      name: isArabic ? "الرئيسية" : "Home",
                    },
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                      "@id": metaUrl,
                      name: isArabic ? "مشاريعنا" : "Portfolio",
                    },
                  },
                ],
              },
            }),
          },
        ],
      });
    },
  },
  mounted() {
    this.updateHeadMeta(); // Set metadata when component mounts
  },
  watch: {
    currentLanguage: {
      handler() {
        this.updateHeadMeta(); // Update metadata when language changes
      },
      immediate: true,
    },
  },
};
</script>
