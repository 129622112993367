<template>
    <div class="container mb-5">
        <section class=" job-benefits">
            <header class="text-center">
  <h4>{{ $t('benefits.header') }}</h4>
</header>
            <div class="row">
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-1">
                    <div>
                        <h4>{{ $t('benefits.sections.flexible_working.title') }}</h4>
                        <ul>
                            <li>{{ $t('benefits.sections.flexible_working.list[0]') }}</li>
                            <li>{{ $t('benefits.sections.flexible_working.list[1]') }}</li>
                        </ul>
                    </div>
                </section>
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-1">
                    <div>
                        <h4>{{ $t('benefits.sections.competitive_remuneration.title') }}</h4>
                        <ul>
                            <li>{{ $t('benefits.sections.competitive_remuneration.list[0]') }}</li>
                            <li>{{ $t('benefits.sections.competitive_remuneration.list[1]') }}</li>
                        </ul>
                    </div>
                </section>
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-5">
                    <div>
                        <h4>{{ $t('benefits.sections.bonus_bits.title') }}</h4>
                        <ul>
                            <li>{{ $t('benefits.sections.bonus_bits.list[0]') }}</li>
                            <li>{{ $t('benefits.sections.bonus_bits.list[1]') }}</li>
                        </ul>
                    </div>
                </section>
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-5">
                    <div>
                        <h4>{{ $t('benefits.sections.stay_fuelled.title') }}</h4>
                        <ul>
                            <li>{{ $t('benefits.sections.stay_fuelled.list[0]') }}</li>
                            <li>{{ $t('benefits.sections.stay_fuelled.list[1]') }}</li>
                        </ul>
                    </div>
                </section>
            </div>
            <p class="last mt-4 mt-lg-5">{{ $t('benefits.closing') }}</p>
        </section>
    </div>
</template>