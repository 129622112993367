<template>
    <div class="digital-section pt-70 pb-35">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 ">
                    <div class="digital-image section-align">
                        <img src="../../assets/images/digital.png" alt="image" class="w-90">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="digital-content">
                        <h3>
                         <span>
                            {{ $t('SeoTeam.span1') }}
                         </span>
                            </h3>
                        <div class="bar"></div>
                        <!----<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices  Lorem Ipsum is simply dummy tex printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    -->
                        <ul class="digital-list">
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    {{ $t('SeoTeam.listItem1') }}
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    {{ $t('SeoTeam.listItem2') }}
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    {{ $t('SeoTeam.listItem3') }}
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    {{ $t('SeoTeam.listItem4') }}
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    {{ $t('SeoTeam.listItem5') }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Digital'
    }
</script>