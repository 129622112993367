<template>
    <section>
        <div class="container clients">
            <div class="section-title">
                <h4> {{ $t('titles.heading') }}</h4>
            </div>
        </div>
        <div class="container-fluid our-clients">
            <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="true"
                :dir="direction">
                <Slide v-for="slide in localizeItems" :key="slide">
                    <div class="d-flex flex-column">
                        <p>
                            {{ slide.title }}
                        </p>
                        <div class="img-container position-relative my-4">
                            <div class="first-shape square-shape">
                                <div></div>
                            </div>
                            <div style="border-radius: 20px; overflow: hidden;">
                                <img :src="slide.img.imgUrl" :alt="slide.img.altText" :width="slide.img.width" :height="slide.img.height" >
                            </div>
                            <div class="second-shape square-shape">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </Slide>
                <template #addons>
                </template>
            </Carousel>
        </div>
    </section>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default defineComponent({
    name: 'OurClients',
    components: {
        Pagination,
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            direction: localStorage.getItem('direction') || 'rtl',
            slides: [
                {
                    img: {
                        imgUrl: require('../../assets/images/build-2.png'),
                        altText: 'taam',
                        width: 388,
                        height: 257,
                    },
                    title: 'titles.finance'
                },
                {
                    img: {
                        imgUrl: require('../../assets/images/build.png'),
                        altText: 'elite minds',
                        width: 389,
                        height: 256,
                    },
                    title: 'titles.marketing'
                },
                {
                    img: {
                        imgUrl: require('../../assets/images/build-3.png'),
                        altText: 'taam',
                        width: 388,
                        height: 257,
                    },
                    title: 'titles.hr'
                },
                {
                    img: {
                        imgUrl: require('../../assets/images/build-4.png'),
                        altText: 'taam',
                        width: 388,
                        height: 257,
                    },
                    title: 'titles.product'
                },
                {
                    img: {
                        imgUrl: require('../../assets/images/build-5.png'),
                        altText: 'elite minds',
                        width: 389,
                        height: 256,
                    },
                    title: 'titles.accounting'
                },
            ],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                1000: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                // 1024 and up
                1400: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
            },/*********/
        }
    },
    computed:{
        localizeItems() {
        return this.slides.map(item => ({
            ...item,
            title: this.$t(item.title),
            //description: this.$t(item.description) 
        }));
    }
    },
    mounted() {
        window.addEventListener('direction-localstorage-changed', (event) => {
            this.direction = event.detail.storage;
        });
    },
})
</script>
<style lang="scss" scoped>
section {
    background: #EBEBEBC4;
    padding-top: 37px;
    padding-bottom: 37px;
}
p {
    color: var(--Black, #121212);
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
h4 {
    color: #081F32;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    line-height: 35px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.carousel__viewport {
    perspective: 2000px;
}
.carousel__slide {
    position: relative;
}
.carousel__slide {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.square-shape {
    border-radius: 15px;
    padding: 10px;
    background: linear-gradient(180deg, #00B79D 0%, #0E4E66 100%);
    width: 238px;
    height: 129px;
    border: 0;
    position: absolute;
    z-index: -1;
    div {
        background-color: white;
        padding: 10px;
        width: 220px;
        border-radius: 15px;
        height: 120px;
    }
}
.first-shape {
    right: -6%;
    top: -8%;
}
.second-shape {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    left: -6%;
    bottom: -7%;
}
</style>