<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <GraphicHeader />
    <GraphicOverview />
    <BrandServices />
    <graphic-design-process></graphic-design-process>
    <graphic-work-with-us></graphic-work-with-us>
    <Faq />
    <Footer />
  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import GraphicHeader from "../BrandIdentity/GraphicHeader";
import GraphicOverview from "../BrandIdentity/GraphicOverview";
import BrandServices from "../BrandIdentity/BrandServices";
import GraphicDesignProcess from "../BrandIdentity/GraphicDesignProcess";

import GraphicWorkWithUs from "../BrandIdentity/GraphicWorkWithUs";
import Faq from "../BrandIdentity/Faq";
import Footer from "../Layout/Footer";
import { useHead } from '@vueuse/head';
import { mapGetters } from 'vuex/dist/vuex.cjs.js';

export default {
  components: {
    NavbarStyleTwo,
    GraphicHeader,
    GraphicOverview,
    GraphicDesignProcess,
    GraphicWorkWithUs,
    BrandServices,
    Faq,
    Footer,
  },
  computed: {
    ...mapGetters(['currentLanguage']),
  },
  methods: {
    updateHeadMeta() {
      const isArabic = this.currentLanguage === 'ar';

      const metaTitle = isArabic
        ? 'هوية العلامة التجارية | شركة مسك لحلول تكنولوجيا المعلومات'
        : 'Brand Identity | Misk IT Solutions';

      const metaDescription = isArabic
        ? 'قم ببناء هوية علامتك التجارية مع شركة مسك. نقدم حلولًا متكاملة لتعزيز تصميم العلامة التجارية، الشعارات، واختيار الألوان بما يعكس رؤيتك وقيمك.'
        : 'Build your brand identity with Misk IT Solutions. We provide comprehensive solutions to enhance your brand design, logos, and color choices to reflect your vision and values.';

      const metaKeywords = isArabic
        ? 'هوية العلامة التجارية, تصميم العلامة التجارية, تصميم الشعارات, تطوير العلامة التجارية, شركة مسك, حلول تكنولوجيا المعلومات'
        : 'brand identity, brand design, logo creation, brand development, Misk IT Solutions, IT solutions';

      const metaImage = isArabic
        ? 'https://example.com/brand-identity-image-ar.jpg'
        : 'https://example.com/brand-identity-image-en.jpg';

      const metaUrl = isArabic
        ? 'https://misk.com/ar/brand-identity'
        : 'https://misk.com/brand-identity';

      useHead({
        title: metaTitle,
        meta: [
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: metaKeywords },
          { name: 'robots', content: 'index, follow' },
          { name: 'language', content: this.currentLanguage },

          // Open Graph
          { property: 'og:title', content: metaTitle },
          { property: 'og:description', content: metaDescription },
          { property: 'og:image', content: metaImage },
          { property: 'og:url', content: metaUrl },
          { property: 'og:type', content: 'website' },

          // Twitter Card
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: metaTitle },
          { name: 'twitter:description', content: metaDescription },
          { name: 'twitter:image', content: metaImage }
        ],
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": metaTitle,
              "description": metaDescription,
              "url": metaUrl,
              "publisher": {
                "@type": "Organization",
                "name": "Misk IT Solutions",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://example.com/logo.jpg"
                }
              },
              "inLanguage": [isArabic ? "ar" : "en"],
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": metaUrl
              },
              "primaryImageOfPage": {
                "@type": "ImageObject",
                "url": metaImage
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://misk.com",
                      "name": isArabic ? "الرئيسية" : "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": metaUrl,
                      "name": isArabic ? "هوية العلامة التجارية" : "Brand Identity"
                    }
                  }
                ]
              }
            })
          }
        ]
      });
    }


  },
  mounted() {
    this.updateHeadMeta(); // Set metadata when component mounts
  },
  watch: {
    currentLanguage: {
      handler() {
        this.updateHeadMeta(); // Update metadata when language changes
      },
      immediate: true,
    },
  },
};
</script>