<template>
    <section class="solution-support">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="d-flex align-items-center gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122"
                                fill="none">
                                <path
                                    d="M106.75 62.1183C106.75 34.2108 85.0953 15.25 61.0003 15.25C37.1595 15.25 15.2503 33.8042 15.2503 62.4233C12.2003 64.1517 10.167 67.405 10.167 71.1667V81.3333C10.167 86.925 14.742 91.5 20.3337 91.5H25.417V60.4917C25.417 40.8192 41.3278 24.9083 61.0003 24.9083C80.6728 24.9083 96.5837 40.8192 96.5837 60.4917V96.5833H55.917V106.75H96.5837C102.175 106.75 106.75 102.175 106.75 96.5833V90.3817C109.749 88.8058 111.834 85.705 111.834 82.045V70.3533C111.834 66.795 109.749 63.6942 106.75 62.1183Z"
                                    fill="#2A6A84" />
                                <path
                                    d="M45.7503 71.1667C48.5578 71.1667 50.8337 68.8908 50.8337 66.0833C50.8337 63.2759 48.5578 61 45.7503 61C42.9429 61 40.667 63.2759 40.667 66.0833C40.667 68.8908 42.9429 71.1667 45.7503 71.1667Z"
                                    fill="#2A6A84" />
                                <path
                                    d="M76.2503 71.1667C79.0578 71.1667 81.3337 68.8908 81.3337 66.0833C81.3337 63.2759 79.0578 61 76.2503 61C73.4429 61 71.167 63.2759 71.167 66.0833C71.167 68.8908 73.4429 71.1667 76.2503 71.1667Z"
                                    fill="#2A6A84" />
                                <path
                                    d="M91.4999 56.0692C90.2878 48.9227 86.5864 42.4355 81.0508 37.7559C75.5153 33.0762 68.5026 30.5059 61.2541 30.5C45.8516 30.5 29.2799 43.2592 30.6016 63.2875C36.87 60.7224 42.4063 56.6461 46.7169 51.4219C51.0275 46.1977 53.9784 39.9881 55.3066 33.3467C61.9657 46.7158 75.6399 55.9167 91.4999 56.0692Z"
                                    fill="#2A6A84" />
                            </svg>
                        </div>
                        <div class="support-text mt-3">
                            <h5>{{ $t("support1.title") }}</h5>
                            <p class="mt-2 pt-2">
                                {{ $t("support1.description") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="w-100 d-flex align-items-center justify-content-center  h-100">
                        <router-link to="/contact" class="default-btn-1"> {{ $t('mainnavItems.contact-us') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>