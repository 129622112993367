<template>
  <section>
    <div class="container pt-70">
      <!-- Tab Headers -->
      <ul class="nav-tabs-custom d-flex list-unstyled  flex-lg-wrap justify-content-start justify-content-lg-between">
        <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
          <button class="nav-link" :class="{ active: activeTab === index }" @click="activeTab = index">
            {{ tab.label }}
          </button>
        </li>
      </ul>
    </div>
    <div class="container-fluid">
      <!-- Tab Content -->
      <div class="tab-content mt-3">
        <section :key="`tab-0`" v-if="activeTab === 0">
          <FirstSlider :slides="localizedSlide" />
        </section>

        <section :key="`tab-1`" v-else-if="activeTab === 1">
          <FirstSlider :slides="localizedSlide2" />
        </section>

        <section :key="`tab-2`" v-else-if="activeTab === 2">
          <FirstSlider :slides="localizedSlide3" />
        </section>

        <section :key="`tab-3`" v-else-if="activeTab === 3">
          <FirstSlider :slides="localizedSlide4" />
        </section>

        <section :key="`tab-4`" v-else-if="activeTab === 4">
          <FirstSlider :slides="localizedSlide5" />
        </section>

        <section :key="`tab-5`" v-else-if="activeTab === 5">
          <FirstSlider :slides="localizedSlide6" />
        </section>

        <section :key="`tab-6`" v-else-if="activeTab === 6">
          <FirstSlider :slides="localizedSlide7" />
        </section>

        <section :key="`tab-7`" v-else-if="activeTab === 7">
          <FirstSlider :slides="localizedSlide8" />
        </section>

        <section :key="`tab-8`" v-else-if="activeTab === 8">
          <FirstSlider :slides="localizedSlide9" />
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import FirstSlider from './FirstSlider.vue';
export default {
  components: {
    FirstSlider
  },
  data() {
    return {
      activeTab: 0, // Index of the currently active tab
      /*tabs: [
      { label: this.$t('tabs.project') },
        { label: this.$t('tabs.task') },
        { label: this.$t('tabs.time tracker') },
        { label: this.$t('tabs.reports') },
        { label: this.$t('tabs.Advanced Search') },
        { label: this.$t('tabs.Custom Field') },
        { label: this.$t('tabs.Chat') },
        { label: this.$t('tabs.Advanced Setting') },
        { label: this.$t('tabs.AI') }
      ],*/

      slides: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-1-1.png'),
            altText: 'project 1',
            width: 1024,
            height: 768,
          },
          title: 'slides.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-2-1.png'),
            altText: 'taam',
            width: 2880,
            height: 1800,
          },
          title: 'slides.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-3-1.jpg'),
            altText: 'Project templets',
            width: 400,
            height: 300,
          },
          title: 'slides.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-4-1.png'),
            altText: 'mohandsi logo',
            width: 400,
            height: 300,
          },
          title: 'slides.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-5-1.png'),
            altText: 'Project details',
            width: 1024,
            height: 768,
          },
          title: 'slides.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-1-1.png'),
            altText: 'project 1',
            width: 1024,
            height: 768,
          },
          title: 'slides.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-2-1.png'),
            altText: 'taam',
            width: 2880,
            height: 1800,
          },
          title: 'slides.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],

      slides2: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-g.png'),
            altText: 'project 1',
            width: 400,
            height: 300,
          },
          title: 'slides2.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-6.png'),
            altText: 'taam',
            width: 400,
            height: 300,
          },
          title: 'slides2.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-7.png'),
            altText: 'pm tricks ',
            width: 311,
            height: 202,
          },
          title: 'slides2.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-8.jpg'),
            altText: 'mohandsi logo',
            width: 299,
            height: 205,
          },
          title: 'slides2.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-80.png'),
            altText: 'project 1',
            width: 308,
            height: 214,
          },
          title: 'slides2.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-81.png'),
            altText: 'taam',
            width: 400,
            height: 300,
          },
          title: 'slides2.title6',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],

      slides3: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-11.png'),
            altText: 'project 1',
            width: 309,
            height: 208,
          },
          title: 'slides3.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-12.png'),
            altText: 'taam',
            width: 305,
            height: 215,
          },
          title: 'slides3.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-13.png'),
            altText: 'pm tricks ',
            width: 309,
            height: 222,
          },
          title: 'slides3.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-14.png'),
            altText: 'mohandsi logo',
            width: 315,
            height: 234,
          },
          title: 'slides3.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-15.png'),
            altText: 'project 1',
            width: 313,
            height: 234,
          },
          title: 'slides3.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-17.png'),
            altText: 'taam',
            width: 307,
            height: 236,
          },
          title: 'slides3.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],
      slides4: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-20.png'),
            altText: 'project 1',
            width: 310,
            height: 206,
          },
          title: 'slides4.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-21.png'),
            altText: 'taam',
            width: 314,
            height: 208,
          },
          title: 'slides4.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-22.png'),
            altText: 'pm tricks ',
            width: 318,
            height: 218,
          },
          title: 'slides4.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-23.png'),
            altText: 'mohandsi logo',
            width: 310,
            height: 224,
          },
          title: 'slides4.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-22.png'),
            altText: 'pm tricks ',
            width: 318,
            height: 218,
          },
          title: 'slides4.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-23.png'),
            altText: 'mohandsi logo',
            width: 310,
            height: 224,
          },
          title: 'slides4.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-24.png'),
            altText: 'pm tricks ',
            width: 429,
            height: 272,
          },
          title: 'slides4.title6',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],
      slides5: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-30.png'),
            altText: 'project 1',
            width: 320,
            height: 218,
          },
          title: 'slides5.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-31.png'),
            altText: 'taam',
            width: 325,
            height: 334,
          },
          title: 'slides5.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-32.png'),
            altText: 'pm tricks ',
            width: 315,
            height: 225,
          },
          title: 'slides5.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-33.png'),
            altText: 'mohandsi logo',
            width: 315,
            height: 220,
          },
          title: 'slides5.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-36.png'),
            altText: 'taam',
            width: 427,
            height: 296,
          },
          title: 'slides5.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-37.jpg'),
            altText: 'pm tricks ',
            width: 315,
            height: 225,
          },
          title: 'slides5.title6',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],

      slides6: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-40.png'),
            altText: 'mohandsi logo',
            width: 428,
            height: 272,
          },
          title: 'slides6.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-40.png'),
            altText: 'mohandsi logo',
            width: 428,
            height: 272,
          },
          title: 'slides6.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-40.png'),
            altText: 'mohandsi logo',
            width: 428,
            height: 272,
          },
          title: 'slides6.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-40.png'),
            altText: 'mohandsi logo',
            width: 428,
            height: 272,
          },
          title: 'slides6.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-40.png'),
            altText: 'mohandsi logo',
            width: 428,
            height: 272,
          },
          title: 'slides6.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-40.png'),
            altText: 'mohandsi logo',
            width: 428,
            height: 272,
          },
          title: 'slides6.title6',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],

      slides7: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-50.png'),
            altText: 'project 1',
            width: 428,
            height: 272,
          },
          title: 'slides7.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-51.png'),
            altText: 'taam',
            width: 428,
            height: 272,
          },
          title: 'slides7.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-52.png'),
            altText: 'pm tricks ',
            width: 428,
            height: 272,
          },
          title: 'slides7.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-50.png'),
            altText: 'project 1',
            width: 428,
            height: 272,
          },
          title: 'slides7.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-51.png'),
            altText: 'taam',
            width: 428,
            height: 272,
          },
          title: 'slides7.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-52.png'),
            altText: 'pm tricks ',
            width: 428,
            height: 272,
          },
          title: 'slides7.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],

      slides8: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-60.png'),
            altText: 'project 1',
            width: 312,
            height: 220,
          },
          title: 'slides8.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-61.png'),
            altText: 'taam',
            width: 313,
            height: 323,
          },
          title: 'slides8.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-62.png'),
            altText: 'pm tricks ',
            width: 310,
            height: 213,
          },
          title: 'slides8.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-63.png'),
            altText: 'mohandsi logo',
            width: 317,
            height: 224,
          },
          title: 'slides8.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-62.png'),
            altText: 'pm tricks ',
            width: 310,
            height: 213,
          },
          title: 'slides8.title5',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-63.png'),
            altText: 'mohandsi logo',
            width: 317,
            height: 224,
          },
          title: 'slides8.title4',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-66.png'),
            altText: 'taam',
            width: 313,
            height: 323,
          },
          title: 'slides8.title6',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],

      slides9: [
        {
          image:
          {
            imgUrl: require('../../assets/images/project-70.png'),
            altText: 'project 1',
            width: 316,
            height: 223,
          },
          title: 'slides9.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-71.png'),
            altText: 'taam',
            width: 448,
            height: 287,
          },
          title: 'slides9.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-72.png'),
            altText: 'pm tricks ',
            width: 427,
            height: 270,
          },
          title: 'slides9.title3',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
        {
          image:
          {
            imgUrl: require('../../assets/images/project-70.png'),
            altText: 'project 1',
            width: 316,
            height: 223,
          },
          title: 'slides9.title1',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },

        {
          image:
          {
            imgUrl: require('../../assets/images/project-71.png'),
            altText: 'taam',
            width: 448,
            height: 287,
          },
          title: 'slides9.title2',
          description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum '
        },
      ],
    };
  },
  computed: {
    localizedSlide() { return this.localizeItems(this.slides); },
    localizedSlide2() { return this.localizeItems(this.slides2); },
    localizedSlide3() { return this.localizeItems(this.slides3); },
    localizedSlide4() { return this.localizeItems(this.slides4); },
    localizedSlide5() { return this.localizeItems(this.slides5); },
    localizedSlide6() { return this.localizeItems(this.slides6); },
    localizedSlide7() { return this.localizeItems(this.slides7); },
    localizedSlide8() { return this.localizeItems(this.slides8); },
    localizedSlide9() { return this.localizeItems(this.slides9); },
    tabs() {
      return [
        { label: this.$t('tabs.project') },
        { label: this.$t('tabs.task') },
        { label: this.$t('tabs.time tracker') },
        { label: this.$t('tabs.reports') },
        { label: this.$t('tabs.Advanced Search') },
        { label: this.$t('tabs.Custom Field') },
        { label: this.$t('tabs.Chat') },
        { label: this.$t('tabs.Advanced Setting') },
        { label: this.$t('tabs.AI') }
      ]
    }
  },
  methods: {
    localizeItems(slidesArray) {
      return slidesArray.map(item => ({
        ...item,
        title: this.$t(item.title),
        //description: this.$t(item.description) 
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
/* Optional styling for better visualization */
.nav-link {
  cursor: pointer;
  border-radius: 20px;
  padding: 7px 25px;
  border: none;
  color: #467688;
  font-size: 16.5px;
  font-weight: 600;
  text-transform: capitalize;
  background: transparent;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  white-space: nowrap;

  &.active,
  &:hover {
    background: #00B79D;
    color: white;
  }
}

.nav-tabs-custom {
  gap: 5px;
}

@media screen and (max-width:1400px) {
  .nav-tabs-custom {
    padding-bottom: 10px;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: #11656ea3 #f0f0f0;
  }

  /* Webkit-based browsers (Chrome, Safari, Edge) */
  .nav-tabs-custom::-webkit-scrollbar {
    height: 5px;
  }

  .nav-tabs-custom::-webkit-scrollbar-thumb {
    background-color: #1a8e9ba3;
    border-radius: 5px;
  }

  .nav-tabs-custom::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
}
</style>
