<template>
    <div class="services-section pt-35 pb-0">
        <div class="container">
            <header class="section-title">
                <h2>{{ $t('HomeContent.services.h2') }}</h2>
                <!----<p>
                    At Misk, we offer all you need to turn your idea into a reality. Our services include:
                </p>-->
                <div class="bar"></div>
            </header>

            <section class="row">
                <div class="col-lg-4 col-md-6">
                    <router-link to="/services-development">
                    <div class="single-services">
                        <div class="icon">
                            <i class="flaticon-setting"></i>
                        </div>
                        <h3>{{ $t('HomeContent.services.listItem1h3') }}</h3>
                        <p>
                            {{ $t('HomeContent.services.listItem1p') }}
                        </p>
                        <!----<router-link to="/single-services" class="read-btn">Read More</router-link>-->
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-mobile-development" class="d-flex">
                    <div class="single-services flex-size">
                        <div class="icon">
                            <i class="flaticon-cellphone"></i>
                        </div>
                        <h3>{{ $t('HomeContent.services.listItem2h3') }}</h3>
                        <p>
                            {{ $t('HomeContent.services.listItem2p') }}
                        </p>
                       <!--<router-link to="/services-graphic-design" class="read-btn">Read More</router-link>--> 
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-brand-design" class="d-flex">
                    <div class="single-services flex-size">
                        <div class="icon">
                            <i class="flaticon-optimize"></i>
                        </div>
                        <h3>{{ $t('HomeContent.services.listItem3h3') }}</h3>
                        <p>
                            {{ $t('HomeContent.services.listItem3p') }}
                        </p>
                        <!----<router-link to="/services-quality-assurance" class="read-btn">Read More</router-link>-->
                    </div>
                    </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-cyber-security" class="d-flex">
                    <div class="single-services">
                        <div class="icon">
                            <i class="flaticon-it"></i>
                        </div>
                        <h3>{{ $t('HomeContent.services.listItem4h3') }}</h3>
                        <p>
                            {{ $t('HomeContent.services.listItem4p') }}
                        </p> 
                        <!----<router-link to="/services-cyber-security" class="read-btn">Read More</router-link>-->
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-digital-marketing" class="d-flex">
                    <div class="single-services">
                        <div class="icon">
                            <i class="flaticon-promotion"></i>
                        </div>
                        <h3>{{ $t('HomeContent.services.listItem5h3') }}</h3>
                        <p>
                            {{ $t('HomeContent.services.listItem5p') }}
                        </p>
                        <!--<router-link to="/services-digital-marketing" class="read-btn">Read More</router-link>-->
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                        <router-link to="/services-seo" class="d-flex">
                            <div class="single-services">
                                <div class="icon">
                                    <i class="flaticon-shopping-cart"></i>
                                </div>
                                <h3>{{ $t('HomeContent.services.listItem6h3') }}</h3>
                                <p>
                                    {{ $t('HomeContent.services.listItem6p') }}
                                </p>
                                <!----<router-link to="/services-ui-ux" class="read-btn">Read More</router-link>-->
                            </div>
                        </router-link>  
                </div>

            </section>
           
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Services'
        ,
    
    }
</script>
