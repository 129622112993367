<template>
    <div class="services-header">
        <div class="container">
            <header>
                <div class="row d-flex align-items-center">
                    <div class="col-lg-6 services-header-content">
                        <h1 v-html="$t('it_services.header.title')"></h1>
                        <p>
                            {{ $t('it_services.description') }}
                        </p>
                        <div>
                            <router-link to="/contact" class="d-inline-flex justify-content-center align-items-center">
                                {{ $t('it_services.contact_button') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-6 text-center mt-4 mt-lg-0">
                        <img src="../../assets/images/digital-presentation.svg" alt="animated image" width="355" height="355">
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>
<script>
    export default{
        name: 'ServicesHeader'
    }
</script>
<style lang="scss" scoped>
    .services-header-content{
        &:lang(en){text-align: left;}
        &:lang(ar){text-align: right;}
    }
    @media screen and (max-width: 768pxpx) {
        .services-header-content{
        &:lang(en),&:lang(ar){text-align: center;}
    }
    }
</style>