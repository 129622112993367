<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <dev-header></dev-header>
        <dev-custom></dev-custom>
        <DevChoose />
        <dev-technology></dev-technology>
        <work-with-us></work-with-us>
        <development-process></development-process>
        <Faq></Faq>
        <Footer />
    </div>
</template>
<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import DevHeader from '../Development/DevHeader'
import DevCustom from '../Development/DevCustom'
import DevTechnology from '../Development/DevTechnology'
import DevChoose from '../Development/DevChoose'
import WorkWithUs from '../Development/WorkWithUs'
import Faq from '../Development/Faq'
import DevelopmentProcess from '../Development/DevelopmentProcess'
import Footer from '../Layout/Footer'
import { useHead } from '@vueuse/head'
import { mapGetters } from 'vuex'
export default {
    components: {
        NavbarStyleTwo,
        DevHeader,
        DevCustom,
        DevChoose,
        Faq,
        DevTechnology,
        WorkWithUs,
        Footer,
        DevelopmentProcess,
    },
    computed: {
        ...mapGetters(['currentLanguage']),
    },
    methods: {
        updateHeadMeta() {
            const isArabic = this.currentLanguage === 'ar';
            const metaTitle = isArabic
                ? 'تطوير مواقع الويب المبتكرة | حلول تطوير المواقع | شركة مسك'
                : 'Innovative Web Development Services | Website Development Solutions | Misk IT Solutions';
            const metaDescription = isArabic
                ? 'نقدم حلول تطوير مواقع الويب المتكاملة التي تتناسب مع احتياجات عملك. من تصميم واجهات المستخدم إلى بناء الأنظمة المعقدة، نحن هنا لتحقيق رؤيتك الرقمية.'
                : 'We offer comprehensive web development solutions tailored to meet your business needs. From UI/UX design to complex system development, we are here to bring your digital vision to life.';
            const metaKeywords = isArabic
                ? 'تطوير مواقع الويب, تصميم المواقع, تطوير الأنظمة, البرمجة, حلول الويب, تطوير الواجهات, شركة مسك'
                : 'web development, website design, system development, programming, web solutions, UI/UX design, Misk IT Solutions';
            const metaImage = isArabic
                ? 'https://example.com/web-development-image-ar.jpg'
                : 'https://example.com/web-development-image-en.jpg';
            const metaUrl = isArabic
                ? 'https://misk.com/ar/web-development'
                : 'https://misk.com/web-development';
            useHead({
                title: metaTitle,
                meta: [
                    { name: 'description', content: metaDescription },
                    { name: 'keywords', content: metaKeywords },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'language', content: this.currentLanguage },
                    // Open Graph
                    { property: 'og:title', content: metaTitle },
                    { property: 'og:description', content: metaDescription },
                    { property: 'og:image', content: metaImage },
                    { property: 'og:url', content: metaUrl },
                    { property: 'og:type', content: 'website' },
                    // Twitter Card
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:title', content: metaTitle },
                    { name: 'twitter:description', content: metaDescription },
                    { name: 'twitter:image', content: metaImage }
                ],
                script: [
                    {
                        type: 'application/ld+json',
                        innerHTML: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": metaTitle,
                            "description": metaDescription,
                            "url": metaUrl,
                            "publisher": {
                                "@type": "Organization",
                                "name": "Misk IT Solutions",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://example.com/logo.jpg"
                                }
                            },
                            "inLanguage": [isArabic ? "ar" : "en"],
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": metaUrl
                            },
                            "primaryImageOfPage": {
                                "@type": "ImageObject",
                                "url": metaImage
                            },
                            "breadcrumb": {
                                "@type": "BreadcrumbList",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "item": {
                                            "@id": "https://misk.com",
                                            "name": isArabic ? "الرئيسية" : "Home"
                                        }
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "item": {
                                            "@id": metaUrl,
                                            "name": isArabic ? "تطوير مواقع الويب" : "Web Development"
                                        }
                                    }
                                ]
                            }
                        })
                    }
                ]
            });
        }
    },
    mounted() {
        this.updateHeadMeta(); // Set metadata when component mounts
    },
    watch: {
        currentLanguage: {
            handler() {
                this.updateHeadMeta(); // Update metadata when language changes
            },
            immediate: true,
        },
    },
}
</script>