<template>
    <div class="about-section pt-70">
        <div class="container">
          <!---Start Of Top Section-->
            <div class="section-title">
                <span>Recent Works</span>
                <h1>Our Portfolio</h1>
                <div class="bar"></div>
            </div>
            <!---End Of Top Section-->
            <div class="row">
                <div class="portofolio-tab mb-5">
                        <section class="about-list-tab audience-content">
                            <div class="tabs">
                                <ul class="tabs__header list-unstyled text-center">
                                    <li 
                                        class="tabs__header-item d-inline-block mb-3 mb-lg-2" 
                                        v-for="tab in tabs" 
                                        @click="selectTab (tab.id)" 
                                        :class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        {{tab.name}}
                                    </li>
                                </ul>
                            </div>
                            <div class="tabs__container">
                                <div 
                                    class="tabs__list"
                                    ref='tabsList'
                                >
                                    <div 
                                        class="tabs__list-tab"
                                        v-for="tab in tabs"
                                        v-bind:class="{ 'active': activeTab == tab.id }"
                                        :key="tab.id"
                                    >
                                        <section class="row mt-4 section-align" >
                                            <div class="col-12 col-lg-6">
                                                <div class="tabs_item">
                                                <h2>{{ tab.firstSection.heading }}</h2>
                                                <span v-for="spanItem,i of tab.firstSection.spanItems" :key="i" 
                                                class="d-inline-block me-3 mb-3 my-lg-0">
                                                    {{ spanItem }}
                                                </span>
                                                <p>
                                                    {{ tab.firstSection.description }}
                                                </p>
                                                <div >
                                                    <router-link class="audience-btn" :to="tab.firstSection.btnLink">{{ tab.btnText }}</router-link>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6" >
                                                <div class=" my-3 mt-lg-0" >
                                                    <img :src="tab.firstSection.imageUrl.url" :alt="tab.firstSection.imageUrl.alt"
                                                     :width="tab.firstSection.imageUrl.width" :height="tab.firstSection.imageUrl.height">
                                                </div>
                                            </div>
                                        </section>
                                        <section class="row second-section align-items-center mt-4 section-align">
                                            <div class="col-12 col-lg-6 order-2 order-lg-1 mt-4 mt-lg-0 d-flex d-lg-block justify-content-center ">
                                                <div class="audience-image mt-3 mt-lg-0">
                                                    <img :src="tab.secondSection.imageUrl.url" :alt="tab.secondSection.imageUrl.alt"
                                                     :width="tab.secondSection.imageUrl.width" :height="tab.secondSection.imageUrl.height">
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 order-1 order-lg-2">
                                                <div class="tabs_item">
                                                    <h2>{{ tab.secondSection.heading }}</h2>
                                                    <span v-for="spanItem,i of tab.secondSection.spanItems" :key="i" class="d-inline-block me-3">
                                                        {{ spanItem }}
                                                    </span>
                                                    <p>
                                                        {{ tab.secondSection.description }}
                                                    </p>
                                                    <div >
                                                        <router-link class="audience-btn" :to="tab.secondSection.btnLink">{{ tab.btnText }}</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <!--------------Start Of third section content ------------>
                                        <section class="row third-section mt-4 section-align">
                                            <div class="col-lg-6">
                                                <div class="tabs_item">
                                                <h2>{{ tab.thirdSection.heading }}</h2>
                                                <span v-for="spanItem,i of tab.thirdSection.spanItems" :key="i" class="d-inline-block me-3">
                                                    {{ spanItem }}
                                                </span>
                                                <p>
                                                    {{ tab.thirdSection.description }}
                                                </p>
                                                <div>
                                                    <router-link class="audience-btn" :to="tab.thirdSection.btnLink">{{ tab.btnText }}</router-link>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mt-4 mt-lg-0">
                                                <div class="mt-3 mt-lg-0">
                                                    <img :src="tab.thirdSection.imageUrl.url" :alt="tab.thirdSection.imageUrl.alt" 
                                                     :width="tab.thirdSection.imageUrl.width" :height="tab.thirdSection.imageUrl.height">
                                                </div>
                                            </div>
                                        </section>
                                        <!--------------End Of Third Section----------------------->
                                    </div>
                                </div>
                            </div>
                        </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PortofolioTabs',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    name: 'all',
                    firstSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    secondSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-mobile.webp'),
                            width: '304',
                            height : '479',
                            alt: 'albalatny website'
                        }
                    },
                    thirdSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    btnText: 'view case study',
                },
               {
                    id: 2,
                    name: 'mobile applications',
                     firstSection:{
                        heading: 'E learning Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    secondSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-mobile.webp'),
                            width: '304',
                            height : '479',
                            alt: 'albalatny website'
                        }
                    },
                    thirdSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    btnText: 'view case study',
                },
                {
                    id: 3,
                    name: 'e-commerce design',
                    firstSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    secondSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-mobile.webp'),
                            width: '304',
                            height : '479',
                            alt: 'albalatny website'
                        }
                    },
                    thirdSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    btnText: 'view case study',
                },
                {
                    id: 4,
                    name: 'E-Learning',
                    firstSection:{
                        heading: 'E-Learning Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    secondSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-mobile.webp'),
                            width: '304',
                            height : '479',
                            alt: 'albalatny website'
                        }
                    },
                    thirdSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    btnText: 'view case study',
                },
                {
                    id: 5,
                    name: 'branding',
                    firstSection:{
                        heading: 'branding Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    secondSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-mobile.webp'),
                            width: '304',
                            height : '479',
                            alt: 'albalatny website'
                        }
                    },
                    thirdSection:{
                        heading: 'Elbalaty Website',
                        spanItems:['Web Deign','Web Development'],
                        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                        btnLink: '/single-case-study',
                        imageUrl:{
                            url: require('../../assets/images/Images/home-Macbook.webp'),
                            width: '602',
                            height : '304',
                            alt: 'albalatny website'
                        }
                    },
                    btnText: 'view case study',
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
           /*let tabsList = this.$refs.tabsList*/
            this.activeTab = id
            /*this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'*/
        },
    },
    mounted () {
      /*let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'*/
    },
}
</script>
