<template>
  <section class="container">
    <div v-for="(item, index) in features" :key="index" class="row" :class="{ 'flex-row-reverse': item.reverseOrder }">
      <div class="col-lg-6" :class="{
        'order-2 order-lg-1': item.reverseOrder,
        'order-1 order-lg-2': !item.reverseOrder,
      }">
        <img :src="item.image" class="img-fluid hover-effect-1" :class="!item.reverseOrder ? 'top-animate' : ''"
          :alt="item.title" :width="item.imageWidth" :height="item.imageHeight" />
      </div>
      <div class="col-lg-6 d-flex flex-column  justify-content-center">
        <div :class="item.reverseOrder ? 'row-text' : 'row-text-1'" class="hover-effect">
          <div>
            <h2 class="hover-effect-2">{{ item.title }}</h2>
            <p class="hover-effect-2">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-top: 90px;
  margin-bottom: 140px;
}

.hover-effect {
  padding: 35px 15px;
}

h2 {
  color: #081f32;
  font-size: 35px;
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
}

p {
  color: var(--Secondary-Text, #8898aa);
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.5px;
  margin-top: 25px;
}

.row-text {
  &:lang(en) {
    padding-left: 40px;
  }

  &:lang(ar) {
    padding-right: 40px;
  }
}

.row-text-1 {
  &:lang(ar) {
    padding-left: 40px;
  }

  &:lang(en) {
    padding-right: 40px;
  }
}

@media screen and (max-width: 992px) {
  .row {
    margin-top: 40px;
  }

  .hover-effect {
    padding: 25px;
  }

  .row-text {
    margin-bottom: 25px;

    &:lang(en) {
      padding-left: 0px;
    }

    &:lang(ar) {
      padding-right: 0px;
    }
  }

  .row-text-1 {
    margin-bottom: 25px;

    &:lang(ar) {
      padding-left: 0px;
    }

    &:lang(en) {
      padding-right: 0px;
    }
  }
}
</style>
