<!-- src/components/ArticleCard.vue -->
<template>
  <section class="col-lg-4 mt-4 mt-lg-0 d-flex">
    <div class="card hover-effect" style="flex:1">
      <div class="card-body d-flex flex-column justify-content-between">
        <div>
          <div class="d-flex gap-3 management">
            <span v-for="(category, index) in categories" :key="index"
              class="d-inline-flex justify-content-center align-items-center">
              {{ category }}
            </span>
          </div>
          <span class="card-subtitle mb-2 text-muted">{{ publishDate }}</span>
          <h3 class="card-title">{{ title }}</h3>
          <p class="card-text">{{ description }}</p>
        </div>
        <div class="link-container">
          <a :href="link" class="card-link">{{ $t('readMore') }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ArticleCard',
  props: {
    title: String,
    description: String,
    categories: Array,
    publishDate: String,
    link: String
  }
}
</script>

<style lang="scss" scoped>
* {
  &:lang(en) {
    font-family: "Poppins", sans-serif;
  }

  &:lang(ar) {
    font-family: "Tajawal", sans-serif;
  }
}

.hover-effect {
  border-radius: 23px !important;
}

.card {
  border-radius: 23px;
  border: 1px solid var(--Stroke, #EBEBEB);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 40px 10px;

  .management {
    margin-bottom: 5px;

    span {
      padding: 2px 8px;
      background: #27DEBF57;
      color: #2A6A84;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }

  span,
  .card-subtitle {
    line-height: 36px;
  }

  .card-subtitle,
  .card-text {
    color: #8898AA;
  }

  .card-subtitle,
  .card-link {
    font-weight: 500;
  }

  .card-subtitle {
    font-size: 14px;
    letter-spacing: 0.32px;
  }

  .card-title {
    color: #081F32;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.8;
    text-transform: capitalize;
  }

  .card-text,
  .card-link {
    line-height: 36px;
    letter-spacing: 0.44px;
  }

  .card-text {
    font-size: 22px;
    font-weight: 400;
  }

  .card-link {
    color: #2A6A84;
    font-size: 20px;
    transition: 0.2s ease-in-out;
    display: inline-block;

    &:hover {
      cursor: pointer;
      transform: translateY(5px);
    }
  }

  .link-container {
    &:lang(en) {
      text-align: right;
    }

    &:lang(ar) {
      text-align: left;
    }
  }
}
</style>