<template>
    <section class="solution-form">
        <div class="section-title">
            <h2 class="text-black" v-html="$t('cyberTab.buttonText1')"></h2>
        </div>
        <form class="w-80" @submit.prevent="sendMessage">
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label for="formGroupExampleInput" class="form-label">{{ $t("form.nameLabel") }}</label>
                    <input type="text" class="form-control" :placeholder="$t('form.namePlaceholder')"
                        aria-label="First name">
                </div>
                <div class="col-md-6 mt-3">
                    <label for="formGroupExampleInput" class="form-label">{{ $t("form.emailLabel") }}</label>
                    <input type="text" class="form-control" :placeholder="$t('form.emailPlaceholder')"
                        aria-label="Last name">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mt-3">
                    <label for="formGroupExampleInput" class="form-label">{{ $t("form.phoneLabel") }}</label>
                    <input type="text" class="form-control" :placeholder="$t('form.phonePlaceholder')"
                        aria-label="First name">
                </div>
                <div class="col-md-6 mt-3">
                    <label for="formGroupExampleInput" class="form-label">{{ $t("form.websiteLabel") }}</label>
                    <input type="text" class="form-control" :placeholder="$t('form.websitePlaceholder')"
                        aria-label="Last name">
                </div>
            </div>
            <div class="row">
                <div class="col mt-3">
                    <label for="formGroupExampleInput" class="form-label">{{ $t("form.messageLabel") }}</label>
                    <textarea class="form-control" :placeholder="$t('form.messagePlaceholder')" aria-label="Last name"
                        rows="5"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="text-center">
                    <button class="submit-btn d-inline-flex justify-content-center align-items-center px-5">
                        {{ $t("form.submitButton") }}
                    </button>
                </div>
            </div>
        </form>
    </section>
    <success-popup :isSuccessPopupVisible="viewModal" @close-popup="handlePopupClose" />
</template>
<script>
import SuccessPopup from '../Common/SuccessPopup.vue';
export default {
    components: { SuccessPopup },
    data() {
        return {
            viewModal: false
        }
    },
    methods: {
        sendMessage() {
            this.viewModal = true
        },
        handlePopupClose() {
            this.viewModal = false
        }
    }
}
</script>
<style lang="scss" scoped></style>