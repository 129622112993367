<template>
    <div>
        <div class="container-fluid">
            <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="true"
                :dir="direction">
                <Slide v-for="slide in slides" :key="slide">
                    <div class="carousel__item">
                        <div class="card">
                            <div class="card-img">
                                <img :src="slide.image.imgUrl" :alt="slide.image.altText" :width="slide.image.width"
                                :height="slide.image.height" class="card-img-top">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">{{ slide.title }}</h5>
                                <p class="card-text">
                                    {{ slide.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel,  Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default defineComponent({
    components: {
        Carousel,
        Slide,
    },
    props:{
        slides:{
            type : Array,
            required : true
        }
    },
    data() {
        return {
            direction: localStorage.getItem('direction') || 'rtl',
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                900: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
            },/*********/
        }
    },
    mounted() {
        window.addEventListener('direction-localstorage-changed', (event) => {
            this.direction = event.detail.storage;
        });
    },
})
</script>
<style lang="scss" scoped>
.carousel__viewport {
    perspective: 2000px;
}
.carousel__slide {
    position: relative;
}
.carousel__pagination {margin-top: 10px;}
.client-navigation {position: absolute;}
.our-clients{
    .carousel__item {
        filter: grayscale(0) !important;
    }
}
.carousel__slide {
    margin-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.card{
    fill: #FFF;
    filter: drop-shadow(0px 10px 50px rgba(0, 59, 121, 0.07));
    border-radius: 15px;
    img{
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    h5{
        color:  #121212;
        font-size: 22px;
        font-weight: 700;
        text-transform: capitalize;
    }
    p{
        color: #467688;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
    }
    h5,p{
        &:lang(en){
            text-align: left;
        }
        &:lang(ar){
            text-align: right;
        }
    }
    .card-body{
        padding: 0 21px 34px 33px;
    }
}
.card-img{
    padding: 25px;
    position: relative;
    img{
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    }
}
.card-img::before,.card-img::after{
    content: '';
    position: absolute;
    background: url(../../assets/images/frame1.png);
    z-index: -1;
    height: 100px;
    width: 100px;
}
.card-img::before{
    top: 10px;
    left: 10px;
}
.card-img::after{
    bottom: 10px;
    right: 10px;
    transform: rotate(90deg);
}
</style>