<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <digital-header></digital-header>
    <digital-overview></digital-overview>
    <DigitalServices></DigitalServices>
    <DigitalMax></DigitalMax>
    <DigitalMaxEnd></DigitalMaxEnd>
    <DigitalProcess></DigitalProcess>
    <ui-work-with-us class="my-5"></ui-work-with-us>
    <Faq></Faq>
    <Footer />
  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import DigitalHeader from "../DigitalMarketing/DigitalHeader";
import DigitalOverview from "../DigitalMarketing/DigitalOverview.vue";
import DigitalServices from "../DigitalMarketing/DigitalServices";
import DigitalMax from "../DigitalMarketing/DigitalMax";
import DigitalMaxEnd from '../DigitalMarketing/DigitalMaxEnd'
import DigitalProcess from '../DigitalMarketing/DigitalProcess'
import UiWorkWithUs from "../DigitalMarketing/DigitalworkWithUs.vue";
import Faq from '../DigitalMarketing/Faq'
import Footer from "../Layout/Footer";
import { useHead } from "@vueuse/head";
import { mapGetters } from "vuex";
export default {
  components: {
    NavbarStyleTwo,
    DigitalHeader,
    DigitalOverview,
    DigitalServices,
    DigitalMax,
    DigitalMaxEnd,
    UiWorkWithUs,
    DigitalProcess,
    Faq,
    Footer,

  },
  computed: {
    ...mapGetters(['currentLanguage']),
  },
  methods: {
    updateHeadMeta() {
      const isArabic = this.currentLanguage === 'ar';

      const metaTitle = isArabic
        ? 'خدمات التسويق الرقمي المبتكرة | حلول التسويق عبر الإنترنت | شركة مسك'
        : 'Innovative Digital Marketing Services | Online Marketing Solutions | Misk IT Solutions';

      const metaDescription = isArabic
        ? 'نقدم حلول التسويق الرقمي المتكاملة التي تساعدك في بناء وجود قوي على الإنترنت. من تحسين محركات البحث (SEO) إلى إدارة حملات وسائل التواصل الاجتماعي، نحن هنا لتعزيز حضورك الرقمي.'
        : 'We provide comprehensive digital marketing solutions to help you build a strong online presence. From SEO to social media campaign management, we’re here to enhance your digital footprint.';

      const metaKeywords = isArabic
        ? 'التسويق الرقمي, تحسين محركات البحث, التسويق عبر الإنترنت, وسائل التواصل الاجتماعي, إعلانات الإنترنت, التسويق عبر البريد الإلكتروني, شركة مسك'
        : 'digital marketing, SEO, online marketing, social media marketing, digital ads, email marketing, Misk IT Solutions';

      const metaImage = isArabic
        ? 'https://example.com/digital-marketing-image-ar.jpg'
        : 'https://example.com/digital-marketing-image-en.jpg';

      const metaUrl = isArabic
        ? 'https://misk.com/ar/digital-marketing'
        : 'https://misk.com/digital-marketing';

      useHead({
        title: metaTitle,
        meta: [
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: metaKeywords },
          { name: 'robots', content: 'index, follow' },
          { name: 'language', content: this.currentLanguage },

          // Open Graph
          { property: 'og:title', content: metaTitle },
          { property: 'og:description', content: metaDescription },
          { property: 'og:image', content: metaImage },
          { property: 'og:url', content: metaUrl },
          { property: 'og:type', content: 'website' },

          // Twitter Card
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:title', content: metaTitle },
          { name: 'twitter:description', content: metaDescription },
          { name: 'twitter:image', content: metaImage }
        ],
        script: [
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": metaTitle,
              "description": metaDescription,
              "url": metaUrl,
              "publisher": {
                "@type": "Organization",
                "name": "Misk IT Solutions",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://example.com/logo.jpg"
                }
              },
              "inLanguage": [isArabic ? "ar" : "en"],
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": metaUrl
              },
              "primaryImageOfPage": {
                "@type": "ImageObject",
                "url": metaImage
              },
              "breadcrumb": {
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@id": "https://misk.com",
                      "name": isArabic ? "الرئيسية" : "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@id": metaUrl,
                      "name": isArabic ? "التسويق الرقمي" : "Digital Marketing"
                    }
                  }
                ]
              }
            })
          }
        ]
      });
    }
  },
  mounted() {
    this.updateHeadMeta(); // Set metadata when component mounts
  },
  watch: {
    currentLanguage: {
      handler() {
        this.updateHeadMeta(); // Update metadata when language changes
      },
      immediate: true,
    },
  },
};
</script>
