<template>
    <header class="dev-services-heading services-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-xl-9">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content">
                        <h1>{{ $t('webDevelopment.header') }}</h1>
                        <p>{{ $t('webDevelopment.description') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    name: 'DevHeader'
}
</script>